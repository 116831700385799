/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, onDeleteAlert } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { deleteColaboradorCliente } from '../../../empresas-list/core/_requests'
import { Coordenador } from '../../coordenador-edit-modal/Coordenador'
import { useSearchParams } from 'react-router-dom'
import { refreshEmpresaCliente } from '../../CoordenadorClienteList'
import { getEmpresaClienteById } from '../../../empresasCliente-list/core/_request'
import { Dropdown, DropdownItem, useModal } from '../../../../../../components'
import { getColaboradorPcmso } from '../../core/_request'

type IProps = { id: ID }

const CoordenadorActionsCell: FC<IProps> = ({ id }) => {
  const { showModal } = useModal()
  const [searchParams] = useSearchParams()
  const cliente = Number(searchParams.get('cliente'))

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const editCoordenador = async () => {
    getEmpresaClienteById(cliente).then((result) => {
      if (result) {
        showModal({
          title: 'Cadastro Coordenador',
          content: <Coordenador empresaCliente={result} />,
          fetchers: {
            coordenador: async () => {
              const coordenadores = await getColaboradorPcmso(cliente)
              return coordenadores?.find((coor) => coor.id === id) || undefined
            },
          },
        })
      } else {
        Swal.fire({
          text: 'Não foi possível atualizar os dados do Empregador ASO!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    })
  }

  const excluiCoordenador = () => {
    deleteColaboradorCliente(cliente, id)
      .then(() => {
        Swal.fire({
          text: 'Coordenador excluído com sucesso!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          refreshEmpresaCliente()
        })
      })
      .catch((error) => {
        Swal.fire({
          text: 'Não foi possível excluir este coordenador!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const handleDelete = () => onDeleteAlert({ onAction: excluiCoordenador })

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={editCoordenador}>Alterar</DropdownItem>
      <DropdownItem onClick={handleDelete}>Excluir</DropdownItem>
    </Dropdown>
  )
}

export { CoordenadorActionsCell }
