import { FC, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { createValidationSchema } from './validation/createValidationSchema'
import {
  Button,
  CustomSelect,
  FetchState,
  Input,
  Label,
  Radio,
  TextEditor,
  useModal,
} from '../../../../../../components'
import { ButtonType, InputType } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../../atendimentos-medico-list/core/QueryResponseProvider'
import { useSearchParams } from 'react-router-dom'
import { updateAnswersFormulario } from '../../../../atendimento/core/_requests'
import Swal from 'sweetalert2'
import { FormularioAtendimentoDto, FormularioAtendimentoView } from '../../core/_models'
import { FormularioCampoView } from '../../../../formularios/formularios-create/core/_models'

type IFormularioModal = {
  onAnswered: () => void
}

export const FormularioModal: FC<IFormularioModal> = ({ onAnswered }) => {
  const { fetchStates, closeModal } = useModal()

  const { data: formularioAtendimento } = (fetchStates['formularioAtendimento'] ||
    {}) as FetchState<FormularioAtendimentoView>

  const { refetch } = useQueryResponse()
  const [searchParams] = useSearchParams()

  const atendimentoId = Number(searchParams.get('atendimento'))

  const initialValues =
    formularioAtendimento?.formulario.campos.reduce((acc: Record<string, any>, field) => {
      const answer = formularioAtendimento.respostas.find((answer) => answer.campo === field.rotulo)

      let valor = answer
        ? field.tipo === InputType.Number
          ? answer.valor !== ''
            ? Number(answer.valor)
            : null
          : answer.valor
        : field.tipo === InputType.Number
        ? field.respostaPadrao !== ''
          ? Number(field.respostaPadrao)
          : null
        : field.respostaPadrao || ''

      if (field.tipo === InputType.Select) {
        if (field.multiplo) {
          acc[String(field.id)] = valor
            ? [{ value: valor, label: valor }]
            : answer?.valores.map((item) => ({ value: item, label: item }))
        } else {
          acc[String(field.id)] = valor ? { label: valor, value: valor } : null
        }
      } else {
        acc[String(field.id)] = valor
      }

      return acc
    }, {}) || {}

  const formik = useFormik({
    initialValues,
    validationSchema: createValidationSchema(formularioAtendimento?.formulario.campos || []),
    onSubmit: (values) => {
      const respostas = Object.entries(values).map(([key, value]) => {
        let valores
        if (Array.isArray(value)) {
          valores = value.map((item) => item.value)
        } else if (typeof value === 'object' && value !== null) {
          valores = [value.value || value.label]
        } else if (value === null) {
          valores = ['']
        } else {
          valores = [String(value)]
        }
        return {
          campo: Number(key),
          valores,
        }
      })

      const answersForm: FormularioAtendimentoDto = {
        formulario: formularioAtendimento?.formulario.id,
        respostas,
      }
      updateAnswersFormulario(answersForm, atendimentoId)
        .then((_) => {
          Swal.fire({
            text: `Respostas ${
              formularioAtendimento?.respostas && formularioAtendimento.respostas.length > 0
                ? 'alteradas'
                : 'cadastradas'
            } com sucesso`,
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            onAnswered()
            closeModal()
          })
        })
        .catch((error) => {
          Swal.fire({
            text: 'Erro no salvar as respostas',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => closeModal())
        })
    },
  })

  const renderSelect = (field: FormularioCampoView) => {
    const options = field.opcoes?.map((option) => ({
      value: option.descricao,
      label: option.descricao,
    }))
    return (
      <CustomSelect
        name={String(field.ordem)}
        className={`${
          formik.touched[String(field.id)] && formik.errors[String(field.id)] ? 'is-invalid' : ''
        }`}
        onChange={(selected) => formik.setFieldValue(String(field.id), selected)}
        onBlur={formik.handleBlur}
        value={formik.values[String(field.id)]}
        isMulti={field.multiplo}
        options={options}
        isRequired={field.obrigatorio}
      />
    )
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) refetch()
    closeModal()
  }

  const renderError = (fieldId: string) => {
    const error = formik.errors[fieldId]

    if (formik.touched[fieldId] && typeof error === 'string') {
      return <div className='invalid-feedback d-block'>{error}</div>
    }

    return null
  }

  return (
    <form onSubmit={formik.handleSubmit} className='px-4'>
      {formularioAtendimento?.formulario.campos.map((field) => (
        <div key={field.id} className='mb-3'>
          <Label className={`form-label fw-bold mb-2 ${field.obrigatorio ? 'required' : ''}`}>
            {field.rotulo}
          </Label>

          {field.tipo === InputType.Text && (
            <>
              <Input
                name={String(field.id)}
                required={field.obrigatorio}
                value={formik.values[String(field.id)]}
                onChange={(e) => formik.setFieldValue(String(field.id), e.target.value)}
                onBlur={formik.handleBlur}
                isInvalid={!!(formik.touched[String(field.id)] && formik.errors[String(field.id)])}
              />
              {renderError(String(field.id))}
            </>
          )}

          {field.tipo === InputType.Number && (
            <>
              <Input
                type='number'
                name={String(field.id)}
                required={field.obrigatorio}
                value={formik.values[String(field.id)]}
                onChange={(e) =>
                  formik.setFieldValue(
                    String(field.id),
                    e.target.value === '' ? '' : Number(e.target.value)
                  )
                }
                onBlur={formik.handleBlur}
                isInvalid={!!(formik.touched[String(field.id)] && formik.errors[String(field.id)])}
              />
              {renderError(String(field.id))}
            </>
          )}

          {field.tipo === InputType.Select && (
            <>
              {renderSelect(field)}
              {renderError(String(field.id))}
            </>
          )}

          {field.tipo === InputType.Radio && (
            <>
              <Radio
                id={String(field.id)}
                name={field.rotulo}
                onChange={(e) => formik.setFieldValue(String(field.id), e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values[String(field.id)]}
                touched={formik.touched[String(field.id)]}
                error={formik.errors[String(field.id)]}
                options={field.opcoes}
              />
              {renderError(String(field.id))}
            </>
          )}

          {field.tipo === InputType.TextEditor && (
            <>
              <TextEditor
                value={formik.values[String(field.id)]}
                onChange={(value: string) => formik.setFieldValue(String(field.id), value)}
              />
              {renderError(String(field.id))}
            </>
          )}
        </div>
      ))}

      <div className='d-flex flex-end pt-10'>
        <div className='me-2'>
          <Button
            type={ButtonType.Reset}
            onClick={cancel}
            disabled={formik.isSubmitting}
            className='btn-secondary'
          >
            Cancelar
          </Button>
        </div>
        <Button type={ButtonType.Submit} disabled={formik.isSubmitting} className='btn-primary'>
          Salvar
        </Button>
      </div>
    </form>
  )
}
