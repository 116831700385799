import { Column } from 'react-table'
import { EspecialidadeView } from '../../core/_models'
import { EspecialidadeActionsCell } from './EspecialidadeActionsCell'
import { Badge, CustomHeader } from '../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const especialidadesColumns: ReadonlyArray<Column<EspecialidadeView>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Nome'
        className='min-w-125px'
      />
    ),
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Tipo Atendimento'
        className='min-w-125px'
        isAbleToSort={false}
      />
    ),
    id: 'tipo',
    accessor: 'tiposAtendimento',
    Cell: ({ value }) => {
      if (Array.isArray(value)) {
        return <span>{value.join(', ')}</span>
      }
      return <span></span>
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Status'
        className='min-w-125px'
      />
    ),
    accessor: 'ativa',
    Cell: ({ value }) => <Badge isActive={value} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <EspecialidadeActionsCell especialidade={props.row.original} />,
  },
]

export { especialidadesColumns }
