/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { useListView } from '../../../core/ListViewProvider'
import { getAtendimentoById } from '../../../core/_requests'
import { useNavigate } from 'react-router-dom'
import { Dropdown, DropdownItem, useModal } from '../../../../../../components'
import { MarcarPresencaModal } from '../../../../atendimento_medicos/atendimentos-medico-list/component/modal'
import { AtendimentoEditModalForm } from '../../atendimento-edit-modal/AtendimentoEditModalForm'
import { AtendimentoPacienteList } from '../../../core/_models'

type Props = {
  atendimento: AtendimentoPacienteList
}

const AtendimentoActionsCell: FC<Props> = ({ atendimento }) => {
  const { showModal } = useModal()

  const { setItemIdForUpdate } = useListView()

  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    showModal({
      title: 'Visualizar Atendimento',
      content: <AtendimentoEditModalForm />,
      fetchers: {
        atendimento: () => getAtendimentoById(atendimento.id),
      },
    })
    setItemIdForUpdate(atendimento.id)
  }

  const showMarcarPresenca = () => {
    showModal({
      title: 'Atendimento',
      content: <MarcarPresencaModal />,
      fetchers: {
        atendimento: () => getAtendimentoById(atendimento?.id),
      },
    })
  }

  const openVideoChamada = () => {
    navigate('/apps/atendimentos/teleAtendimento?atendimentoPaciente=' + atendimento.id)
  }

  return (
    <>
      <Dropdown name='Ações'>
        <DropdownItem onClick={openEditModal}>Visualizar</DropdownItem>
      </Dropdown>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
        data-kt-menu='true'
      >
        {atendimento?.presencaPacienteFormatada === null && (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={showMarcarPresenca}>
              Marcar presença
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export { AtendimentoActionsCell }
