import { FC, useEffect, useState } from 'react'
import { Button, FetchState, TextEditor, useModal } from '../../../../../../components'
import { AtendimentoView, InformacaoAtendimentoView } from '../../../../atendimento/core/_models'
import {
  GenderType,
  IconPaths,
  KTSVG,
  ModalSize,
  showAlert,
} from '../../../../../../../_metronic/helpers'
import { IData, Leitura } from '../../../atendimento-video/modal'
import { FormularioAtendimentoView } from '../../../atendimento-video/core/_models'
import { getDiagnostic } from '../../core/_requests'
import { ResultadoIAModal, ResultJSON } from './ResultadoIAModal'
import Swal from 'sweetalert2'

type IDiagnosticoModal = {
  onClose: React.Dispatch<React.SetStateAction<ResultJSON | undefined>>
}
export const DiagnosticoIAModal: FC<IDiagnosticoModal> = ({ onClose }) => {
  const { fetchStates, closeModal, showModal } = useModal()
  const { data: atendimento } = (fetchStates['atendimento'] || {}) as FetchState<AtendimentoView>
  const { data: formularioAtendimento } = (fetchStates['formularioAtendimento'] ||
    {}) as FetchState<FormularioAtendimentoView>

  const [prompt, setPrompt] = useState<string>('')

  const generatePrompt = () => {
    const informacoes = atendimento?.informacoes || []

    const nome = atendimento?.paciente?.pessoa?.nome || 'Nome não informado'
    const nascimento = atendimento?.paciente?.pessoa?.dataNascimento || null
    const genero =
      atendimento?.paciente?.pessoa?.sexo === GenderType.Feminino ? 'Feminino' : 'Masculino'

    const idade = nascimento
      ? Math.max(new Date().getFullYear() - new Date(nascimento).getFullYear(), 0)
      : 'Idade não disponível'

    const dispAux: IData[] = []

    informacoes.map((informacao: InformacaoAtendimentoView) => {
      const leitura: Leitura = {
        descricao: informacao.descricao,
        anexo: null,
        valor: informacao.valor,
        unidade: informacao.unidade,
        dataFormatada: informacao.dataFormatada,
        data: new Date(informacao.data),
      }

      const dispositivoId = informacao.dispositivo?.id
      let info = dispAux.find((d) => d.dispositivo.id === dispositivoId)

      if (info) {
        const leituraMap = new Map<string, Leitura>()
        info.leituras.forEach((l) => leituraMap.set(l.descricao, l))

        const existingLeitura = leituraMap.get(leitura.descricao)

        if (!existingLeitura || existingLeitura.data < leitura.data)
          leituraMap.set(leitura.descricao, leitura)

        info.leituras = Array.from(leituraMap.values())
      } else {
        dispAux.push({
          dispositivo: informacao.dispositivo,
          leituras: [leitura],
        })
      }
    })

    const message = `
    <p>
    ${nome}, com ${idade} anos, sexo ${genero}
    </p>
     ${
       dispAux.length > 0
         ? `
        <p>Os sinais vitais registrados na admissão foram:</p>
        ${dispAux
          .map((info) => {
            const { dispositivo, leituras } = info
            const dispositivoNome = dispositivo?.descricao || 'Dispositivo desconhecido'

            const leiturasDetalhes =
              leituras
                ?.map(
                  (leitura) =>
                    `<li>${leitura.descricao}: ${leitura.valor} ${leitura.unidade || ''}</li>`
                )
                .join('') || '<li>Sem leituras disponíveis</li>'

            return `<ul>
                <li><strong>${dispositivoNome}</strong></li>
                <ul>${leiturasDetalhes}</ul>
              </ul>`
          })
          .join('')}
        `
         : ''
     }
    <p>
    ${
      formularioAtendimento && formularioAtendimento?.respondido
        ? formularioAtendimento.respostas
            .filter((answer) => answer.valores.length > 0 || answer.valor)
            .map(
              (answer) => `
              <p><strong>${answer.campo}</strong> ${
                answer.valores.length > 0 ? answer.valores.join(', ') : answer.valor
              }</p>`
            )
            .join('')
        : ''
    }
    </p>
`

    setPrompt(message)
  }

  useEffect(() => {
    generatePrompt()
  }, [])

  const generateDiagnostic = async () => {
    closeModal()
    showAlert({
      title: 'Aguarde...',
      text: 'Gerando análise com IA...',
      icon: 'info',
      showLoading: true,
    })
    const content = `${prompt}
    <p>Descreva o melhor Diagnóstico, Receita, Orientações Médicas, Atestados e Exames complementares</p>
    <p>Estruture a resposta em JSON com os campos diagnostico (string), receita (array de objetos com campos: medicamento, dosagem e administracao), 
    orientacoes_medicas (array de string), atestados (array de objetos com campos: observacoes, tipo e validade) e 
    exames_complementares (array de objetos com campos: exame e justificativa)</p>`

    const response = await getDiagnostic(content)

    const data = JSON.parse(response.choices[0].message.content)
    onClose(data)

    Swal.close()

    showModal({
      title: 'Análise da IA',
      content: <ResultadoIAModal resultData={data} />,
      size: ModalSize.ExtraLarge,
    })
  }

  return (
    <div>
      <TextEditor onChange={setPrompt} value={prompt} height={450} />
      <div className='d-flex align-items-center p-5 rounded bg-info text-dark'>
        <KTSVG path={IconPaths.Info} color='blue' className='svg-icon-1 me-2' />
        <span className='fs-6'>
          Adicionar as queixas ao prompt para o diagnóstico ficar mais preciso
        </span>
      </div>
      <div className='d-flex flex-end mt-4'>
        <Button className='btn-primary' onClick={generateDiagnostic}>
          Gerar Resposta
        </Button>
      </div>
    </div>
  )
}
