import { Column } from 'react-table'
import { Importacao } from '../../core/_models'
import { ImportacoesActionsCell } from './ImportacoesActionsCell'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader } from '../../../../../../components'

const importacoesColumns: ReadonlyArray<Column<Importacao>> = [
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Descrição' />
    ),
    id: 'descricao',
    accessor: 'descricao',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Registros importados'
      />
    ),
    id: 'registrosImportados',
    accessor: 'registrosImportados',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Erros' />
    ),
    id: 'registrosErros',
    accessor: 'registrosErros',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Pacientes cadastrados'
      />
    ),
    id: 'pacientesCadastrados',
    accessor: 'pacientesCadastrados',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Início' />
    ),
    id: 'inicioFormatado',
    accessor: 'inicioFormatado',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Término' />
    ),
    id: 'terminoFormatado',
    accessor: 'terminoFormatado',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ImportacoesActionsCell importacao={props.data[props.row.index]} />,
  },
]

export { importacoesColumns }
