import { KTCard } from '../../../../../_metronic/helpers'
import { LogsUsuarioListHeader } from './components/header/LogsUsuarioListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { LogsUsuarioTable } from './table/LogsUsuarioTable'

const LogsUsuarioList = () => {
  return (
    <>
      <KTCard>
        <LogsUsuarioListHeader />
        <LogsUsuarioTable />
      </KTCard>
    </>
  )
}

const LogsUsuarioListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LogsUsuarioList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { LogsUsuarioListWrapper }
