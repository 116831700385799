import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { RelatorioNpsData } from './components/data/RelatorioNpsData'
import { RelatorioNpsListHeader } from './components/header/RelatorioNpsListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'

const RelatorioNpsList = () => {
  return (
    <KTCard>
      <RelatorioNpsListHeader />
      <RelatorioNpsData />
    </KTCard>
  )
}

const RelatorioNpsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RelatorioNpsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { RelatorioNpsListWrapper }
