import axios from 'axios'
import { AtendimentoResumo, Estado, OpenAIResponse } from './_models'

const openAIInstance = axios.create()

const API_URL = process.env.REACT_APP_API_URL
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY

const ATENDIMENTO_URL = `${API_URL}/atendimentos`

export const getResumoAtendimentos = (query: string): Promise<AtendimentoResumo> => {
  return axios.get(`${ATENDIMENTO_URL}/resumo?${query}`).then((resp) => resp.data)
}

export const getResumoAtendimentosResponsavel = (query: string): Promise<AtendimentoResumo> => {
  return axios.get(`${ATENDIMENTO_URL}/responsavel/resumo?${query}`).then((resp) => resp.data)
}

export const getEstadosUf = (): Promise<Estado[]> => {
  return axios.get(`${API_URL}/estados/uf`).then((resp) => resp.data)
}

export const getEstadoByUf = (uf: string): Promise<Estado> => {
  return axios.get(`${API_URL}/estados/uf/${uf}`).then((resp) => resp.data)
}

export const getDiagnostic = (content: string): Promise<OpenAIResponse> => {
  return openAIInstance
    .post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [{ role: 'user', content }],
        response_format: {
          type: 'json_object',
        },
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((resp) => resp.data)
}
