import { FC, useEffect } from 'react'
import { EspecialistasModalForm } from '../../component/modal/EspecialistasModalForm'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { Dropdown, DropdownItem, useModal } from '../../../../../components'
import { getProfissionalVinculo } from '../../core/_requests'

type Props = {
  pessoaId: number
}

const EspecialistasActionsCell: FC<Props> = ({ pessoaId }) => {
  const { showModal } = useModal()

  const openModal = () => {
    showModal({
      title: 'Vincular Especialidades e Empresas',
      content: <EspecialistasModalForm />,
      fetchers: {
        profissional: () => getProfissionalVinculo(pessoaId),
      },
    })
  }

  useEffect(() => MenuComponent.reinitialization(), [])

  return (
    <Dropdown name='Ações' size={175}>
      <DropdownItem onClick={openModal}>Vincular Especialidade</DropdownItem>
    </Dropdown>
  )
}

export { EspecialistasActionsCell }
