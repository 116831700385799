/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react'
import {
  AnexoAtendimentoView,
  AtendimentoView,
  InformacaoAtendimentoView,
  PrescricaoAtendimentoView,
  ProcedimentoAtendimentoView,
} from '../../../../atendimento/core/_models'
import { KTSVG, getArquivoAnexo } from '../../../../../../../_metronic/helpers'
import Anotacao from '../../table/Anotacao'
import { useAuth } from '../../../../../auth'
import { FetchState, ListLoading, useModal } from '../../../../../../components'

const HistoricoDetalhesModal: FC = () => {
  const { fetchStates } = useModal()
  const { currentUser } = useAuth()

  const { data: atendimento, isLoading } = (fetchStates['atendimento'] ||
    {}) as FetchState<AtendimentoView>

  const isMedico = currentUser?.vinculos?.some((vinculo) => vinculo.cargo === 'MEDICO')

  const getNomeByAnexoId = (
    anexo: AnexoAtendimentoView,
    informacoes: InformacaoAtendimentoView[] = [],
    prescricoes: PrescricaoAtendimentoView[] = [],
    procedimentos: ProcedimentoAtendimentoView[] = []
  ) => {
    for (const info of informacoes) {
      if (info.anexo === anexo.id) {
        return info.descricao
      }
    }

    if (anexo.tipo?.descricao === 'PRESCRICAO') {
      return 'Prescrição'
    }

    for (const procedimento of procedimentos) {
      if (procedimento.anexo === anexo.id) {
        return procedimento.nome
      }
    }

    return anexo.tipo?.descricao
  }

  return (
    <>
      {isLoading ? (
        <ListLoading />
      ) : (
        <div
          className='card mb-5 mb-xl-10'
          data-kt-scroll='true'
          data-kt-scroll-height='auto'
          data-kt-scroll-offset='5px'
        >
          <div className='col-lg-12 col-xl-5 mb-5 mb-xl-0'>
            <div className='fv-row mb-5 '>
              <label className='col-lg-8 fw-bold text-muted'>Nome do paciente</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{atendimento?.paciente?.nome}</span>
              </div>
            </div>
            <div className='fv-row mb-5'>
              <label className='col-lg-8 fw-bold text-muted'>Criado por</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{atendimento?.criador?.nome}</span>
              </div>
            </div>
            <div className='fv-row mb-5'>
              <label className='col-lg-8 fw-bold text-muted'>Responsável</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {atendimento?.responsavel?.nome || 'Sem responsável'}
                </span>
              </div>
            </div>
          </div>

          {isMedico && (
            <div className='fv-row mb-12'>
              <label className='col-lg-4 fw-bold text-muted'>CID</label>
              <div className='col-lg-8 fv-row'>
                {atendimento?.cids ? (
                  atendimento.cids.map((c, index) => (
                    <span key={index} className='fw-bold fs-6'>
                      {c.cid.codigo} - {c.cid.descricao}
                    </span>
                  ))
                ) : (
                  <span className='fw-bold fs-6'>Não definido</span>
                )}
              </div>
            </div>
          )}
          <div className='fv-row mb-5'>
            <label className='col-lg-10 fw-bold text-muted'>Data da Solicitação</label>

            <div className='fv-row mb-5'>
              <span className='fw-bold fs-6'>
                {atendimento?.inicio !== 'null'
                  ? String(atendimento?.inicioFormatado)
                  : String(atendimento?.solicitacaoFormatada)}
              </span>
            </div>
          </div>

          <div className='fv-row mb-12'>
            <label className='col-lg-4 fw-bold text-muted'>Estado</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{atendimento?.estado?.descricao}</span>
            </div>
          </div>

          <div className='fv-row mb-1'>
            <label className='col-lg-4 fw-bold text-muted'>Informações equipamentos</label>
            <div className='d-flex flex-row-fluid'>
              <ul>
                {atendimento?.informacoes?.map((info) => (
                  <li>{info.descricao + ' - ' + info.valor + info.unidade}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className='fv-row mb-1'>
            {atendimento?.anotacoes && (
              <>
                <label className='col-lg-4 fw-bold text-muted'>Anotações</label>
                <div className='d-flex flex-row-fluid'>
                  <ul>
                    {atendimento?.anotacoes?.map((anot) => {
                      return <Anotacao nota={anot} />
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className='col-lg-8 col-xl-8 mb-5 mb-xl-0'>
            <div className='fv-row mb-1'>
              <label className='col-lg-8 fw-bold text-muted'>Prescrição/Medicamentos</label>
              <div className='d-flex flex-row-fluid'>
                <ul>
                  {atendimento?.prescricoes?.map((p) =>
                    p.medicamentos?.map((m) => <li>{m.nome + ' - ' + m.posologia}</li>)
                  )}
                </ul>
              </div>
            </div>
            <div className='fv-row mb-1'>
              <label className='col-lg-8 fw-bold text-muted'>Prescrição/Exames</label>
              <div className='d-flex flex-row-fluid'>
                <ul>
                  {atendimento?.procedimentos?.map((p) => (
                    <li>{p.nome}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='fv-row mb-1'>
              <label className='col-lg-8 fw-bold text-muted'>Anexos</label>
              <div className='d-flex flex-row-fluid'>
                <ul>
                  {atendimento?.anexos?.map((p) => {
                    const nome = getNomeByAnexoId(
                      p,
                      atendimento.informacoes,
                      atendimento?.prescricoes,
                      atendimento.procedimentos
                    )

                    if (
                      p?.tipo?.descricao === 'Atestado de Saúde Ocupacional' &&
                      !p?.url?.endsWith('_assinado.pdf')
                    ) {
                      return null
                    }

                    return (
                      <button
                        key={p.id}
                        className='btn btn-light btn-active-light-primary btn-sm'
                        onClick={() => getArquivoAnexo(atendimento.id, p.id)}
                      >
                        <span className='indicator-label'>{`${nome}: ${p.url}`}</span>
                        <KTSVG
                          path='/media/icons/duotune/files/fil021.svg'
                          className='svg-icon-5 m-0'
                        />
                      </button>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { HistoricoDetalhesModal }
