import { KTCard } from '../../../../../_metronic/helpers'
import { ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { AtendimentosListHeader } from './components/header/AtendimentosListHeader'
import { AtendimentosTable } from './table/AtendimentosTable'

const AtendimentoList = () => {
  return (
    <KTCard>
      <AtendimentosListHeader />
      <AtendimentosTable />
    </KTCard>
  )
}

const AtendimentosListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AtendimentoList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { AtendimentosListWrapper }
