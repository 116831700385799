import { FC, useMemo } from 'react'
import { useFormik } from 'formik'
import { validationSchema } from './validation/ValidationSchema'
import {
  ButtonType,
  OptionsHelper,
  showAlert,
  showError,
} from '../../../../../../_metronic/helpers'
import {
  Button,
  CustomSelect,
  FetchState,
  IOption,
  Label,
  ListLoading,
  LoadingIndicator,
  useModal,
} from '../../../../../components'
import { FuncionarioDtoEspecialidades, FuncionarioDtoEspecialidadesAdd } from '../../core/_models'
import { createVinculoEspecialidade } from '../../core/_requests'

export const EspecialistasModalForm: FC = () => {
  const { closeModal, fetchStates } = useModal()

  const { data: profissional } = (fetchStates['profissional'] ||
    {}) as FetchState<FuncionarioDtoEspecialidades>

  const empresaOptions = useMemo(() => {
    return OptionsHelper.createOptions(
      profissional?.vinculos || [],
      'empresa.nomeFantasia',
      'empresa.id'
    )
  }, [profissional?.vinculos])

  const initialFormulario: { especialidades: IOption[]; empresas: IOption[] } = {
    especialidades: [],
    empresas: [],
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormulario,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const profissionalVinculo: FuncionarioDtoEspecialidadesAdd = {
        pessoa: profissional?.pessoa.id as number,
        especialidades: values.especialidades.map(({ value }) => value as number),
        empresas: values.empresas.map(({ value }) => value as number),
      }
      try {
        setSubmitting(true)

        await createVinculoEspecialidade(profissionalVinculo)

        await showAlert({
          title: 'Sucesso',
          text: 'Vínculo criado com sucesso!',
          icon: 'success',
        })

        closeModal()
      } catch (error: any) {
        await showError(error)
        closeModal()
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column me-n7 pe-7 w-500 h-500'>
          <div className='fv-row mb-7'>
            <Label className='mb-2'>Especialidades</Label>
            <CustomSelect
              placeholder='Selecione uma especialidade'
              onChange={(data) => formik.setFieldValue('especialidades', data)}
              fetchOptions={OptionsHelper.getOptionsEspecialidades}
              value={formik.values.especialidades}
              isMulti={true}
            />
            {formik.touched.especialidades && formik.errors.especialidades && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>Selecione pelo menos uma especialidade.</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <Label className='mb-2'>Empresa</Label>
            <CustomSelect
              placeholder='Selecione uma empresa'
              onChange={(data) => formik.setFieldValue('empresas', data)}
              options={empresaOptions}
              value={formik.values.empresas}
              isMulti={true}
            />

            {formik.touched.empresas && formik.errors.empresas && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>Selecione pelo menos uma empresa.</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='d-flex justify-content-center pt-10'>
          <Button
            type={ButtonType.Reset}
            onClick={closeModal}
            className='btn-secondary me-3'
            disabled={formik.isSubmitting}
          >
            Cancelar
          </Button>
          <Button type={ButtonType.Submit} className='btn-primary' disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <LoadingIndicator /> : <Label>Confirmar</Label>}
          </Button>
        </div>
      </form>
      {formik.isSubmitting && <ListLoading />}
    </>
  )
}
