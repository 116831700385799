import { FC } from 'react'
import { AtendimentoPacienteList } from '../../../core/_models'
import { Dot } from '../../../../../../components'

type Props = {
  atendimento: AtendimentoPacienteList
}

const PresencaColumn: FC<Props> = ({ atendimento }) => {
  const cor = atendimento?.presencaPacienteFormatada !== null ? 'bg-success' : 'bg-secondary'

  return <Dot cor={cor} />
}

export { PresencaColumn }
