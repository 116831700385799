/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { useSearchParams } from 'react-router-dom'
import { ClienteView } from '../../empresasCliente-list/core/_models'
import { getEmpresaClienteById } from '../../empresasCliente-list/core/_request'
import { useModal } from '../../../../../components'

const QueryResponseContext = createResponseContext<ClienteView>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isOpen } = useModal()
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const [searchParams] = useSearchParams()
  const cliente = Number(searchParams.get('cliente'))
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: responseSemPaginacao,
  } = useQuery(
    `${QUERIES.EMPRESAS_LIST}-${query}-${isOpen}`,
    () => getEmpresaClienteById(Number(cliente)),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !isOpen,
    }
  )

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, responseSemPaginacao, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { responseSemPaginacao } = useQueryResponse()

  return responseSemPaginacao
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  }

  return responsePaginationState
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
