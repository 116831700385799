import { useMemo } from 'react'
import { ListLoading, PieChart } from '../../../../../../components'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { KTCardBody, NPSType } from '../../../../../../../_metronic/helpers'

export const RelatorioNpsData = () => {
  const { singleResponse } = useQueryResponse()

  const chartData = useMemo(() => {
    if (!singleResponse) return null

    return Object.entries(singleResponse).map(([category, values]) => ({
      label: category,
      series: {
        [NPSType.Detratores]: values?.Detratores || 0,
        [NPSType.Neutros]: values?.Neutros || 0,
        [NPSType.Promotores]: values?.Promotores || 0,
      },
      details: {
        'Resposta 0': values['Resposta 0'],
        'Resposta 1': values['Resposta 1'],
        'Resposta 2': values['Resposta 2'],
        'Resposta 3': values['Resposta 3'],
        'Resposta 4': values['Resposta 4'],
        'Resposta 5': values['Resposta 5'],
        'Porcentagem de Promotores': values?.['Porcentagem de Promotores'] || 0,
        'Porcentagem de Detratores': values?.['Porcentagem de Detratores'] || 0,
        NPS: values?.NPS || 0,
      },
    }))
  }, [singleResponse])

  return (
    <>
      {!chartData ? (
        <ListLoading />
      ) : (
        <KTCardBody className='py-5'>
          <div className='row'>
            {chartData.map((data, index) => (
              <div className='col-lg-4 col-md-12 mb-4' key={index}>
                <div className='card shadow-sm'>
                  <div className='card-body'>
                    <div className='mb-4'>
                      <PieChart label={data.label} series={data.series} />
                    </div>
                    <div className='table-responsive'>
                      <table className='table table-striped table-bordered'>
                        <thead>
                          <tr>
                            <th className='text-center'>Descrição</th>
                            <th className='text-center'>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(data.details).map(([key, value], index) => (
                            <tr key={`${key}-${index}`}>
                              <td>
                                <strong>{key}</strong>
                              </td>
                              <td className='text-center'>{value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </KTCardBody>
      )}
    </>
  )
}
