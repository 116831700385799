import axios, { AxiosResponse } from 'axios'
import { LogAcessoQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const getLogsUsuario = (query: string): Promise<LogAcessoQueryResponse> => {
  return axios
    .get(`${API_URL}/log?${query}`)
    .then((resp: AxiosResponse<LogAcessoQueryResponse>) => resp.data)
}
