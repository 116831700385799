import React, { FC, useContext, useState, useEffect, useMemo, useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  createResponseContext,
  getParameterByName,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { AtendimentoList } from '../../../atendimento/core/_models'
import {
  getAtendimentosMedico,
  getAtendimentosMedicoLogado,
} from '../../../atendimento/core/_requests'
import { useAuth } from '../../../../auth'
import { Stomp } from '@stomp/stompjs'
import { useModal } from '../../../../../components'

const QueryResponseContext = createResponseContext<AtendimentoList>(initialQueryResponse)

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isOpen } = useModal()

  const auth = useAuth()
  const { state } = useQueryRequest()

  const queryClient = useQueryClient()
  const { idEmpresa } = useQueryResponse()
  const { idPaciente } = useQueryResponse()
  const [query, setQuery] = useState<string>(decodeURIComponent(stringifyRequestQuery(state)))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const API_URL =
    window.location.hostname.includes('homolog') || window.location.hostname.includes('localhost')
      ? 'wss://api-homologacao.lauduz.com/socket/atendimento'
      : 'wss://api.lauduz.com/socket/atendimento'

  useEffect(() => {
    if (query !== updatedQuery) {
      getAtendimentosMedico(query).catch((error) => {
        console.log(error)
      })
      setQuery(decodeURIComponent(updatedQuery))
    }
  }, [query, updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}-${isOpen}`,
    () => getAtendimentosMedico(query),
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: !isOpen }
  )

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries([`${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}-${isOpen}`])
  }, [query, queryClient])

  useEffect(() => {
    const stompClient = Stomp.client(API_URL)
    const token = auth.auth?.accessToken

    const onConnect = (frame: string) => {
      console.log('Connected: ' + frame)
      stompClient.subscribe('/topic/atendimento', (message: any) => {
        invalidateQuery()
      })
    }

    stompClient.connect({ Authorization: 'Bearer ' + token }, onConnect)

    return () => {
      stompClient.disconnect(() => console.log('Disconnected'))
    }
  }, [auth.auth?.accessToken, invalidateQuery])

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response,
        query,
        idEmpresa,
        idPaciente,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }
  return response?.data || []
}

const useQueryResponsePagination = (): PaginationState => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  }

  return responsePaginationState
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
