import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { atendimentosMedicosColumns } from './columns/_columns'
import { AtendimentoView } from '../../../atendimento/core/_models'
import { getAtendimentoById } from '../../../atendimento/core/_requests'
import { AtendimentoExtra } from '../component/AtendimentoExtra/AtendimentoExtra'
import { useQueryRequest } from '../core/QueryRequestProvider'
import {
  CustomHeaderColumn,
  CustomRow,
  CustomPagination,
  ListLoading,
} from '../../../../../components'

const AtendimentoMedicoTable = () => {
  const atendimentosMedicos = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => atendimentosMedicos, [atendimentosMedicos])
  const columns = useMemo(() => atendimentosMedicosColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data as AtendimentoView[],
  })
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({})

  const [atendimentos, setAtendimentos] = useState<{ [key: string]: AtendimentoView }>({})

  const handleRow = (rowId: string) => {
    getAtendimentoById(Number(rowId)).then((atendimento) =>
      setAtendimentos((prev) => ({
        ...prev,
        [rowId]: atendimento ? atendimento : {},
      }))
    )
    toggleRowExpansion(rowId)
  }

  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }))
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_atendimento_medico'
          className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
              {headers.map((column: ColumnInstance<AtendimentoView>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<AtendimentoView>, i) => {
                prepareRow(row)
                const isExpanded = !!expandedRows[String(row.original.id) as string]
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    toggleRow={() => handleRow(String(row.original.id) as string)}
                    isExpanded={isExpanded}
                  >
                    {isExpanded ? (
                      <AtendimentoExtra
                        isLoading={!atendimentos[String(row.original.id)]}
                        anotacoes={atendimentos[String(row.original.id)]?.anotacoes}
                        procedimentos={atendimentos[String(row.original.id)]?.procedimentos}
                        retorno={atendimentos[String(row.original.id)]?.retorno}
                        dispositivos={atendimentos[String(row.original.id)]?.dispositivos}
                      />
                    ) : null}
                  </CustomRow>
                )
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CustomPagination useQueryRequest={useQueryRequest} useQueryResponse={useQueryResponse} />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { AtendimentoMedicoTable }
