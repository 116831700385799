import { FormikProps } from 'formik'
import React from 'react'
import { Input, Label } from '..'

interface FormInputProps {
  label: string
  name: string
  placeholder: string
  type?: 'text' | 'number'
  mask?: string
  formik: FormikProps<any>
  isDisabled?: boolean
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  name,
  placeholder,
  type = 'text',
  mask,
  formik,
  isDisabled = false,
}) => {
  const errorMessage =
    formik.touched[name] && typeof formik.errors[name] === 'string'
      ? (formik.errors[name] as string)
      : null

  return (
    <div className='row mb-7'>
      <Label className='mb-2'>{label}</Label>
      <Input
        {...formik.getFieldProps(name)}
        placeholder={placeholder}
        type={type}
        mask={mask}
        isInvalid={formik.touched[name] && Boolean(formik.errors[name])}
        isValid={formik.touched[name] && !formik.errors[name]}
        disabled={formik.isSubmitting || isDisabled}
      />
      {errorMessage && (
        <div className='invalid-feedback d-block'>
          <span role='alert'>{errorMessage}</span>
        </div>
      )}
    </div>
  )
}
