import axios, { AxiosResponse } from 'axios'
import { LogAtendimentoQueryResponse } from './_models'
import { ID } from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTO_RESPONSAVEL_URL = `${API_URL}/atendimentos/logs`

export const getLogsConsultaIdAtendimento = (
  query: string,
  atendimentoId: ID
): Promise<LogAtendimentoQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_RESPONSAVEL_URL}/${atendimentoId}?${query}`)
    .then((resp: AxiosResponse) => resp.data)
}
