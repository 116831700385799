import { Column } from 'react-table'
import { AtendimentoAsoDownloadCell } from './AtendimentoAsoDownloadCell'
import { AtendimentoList } from '../../../../atendimento/core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useListView } from '../../core/ListViewProvider'
import { SelectionHeader, SelectionCell, CustomHeader } from '../../../../../../components'

const AtendimentosAsoDownloadColumns: ReadonlyArray<Column<AtendimentoList>> = [
  {
    Header: (props) => <SelectionHeader useListView={useListView} tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell useListView={useListView} id={props.row.original.id} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Protocolo'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Nome Paciente' useQueryRequest={useQueryRequest} />
    ),
    id: 'paciente.pessoa.nome',
    accessor: (row) => row.paciente?.pessoa?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Empresa'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'empresa.nomeFantasia',
    accessor: (row) => row.empresa?.nomeFantasia,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Estado'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'estado.descricao',
    accessor: (row) => row.estado?.descricao,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Data finalização'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'terminoFormatado',
    accessor: 'terminoFormatado',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <AtendimentoAsoDownloadCell id={props.row.original.id} />,
  },
]

export { AtendimentosAsoDownloadColumns }
