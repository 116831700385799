import { FC } from 'react'
import { useFormik } from 'formik'
import { ID, OptionsHelper, showAlert, showError } from '../../../../../../../_metronic/helpers'
import { Badge } from '../../../../../../../_metronic/helpers/components/Badge/Badge'
import { validationSchema } from './validation/ValidationSchema'
import { bindFormulario, deleteVinculo } from '../../core/_requests'
import Swal from 'sweetalert2'
import { CustomSelect, IOption, Label, ListLoading, useModal } from '../../../../../../components'
import { EmpresaDtoVinculo } from '../../../../empresa/empresas-list/core/_models'
import { useSearchParams } from 'react-router-dom'
import { Vinculos } from '../../core/_models'

type IOptionsEmpresa = {
  value: ID
  label: string
}
type IOptionsEspecialidade = {
  value: ID
  label: string
}

type IProps = {
  vinculo?: Vinculos
}

export const VincularFormularioModalForm: FC<IProps> = ({ vinculo }) => {
  const { closeModal } = useModal()
  const [searchParams] = useSearchParams()
  const formularioId = Number(searchParams.get('id'))

  const mapEmpresas = (empresas: EmpresaDtoVinculo[]) => {
    const results: { label: string; value: ID }[] = []

    const empresasTemp: { label: string; value: ID }[] = []

    empresas.forEach((empresa) =>
      empresasTemp.push({ label: empresa.nomeFantasia as string, value: empresa.id })
    )

    empresasTemp.sort((a, b) => {
      const labelA = a.label.toLowerCase()
      const labelB = b.label.toLowerCase()
      if (labelA < labelB) return -1
      if (labelA > labelB) return 1
      return 0
    })

    results.push(...empresasTemp)

    return results
  }

  const initialFormulario: {
    especialidades: IOptionsEspecialidade[]
    formulario: ID
    empresas: IOptionsEmpresa[]
  } = {
    especialidades: vinculo
      ? [{ label: vinculo.especialidade.nome, value: vinculo.especialidade.id }]
      : [],
    formulario: formularioId,
    empresas: vinculo ? mapEmpresas(vinculo.empresas) : [],
  }

  const getAddedAndDeletedEmpresas = (empresas: ID[]) => {
    const deleteIds = vinculo?.empresas
      .filter((empresa) => !empresas.includes(empresa.id))
      .map((empresa) => empresa.id)

    const addIds = empresas.filter((id) => !vinculo?.empresas.some((empresa) => empresa.id === id))

    return { deleteIds, addIds }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormulario,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const bind = {
        ...values,
        especialidades: values.especialidades.map((especialidade) => especialidade.value),

        empresas: values.empresas.map((empresa) => empresa.value),
      }
      try {
        if (vinculo) {
          const { deleteIds, addIds } = getAddedAndDeletedEmpresas(bind.empresas)

          const deletePromises =
            deleteIds?.map((empresaId) =>
              deleteVinculo(formularioId, vinculo.especialidade.id, empresaId).catch((err) => {
                throw err
              })
            ) || []

          const addPromise =
            addIds.length > 0
              ? bindFormulario({
                  formulario: formularioId,
                  especialidades: [vinculo.especialidade.id],
                  empresas: addIds,
                }).catch((err) => {
                  throw err
                })
              : Promise.resolve(false)

          Promise.all(deletePromises)
            .then((deleteResults) => {
              const allDeleted = deleteResults.length > 0

              return addPromise.then((addSucceeded) => {
                return { allDeleted, addSucceeded }
              })
            })
            .then(({ allDeleted, addSucceeded }) => {
              if (allDeleted && addSucceeded) {
                showAlert({
                  title: 'Sucesso!',
                  text: 'Vínculos excluídos e adicionados com sucesso!',
                  icon: 'success',
                }).then(() => closeModal())
              } else if (allDeleted) {
                showAlert({
                  title: 'Sucesso!',
                  text: 'Vínculos excluídos com sucesso!',
                  icon: 'success',
                }).then(() => closeModal())
              } else if (addSucceeded) {
                showAlert({
                  title: 'Sucesso!',
                  text: 'Novos vínculos adicionados com sucesso!',
                  icon: 'success',
                }).then(() => closeModal())
              }
            })
            .catch((error) => {
              showError(error)
            })
        } else {
          setSubmitting(true)
          bindFormulario(bind)
            .then(() => {
              Swal.fire({
                text: 'Vínculo criado com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => closeModal())
            })
            .catch((error) => {
              showError(error)
            })
        }
      } catch (error: any) {
        showError(error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleEspecialidades = (especialidades: IOptionsEspecialidade[]) =>
    formik.setFieldValue('especialidades', especialidades)

  const handleEmpresas = (empresas: IOptionsEmpresa[]) => formik.setFieldValue('empresas', empresas)

  const removeEmpresa = (empresa: IOptionsEmpresa) => {
    const updateEmpresas = formik.values.empresas.filter(
      (empr: IOptionsEmpresa) => empresa.value != empr.value
    )
    formik.setFieldValue('empresas', updateEmpresas)
  }

  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column me-n7 pe-7 w-500 h-500'>
          <div className='fv-row mb-7'>
            <Label className='mb-2'>Especialidades</Label>
            <CustomSelect
              placeholder='Selecione uma especialidade'
              onChange={(data) => handleEspecialidades(data)}
              fetchOptions={OptionsHelper.getOptionsEspecialidades}
              value={formik.values.especialidades}
              isMulti={!vinculo}
              isDisabled={!!vinculo}
            />
          </div>
          <div className='fv-row mb-7'>
            <Label className='mb-2'>Empresa</Label>
            <CustomSelect
              placeholder='Selecione uma empresa'
              onChange={(empresa) => handleEmpresas(empresa)}
              fetchOptions={OptionsHelper.getOptionsEmpresas}
              value={formik.values.empresas}
              isMulti={true}
            />

            {formik.touched.empresas && formik.errors.empresas && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>Selecione pelo menos uma empresa.</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-8'>Empresas Selecionadas</label>
            <div>
              {formik.values.empresas.map((empresa: IOptionsEmpresa) => (
                <Badge key={empresa.value} onDelete={() => removeEmpresa(empresa)}>
                  {empresa.label}
                </Badge>
              ))}
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => closeModal()}
            className='btn btn-light me-3'
            disabled={formik.isSubmitting}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched || !formik.dirty}
          >
            <span className='indicator-label'>Salvar</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {formik.isSubmitting && <ListLoading />}
    </>
  )
}
