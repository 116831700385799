import React, { FC } from 'react'

interface ITextArea {
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  rows?: number
  cols?: number
  className?: string
}

export const TextArea: FC<ITextArea> = ({
  value,
  onChange,
  placeholder = '',
  rows = 5,
  cols = 30,
  className = '',
}) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      className={`form-control form-control-solid ${className}`}
    />
  )
}
