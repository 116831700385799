import { EspecialistasListSearchComponent } from './EspecialistasListSearchComponent'

const EspecialistasListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <EspecialistasListSearchComponent />
    </div>
  )
}

export { EspecialistasListHeader }
