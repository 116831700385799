import React, { useState, useImperativeHandle, forwardRef, FC } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Input, useModal } from '../../../../../../components'
import { ButtonType, ID, showAlert } from '../../../../../../../_metronic/helpers'
import { registerPendencia, resolvePendencia } from '../../../../atendimento/core/_requests'

interface IAnotacaoModal {
  isRequired: boolean
  atendimentoId: ID
}

export const AnotacaoModal: FC<IAnotacaoModal> = ({ atendimentoId, isRequired }) => {
  const { closeModal } = useModal()
  const [anotacao, setAnotacao] = useState<string>('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isRequired) {
      resolvePendencia(anotacao, atendimentoId).then(() => {
        showAlert({
          title: 'Resolvido',
          text: 'Pendência resolvida com sucesso!',
          icon: 'success',
        }).then(() => closeModal())
      })
    } else {
      registerPendencia(anotacao, atendimentoId).then(() => {
        showAlert({
          title: 'Pendência Adicionada',
          text: 'Pendência adicionada com sucesso!',
          icon: 'success',
        }).then(() => closeModal())
      })
    }
    setAnotacao('')
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Input
        placeholder='Digite a pendência'
        onChange={(e) => setAnotacao(e.target.value)}
        value={anotacao}
        required={isRequired}
      />
      <div className='d-flex justify-content-end pt-3'>
        <Button type={ButtonType.Submit} className='btn-primary'>
          Salvar
        </Button>
      </div>
    </form>
  )
}
