import { IconPaths, ModalSize } from '../../../../../../../_metronic/helpers'
import { Button, useModal } from '../../../../../../components'
import { ImportacaoModalForm } from '../../../form-modal/ImportacaoModalForm'

const ImportacaoListToolbar = () => {
  const { showModal } = useModal()

  const openImportAtendimentos = () => {
    showModal({ title: 'Importação', content: <ImportacaoModalForm />, size: ModalSize.Medium })
  }

  return (
    <div className='d-flex justify-content-end pt-3' data-kt-user-table-toolbar='base'>
      <Button className='btn-primary' onClick={openImportAtendimentos} icon={IconPaths.Add}>
        Nova importação
      </Button>
    </div>
  )
}

export { ImportacaoListToolbar }
