import axios, { AxiosResponse } from 'axios'
import { NPSDataResumo } from './_models'
import { ArquivoDto } from '../../../atendimento/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTO_AVALIACAO_URL = `${API_URL}/atendimentos/avaliacao`

export const getResumoNps = (query: string): Promise<NPSDataResumo> => {
  return axios
    .get(`${ATENDIMENTO_AVALIACAO_URL}/resumo?${query}`)
    .then((d: AxiosResponse<NPSDataResumo>) => d.data)
}

export const getResumoNpsByEmpresa = (empresaId: number, query: string): Promise<NPSDataResumo> => {
  console.log(empresaId, query)
  return axios
    .get(`${ATENDIMENTO_AVALIACAO_URL}/resumo/empresa/${empresaId}?${query}`)
    .then((d: AxiosResponse<NPSDataResumo>) => d.data)
}

export const getResumoNpsByProfissionalSaude = (
  profissionalSaudeId: number,
  query: string
): Promise<NPSDataResumo> => {
  return axios
    .get(`${ATENDIMENTO_AVALIACAO_URL}/resumo/profissional/${profissionalSaudeId}?${query}`)
    .then((d: AxiosResponse<NPSDataResumo>) => d.data)
}

export const getResumoNpsByTelekit = (query: string): Promise<NPSDataResumo> => {
  return axios
    .get(`${ATENDIMENTO_AVALIACAO_URL}/resumo/telekit?${query}`)
    .then((d: AxiosResponse<NPSDataResumo>) => d.data)
}

export const getPdfNps = (query: string): Promise<ArquivoDto> => {
  return axios
    .get(`${ATENDIMENTO_AVALIACAO_URL}/relatorio/pdf?${query}`)
    .then((response) => response.data as ArquivoDto)
}

export const getCsvNps = (query: string): Promise<ArquivoDto> => {
  return axios
    .get(`${ATENDIMENTO_AVALIACAO_URL}/relatorio/csv?${query}`)
    .then((response) => response.data as ArquivoDto)
}
