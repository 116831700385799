import { FC, useState } from 'react'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { getAvatarPaciente } from '../../../../paciente-management/pacientes-list/core/_requests'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { marcarPresencaPaciente } from '../../../../atendimento/core/_requests'
import Swal from 'sweetalert2'
import { Label, ListLoading } from '../../../../../../components'

type Props = {
  atendimentoMedico: AtendimentoView | undefined
  isMarcarPresencaModal?: boolean
  setDisable?: (value: boolean) => void
}

const AtendimentoMedicoInfo: FC<Props> = ({
  atendimentoMedico,
  isMarcarPresencaModal = false,
  setDisable,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false)
  const isCancelado = atendimentoMedico?.estado?.nome === 'Cancelado'
  const falta = atendimentoMedico?.estado?.nome === 'Falta'
  const [avatar, setAvatar] = useState<String | undefined>(undefined)
  const [presenca, setPresenca] = useState(false)

  const getImagemAvatar = () => {
    getAvatarPaciente(atendimentoMedico?.paciente?.id)
      .then((result) => {
        if (result?.data !== null) {
          setAvatar(result?.data)
        } else {
          setAvatar('/media/avatars/AVATARES_05.png')
        }
      })
      .catch((error) => {
        setAvatar('/media/avatars/AVATARES_05.png')
      })
  }

  function calcularIdade(dataNascimento: string) {
    const hoje = new Date()
    const nascimento = new Date(dataNascimento)
    let idade = hoje.getFullYear() - nascimento.getFullYear()
    const mesAtual = hoje.getMonth()
    const mesNascimento = nascimento.getMonth()

    if (
      mesAtual < mesNascimento ||
      (mesAtual === mesNascimento && hoje.getDate() < nascimento.getDate())
    ) {
      idade--
    }

    return idade
  }

  const handleMarcarPresenca = async () => {
    try {
      await marcarPresencaPaciente(atendimentoMedico?.id)
      setPresenca(true)
      Swal.fire({
        text: 'Presença marcarcada com sucesso',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    } catch (error) {}
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked)
    //@ts-ignore
    setDisable(!event.target.checked)
  }

  getImagemAvatar()

  return (
    <>
      {atendimentoMedico ? (
        <>
          <div className='menu-item'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-100px me-8'>
                <img alt='Logo' src={toAbsoluteUrl(String(avatar))} />
              </div>
              <div className='d-flex flex-column'>
                <div className='fw-bold text-muted'>Nome</div>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {atendimentoMedico?.paciente?.nome}
                </div>
                <div className='fw-bold text-muted'>CPF</div>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {atendimentoMedico?.paciente?.pessoa?.cpf}
                </div>
                <div className='fw-bold text-muted'>Sexo</div>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {atendimentoMedico?.paciente?.pessoa?.sexo}
                </div>
                <div className='fw-bold text-muted'>Data Nascimento</div>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {atendimentoMedico?.paciente?.pessoa?.dataNascimentoFormatada} (
                  {calcularIdade(atendimentoMedico?.paciente?.pessoa?.dataNascimento as string)}{' '}
                  anos)
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10' id='kt_atendimento_details_view'>
            <div className='card-body p-15'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Empresa</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {atendimentoMedico?.empresa?.nomeFantasia}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Data Solicitação</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {atendimentoMedico?.solicitacaoFormatada}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Estado</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {atendimentoMedico?.estado?.descricao}
                  </span>
                </div>
              </div>

              {(isCancelado || falta) && (
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Observação</label>
                  {atendimentoMedico?.anotacoes?.map((nota, index) => {
                    if (nota.tipo === 'OBSERVACAO') {
                      return (
                        <div className='col-lg-8' key={index}>
                          <span className='fw-bolder fs-6'>{nota?.anotacao}</span>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              )}

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Protocolo</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{atendimentoMedico?.protocolo}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Retorno</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{atendimentoMedico?.retorno ? 'Sim' : 'Não'}</span>
                </div>
              </div>
              {atendimentoMedico?.tipo === 'ASO' && (
                <>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Cargo</label>
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>{atendimentoMedico.dadosAso?.cargo}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Setor</label>
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>{atendimentoMedico.dadosAso?.setor}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Empresa cliente</label>
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>{atendimentoMedico?.cliente?.nome}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Tipo ASO</label>
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>{atendimentoMedico?.tipoAso}</span>
                    </div>
                  </div>
                </>
              )}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Criado por</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{atendimentoMedico?.criador?.nome}</span>
                </div>
              </div>
              {atendimentoMedico?.anotacoes && atendimentoMedico.anotacoes?.length > 0 ? (
                <div className='row mb-7 bg-secondary py-2'>
                  <label className='col-lg-4 fw-bold text-muted'>Pendente</label>
                  <div className='ps-5'>
                    {atendimentoMedico.anotacoes
                      .filter((anotacao) => anotacao.tipo === 'PENDENCIA')
                      .map((anotacao, index) => (
                        <div key={index} className='col-12'>
                          <Label>{anotacao.dataFormatada} -&nbsp;</Label>
                          <Label>{anotacao.usuario?.nome} -&nbsp;</Label>
                          <Label>{anotacao.anotacao}</Label>
                        </div>
                      ))}
                  </div>
                </div>
              ) : null}
              {atendimentoMedico?.tipo === 'ASO' && !!atendimentoMedico?.fatoresRisco?.length && (
                <>
                  <div className='row mb-3'>
                    <label className='fw-bold text-muted'>Riscos</label>
                  </div>
                  <div className='row mb-7'>
                    <ul className='list-unstyled mb-0'>
                      {atendimentoMedico?.fatoresRisco?.map((risco, index) => (
                        <li key={index} className='fw-bolder fs-6 text-dark'>
                          - {risco.descricaoFatorRisco}: {risco.valor}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
              {atendimentoMedico?.tipo === 'ASO' && !!atendimentoMedico?.procedimentos?.length && (
                <>
                  <div className='row mb-3'>
                    <label className='fw-bold text-muted'>Exames</label>
                  </div>
                  <div className='row mb-7'>
                    <ul className='list-unstyled mb-0'>
                      {atendimentoMedico?.procedimentos?.map((p, index) => (
                        <li key={index} className='fw-bolder fs-6 text-dark'>
                          {!!p?.descricao ? `- ${p.nome}: ${p?.descricao}` : `- ${p.nome}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
            <div className='bg-info p-3 rounded'>
              <div className='form-check'>
                <input
                  className='form-check-input text-dark fw-bold'
                  type='checkbox'
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                />
                <label className='form-check-label text-dark fw-bold' htmlFor='confirmacao1'>
                  Confirmo que conferi os dados do paciente e a veracidade das informações.
                </label>
              </div>
              <div className='mt-4'>
                {!!atendimentoMedico?.presencaPacienteFormatada || presenca ? (
                  <p className='text-success'>Presença do paciente confirmada!</p>
                ) : (
                  <>
                    {isCheckboxChecked && !isMarcarPresencaModal && !presenca && (
                      <button
                        className='btn btn-success text-dark fw-bold'
                        onClick={handleMarcarPresenca}
                      >
                        Confirmar presença do paciente
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ListLoading />
      )}
    </>
  )
}

export { AtendimentoMedicoInfo }
