import { FC, useEffect } from 'react'
import { IconPaths, ID } from '../../../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { Button } from '../../../../../../components'
import { useNavigate } from 'react-router-dom'

type Props = {
  usuarioId: ID
}

export const LogsUsuarioActionsCell: FC<Props> = ({ usuarioId }) => {
  const navigate = useNavigate()

  useEffect(() => MenuComponent.reinitialization(), [])

  return (
    <div className='d-flex flex-end'>
      <Button
        className='btn-primary btn-sm'
        icon={IconPaths.Search}
        onClick={() => navigate(`/apps/logs/usuario/${usuarioId}`)}
      >
        Ver Log
      </Button>
    </div>
  )
}
