import axios, { AxiosResponse } from 'axios'
import { FuncionariosQueryResponse } from '../../../funcionarios-management/funcionarios-list/core/_models'
import { AtendimentoQueryResponse } from './_models'
import { ID } from '../../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTOS_URL = `${API_URL}/atendimentos`
const FUNCIONARIOS_URL = `${API_URL}/funcionarios`

interface PDFObject {
  id: string
  name: string
  type: string
  length: number
  contentBase64: string
}

export const getAtendimentosRelatorio = (query: string): Promise<AtendimentoQueryResponse> => {
  return axios
    .get(`${ATENDIMENTOS_URL}/relatorio?${decodeURIComponent(query)}`)
    .then((d: AxiosResponse<AtendimentoQueryResponse>) => d.data)
}

export const getPdf = (query: string): Promise<PDFObject> => {
  return axios
    .get(`${ATENDIMENTOS_URL}/relatorio/pdf?${decodeURIComponent(query)}`)
    .then((response) => response.data as PDFObject)
}

export const getCsv = (query: string): Promise<PDFObject> => {
  return axios
    .get(`${ATENDIMENTOS_URL}/relatorio/csv?${decodeURIComponent(query)}`)
    .then((response) => response.data as PDFObject)
}

export const getSolicitante = (query: string): Promise<FuncionariosQueryResponse> => {
  return axios
    .get(`${FUNCIONARIOS_URL}?${decodeURIComponent(query)}`)
    .then((d: AxiosResponse<FuncionariosQueryResponse>) => d.data)
}
