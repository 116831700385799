import { ImportacaoDetalheListHeader } from './ImportacaoDetalheListHeader'
import { KTCard } from '../../../../../../_metronic/helpers'
import { ImportacaoDetalheListTable } from '../table/ImportacaoDetalheListTable'

export const ImportacaoDetalheList = () => {
  return (
    <>
      <KTCard>
        <ImportacaoDetalheListHeader />
        <ImportacaoDetalheListTable />
      </KTCard>
    </>
  )
}
