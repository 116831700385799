import React, { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContextSemPaginacao,
  initialQueryResponseSemPaginacao,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { NPSDataResumo } from './_models'
import { getResumoNps } from './_requests'

const QueryResponseContext = createResponseContextSemPaginacao<NPSDataResumo>(
  initialQueryResponseSemPaginacao
)

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()

  const [query, setQuery] = useState<string>(decodeURIComponent(stringifyRequestQuery(state)))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    console.log(updatedQuery)
    if (query !== updatedQuery) {
      setQuery(decodeURIComponent(updatedQuery))
    }
  }, [query, updatedQuery])

  const { isFetching, refetch, data } = useQuery(
    `${QUERIES.NPS_LIST}-${query}`,
    () => getResumoNps(query),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        singleResponse: data,
        query,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

export { QueryResponseProvider, useQueryResponse }
