import { Modal } from 'react-bootstrap'
import { KTCard } from '../../../../../_metronic/helpers'
import { HistoricoListHeader } from './component/header/HistoricoListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { HistoricoTable } from './table/HistoricoTable'

const HistoricoList = () => {
  return (
    <KTCard>
      <HistoricoListHeader />
      <HistoricoTable />
    </KTCard>
  )
}

const HistoricoListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <HistoricoList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { HistoricoListWrapper }
