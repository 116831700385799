import * as Yup from 'yup'

import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { ID, isNotEmpty } from '../../../../../../_metronic/helpers'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import Select from 'react-select'
import { getEmpresaClienteById } from '../../empresasCliente-list/core/_request'
import { getCargosCliente } from '../../empresasClienteCargo-list/core/_request'
import { createExameCliente, editExameCliente } from '../core/_request'
import { ExameAsoView } from '../core/_models'
import { getSetoresCliente } from '../../empresasClienteSetor-list/core/_request'
import { FetchState, ListLoading, useModal } from '../../../../../components'

const ExameClienteEditModalForm: FC = () => {
  const { fetchStates, closeModal } = useModal()

  const { data: exame, isLoading } = (fetchStates['exame'] || {}) as FetchState<ExameAsoView>

  const { refetch } = useQueryResponse()
  const [searchParams] = useSearchParams()
  const idEmpresaCliente = Number(searchParams.get('cliente'))
  const [optionsSetor, setOptionsSetor] = useState<any>([])
  const [optionsCargo, setOptionsCargo] = useState<any>([])

  const INITIAL_DATA_SETOR = {
    value: exame?.setor !== undefined ? exame.setor?.id : 0,
    label: exame?.setor !== undefined ? exame.setor?.nome : 'Selecione um setor',
  }
  const [selectDataSetor, setSelectDataSetor] = useState(INITIAL_DATA_SETOR)

  const INITIAL_DATA_CARGO = {
    value: exame?.cargo !== undefined ? exame.cargo?.id : 0,
    label: exame?.cargo !== undefined ? exame.cargo?.nome : 'Selecione um cargo',
  }
  const [selectDataCargo, setSelectDataCargo] = useState(INITIAL_DATA_CARGO)

  function getOptionsSetores(id: ID) {
    getSetoresCliente(id, `page=0&size=100&search=cliente:${idEmpresaCliente}`)
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.data?.forEach((value) => {
            results.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsSetor([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  function getOptionsCargos(id: ID) {
    getCargosCliente(id, 'page=0&size=100&search=cliente.id:' + idEmpresaCliente)
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.data?.forEach((value) => {
            results.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsCargo([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  //consulta pelo nome do responsavel
  const editExameSchema = Yup.object().shape({
    setor: Yup.string().required('Setor é obrigatório'),
    cargo: Yup.string().required('Cargo é obrigatório'),
    exame: Yup.string().required('Descrição é obrigatório'),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    closeModal()
  }
  const exameClienteForEdit = {
    id: exame?.id !== undefined ? exame.id : undefined,
    exame: exame?.exame !== undefined ? exame.exame : '',
    setor: exame?.setor !== undefined ? exame.setor.id : undefined,
    cargo: exame?.cargo !== undefined ? exame.cargo.id : undefined,
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: exameClienteForEdit,
    validationSchema: editExameSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          editExameCliente(values.id, values.cargo, values.setor, values.exame, true)
            .then((result) => {
              Swal.fire({
                text: 'Exame alterado com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => cancel(true))
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              if (errors !== undefined) {
                setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
              } else {
                Swal.fire({
                  text: 'Não foi possível alterar o Exame!',
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
            })
        } else {
          createExameCliente(idEmpresaCliente, values.cargo, values.setor, values.exame)
            .then((result) => {
              Swal.fire({
                text: 'Exame criado com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => cancel(true))
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              if (errors !== undefined) {
                setFieldError('cargo', errors['cargo'] !== undefined ? errors.cargo[0] : '')
                setFieldError('setor', errors['setor'] !== undefined ? errors.setor[0] : '')
              } else {
                Swal.fire({
                  text: 'Não foi possível criar Exame!',
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
            })
        }
      } catch (error: any) {
        const errors = error.response.data.data.errors
        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
        setFieldError('cargo', errors['cargo'] !== undefined ? errors.cargo[0] : '')
        setFieldError('setor', errors['setor'] !== undefined ? errors.setor[0] : '')
        console.error(error)
      } finally {
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    if (idEmpresaCliente !== undefined) {
      getEmpresaClienteById(idEmpresaCliente)
        .then((result) => {
          getOptionsSetores(result?.empresa?.id)
          getOptionsCargos(result?.empresa?.id)
          setSelectDataSetor({
            value: exame?.setor !== undefined ? exame.setor?.id : 0,
            label: exame?.setor !== undefined ? exame.setor?.nome : 'Selecione um setor',
          })
          setSelectDataCargo({
            value: exame?.cargo !== undefined ? exame.cargo?.id : 0,
            label: exame?.cargo !== undefined ? exame.cargo?.nome : 'Selecione um cargo',
          })
        })
        .catch((error) => {
          const err = error as AxiosError
          Swal.fire({
            text: 'Erro ao buscar empresa! ' + err.response?.data.data.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        })
    } else {
      Swal.fire({
        text: 'Informe a Empregador ASO ',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }, [exame])

  return (
    <>
      <form
        id='kt_modal_add_empresa_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column '
          id='kt_modal_add_empresa_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_empresa_header'
          data-kt-scroll-wrappers='#kt_modal_add_empresa_scroll'
          // data-kt-scroll-offset='300px'
        >
          {/*           
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Setor</label>
            <Select
              placeholder='Selecione um setor'
              onChange={(data: any) => {
                setSelectDataSetor(data)
                formik.setFieldValue('setor', data.value)
              }}
              options={optionsSetor}
              className='form-control form-control-solid'
              // defaultValue={{ label: "Selecione um valor", value: "0" }}
              value={selectDataSetor}
            />
            {formik.touched.setor && formik.errors.setor && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.setor}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Cargo</label>
            <Select
              placeholder='Selecione um cargo'
              onChange={(data: any) => {
                setSelectDataCargo(data)
                formik.setFieldValue('cargo', data.value)
              }}
              options={optionsCargo}
              className='form-control form-control-solid'
              // defaultValue={{ label: "Selecione um valor", value: "0" }}
              value={selectDataCargo}
            />
            {formik.touched.cargo && formik.errors.cargo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cargo}</span>
                </div>
              </div>
            )}
            {formik.touched.cargo && formik.errors.cargo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cargo}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Exame</label>
            <input
              placeholder='Exame'
              {...formik.getFieldProps('exame')}
              type='text'
              name='exame'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.exame && formik.errors.exame },
                {
                  'is-valid': formik.touched.exame && !formik.errors.exame,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {formik.touched.exame && formik.errors.exame && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.exame}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
        </div>

        {/* </div> */}
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export { ExameClienteEditModalForm }
