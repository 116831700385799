/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { ativarEmpresa, getEmpresaById, getLogo, inativarEmpresa } from '../../core/_requests'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { Row } from 'react-table'
import { Dropdown, DropdownItem, useModal } from '../../../../../../components'
import { EmpresaList } from '../../core/_models'
import { EmpresaEditModalForm } from '../../empresa-edit-modal/EmpresaEditModalForm'
type Props = {
  id: ID
  row: Row<EmpresaList>
}

const EmpresasActionsCell: FC<Props> = ({ id, row }) => {
  const { showModal } = useModal()

  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => MenuComponent.reinitialization(), [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
    showModal({
      title: 'Empresa',
      content: <EmpresaEditModalForm />,
      fetchers: {
        logo: () => getLogo(id),
        empresa: () => getEmpresaById(id),
      },
    })
  }

  const pacientes = () => {
    window.location.href = '/apps/pacientes-management/pacientes?empresa=' + id
  }
  const deleteItem = useMutation(() => inativarEmpresa(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const activeItem = useMutation(() => ativarEmpresa(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })
  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={openEditModal}>Alterar</DropdownItem>
      <DropdownItem
        onClick={async () =>
          row.original.ativa ? await deleteItem.mutateAsync() : await activeItem.mutateAsync()
        }
      >
        {row.original.ativa ? 'Inativar' : 'Ativar'}
      </DropdownItem>
    </Dropdown>
  )
}

export { EmpresasActionsCell }
