import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, ModalSize, onDeleteAlert } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteRiscoCliente, getRiscoClienteById } from '../../core/_request'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { Dropdown, DropdownItem, useModal } from '../../../../../../components'
import { RiscoClienteEditModalForm } from '../../risco-edit-modal/RiscoClienteEditModalForm'
type Props = {
  id: ID
}

const RiscoActionsCell: FC<Props> = ({ id }) => {
  const { showModal } = useModal()
  const { refetch } = useQueryResponse()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    showModal({
      title: 'Risco',
      content: <RiscoClienteEditModalForm />,
      size: ModalSize.Medium,
      fetchers: {
        risco: () => getRiscoClienteById(id),
      },
    })
  }

  const deleteItem = useMutation(() => deleteRiscoCliente(id), {
    onSuccess: () => {
      Swal.fire({
        text: 'Risco excluído com sucesso!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then((_) => refetch())
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const handleDelete = () => onDeleteAlert({ onAction: deleteItem.mutateAsync })

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={openEditModal}>Alterar</DropdownItem>
      <DropdownItem onClick={handleDelete}>Remover</DropdownItem>
    </Dropdown>
  )
}

export { RiscoActionsCell }
