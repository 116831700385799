import { useSearchParams } from 'react-router-dom'
import { KTCard } from '../../../../../_metronic/helpers'
import { VinculosListHeader } from './component/header/VinculosListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { VinculosTable } from './table/VinculosTable'
import { useEffect } from 'react'
import { getFormularioById } from '../formularios-create/core/_requests'

const VinculosList = () => {
  const { setItemIdForUpdate, setNomeFormulario } = useListView()
  const [searchParams] = useSearchParams()
  const formId = Number(searchParams.get('id'))

  useEffect(() => {
    const fetchData = async () => {
      try {
        const form = await getFormularioById(formId)
        setNomeFormulario && setNomeFormulario(form?.nome ?? '')
      } catch (error) {
        console.error('Error fetching formulario:', error)
        setItemIdForUpdate(undefined)
      }
    }

    fetchData()
  }, [])

  return (
    <KTCard>
      <VinculosListHeader />
      <VinculosTable />
    </KTCard>
  )
}

const VinculosListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <VinculosList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { VinculosListWrapper }
