import { FC, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { Button, FetchState, useModal } from '../../../../../../components'

export const TokenModal: FC = () => {
  const { fetchStates } = useModal()

  const { data: accessToken } = (fetchStates['setor'] || {}) as FetchState<string>

  const [copied, setCopied] = useState<boolean>(false)

  const handleCopyToken = () => {
    navigator.clipboard.writeText(accessToken || '')
    setCopied(true)
  }

  return (
    <>
      <p>Token gerado com sucesso. Copie o token abaixo:</p>
      <InputGroup>
        <FormControl
          as='textarea'
          //@ts-ignore
          value={accessToken}
          readOnly
          aria-label='Generated Token'
          rows={5}
        />
        <Button className='btn-secondary' onClick={handleCopyToken}>
          {copied ? 'Copiado' : 'Copiar'}
        </Button>
      </InputGroup>
    </>
  )
}
