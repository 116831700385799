/* eslint-disable react/jsx-no-target-blank */

import { CargoType, KTSVG, Permission, Tipo } from '../../../helpers'
import { useAuth, UsuarioDtoVinculo } from '../../../../app/modules/auth'
import { useEffect, useState } from 'react'

import { AsideMenuItem } from './AsideMenuItem'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import { getLoggedUser } from '../../../../app/modules/auth/core/_requests'
import { useMediaQuery } from 'react-responsive'

type DropdownMenuProps = {
  title: string
  icon: string
  children: React.ReactNode
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ title, icon, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={clsx('menu-item menu-accordion', { 'menu-accordion-active': isOpen })}>
      <div className='menu-link' onClick={() => setIsOpen(!isOpen)}>
        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        <span className='menu-title'>{title}</span>
        <span className={clsx('menu-arrow', { 'rotate-180': isOpen })}></span>
      </div>
      <div
        className={clsx('menu-sub menu-sub-accordion', { 'd-block': isOpen, 'd-none': !isOpen })}
        style={{ paddingLeft: '20px' }}
      >
        {children}
      </div>
    </div>
  )
}

export function AsideMenuMain() {
  const [usuario, setUsuario] = useState<UsuarioDtoVinculo>()
  const [isMedico, setIsMedico] = useState(false)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [isColaborador, setIsColaborador] = useState(false)
  const [isResponsavel, setIsResponsavel] = useState(false)
  const [isPacienteLogado, setIsPacienteLogado] = useState(false)
  const [isMedicoAdmin, setIsMedicoAdmin] = useState(false)
  const [isGerente, setIsGerente] = useState(false)
  const [isEmployee, setIsEmployee] = useState(false)
  const [hasASO, setHasAso] = useState(false)

  const mostrarPaginaEmpresa = isSuperAdmin || isGerente
  const mostrarPaginaApi = isSuperAdmin || isResponsavel
  const mostrarPaginaEsp = isSuperAdmin || isMedicoAdmin
  const mostrarPaginaEmpresaCliente =
    (isSuperAdmin || isResponsavel || isMedico || isColaborador || isMedicoAdmin || isEmployee) &&
    hasASO
  const mostrarPaginaFuncionarios = isSuperAdmin || isResponsavel || isMedicoAdmin || isGerente
  const mostrarPaginaPacientes = isSuperAdmin || isResponsavel || isColaborador || isMedicoAdmin
  const mostrarPaginaAtendimentos =
    isSuperAdmin || isMedico || isColaborador || isResponsavel || isMedicoAdmin || isEmployee
  const mostrarPaginaAssinarAso = (isSuperAdmin || isMedico || isMedicoAdmin) && hasASO
  const mostrarPaginaDownloadAso =
    (isSuperAdmin || isResponsavel || isColaborador || isMedicoAdmin || isEmployee) && hasASO
  const mostrarPaginaRelatorios = isSuperAdmin || isMedicoAdmin || isGerente || isResponsavel
  const mostrarPaginaAvaliacoesPendentes = isSuperAdmin || isMedicoAdmin || isMedico
  const mostrarPaginaAvaliacoesConcluidas =
    isSuperAdmin || isColaborador || isResponsavel || isMedico
  const mostrarPaginaFormularios = isSuperAdmin // quem vai ver essa pagina?
  const mostrarPaginaMeusAtendimentos = isSuperAdmin || isMedicoAdmin || isGerente || isMedico
  const mostrarPaginaLogs = isSuperAdmin

  const mostrarPaginaImportar = isSuperAdmin || isResponsavel || isColaborador || isMedicoAdmin
  const mostrarPaginaRelatorioNps = isSuperAdmin
  const mostrarPaginaEspecialistas = isSuperAdmin || isResponsavel || isMedicoAdmin || isGerente

  const { logout } = useAuth()

  const isMobile = useMediaQuery({ maxWidth: 767 })

  function loadRoles() {
    getLoggedUser()
      .then((responseData) => {
        setUsuario(responseData.data)
      })
      .catch((error) => {
        Swal.fire({
          text: 'Erro ao verificar usuário logado!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  useEffect(() => {
    loadRoles()
  }, [])

  useEffect(() => {
    if (usuario) {
      setIsSuperAdmin(usuario?.grupo?.nome === Permission.SuperAdmin || false)
      setIsEmployee(usuario?.grupo?.nome === Permission.Medico || false)
      setIsGerente(usuario.grupo?.nome === Permission.SuperGerente || false)
      setIsPacienteLogado(usuario?.grupo?.nome === Permission.Usuario)
      setIsMedicoAdmin(usuario?.grupo?.nome === Permission.SuperMedico)

      setIsMedico(usuario?.vinculos?.some((vinculo) => vinculo.cargo === CargoType.Medico) || false)
      setIsColaborador(
        usuario?.vinculos?.some((vinculo) => vinculo.cargo === CargoType.Colaborador) || false
      )
      setIsResponsavel(
        usuario?.vinculos?.some((vinculo) => vinculo.cargo === CargoType.Responsavel) || false
      )
      setHasAso(
        usuario?.vinculos?.some((vinculo) =>
          vinculo?.empresa?.tiposAtendimento?.includes(Tipo.ASO)
        ) ?? false
      )
    }
  }, [usuario])

  return (
    <div className='d-flex flex-column'>
      {!!usuario ? (
        isPacienteLogado ? (
          <div>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                  Atendimentos
                </span>
              </div>
            </div>
            <AsideMenuItem
              to='/apps/atendimentos/paciente'
              icon='/media/icons/duotune/communication/com005.svg'
              title='Meus atendimentos'
            />
          </div>
        ) : (
          <>
            {mostrarPaginaEmpresa && (
              <AsideMenuItem
                to='/apps/empresas/lista'
                title='Empresas'
                icon='/media/icons/duotune/general/gen001.svg'
              />
            )}
            {mostrarPaginaFuncionarios && (
              <AsideMenuItem
                to='/apps/funcionarios-management/funcionarios'
                title='Funcionários'
                icon='/media/icons/duotune/communication/com014.svg'
              />
            )}

            {mostrarPaginaEmpresaCliente && (
              <AsideMenuItem
                to='/apps/empresasCliente/lista'
                icon='/media/icons/duotune/communication/com009.svg'
                title='Empregador ASO'
              />
            )}

            {mostrarPaginaApi && (
              <AsideMenuItem
                to='/apps/api'
                icon='/media/icons/duotune/coding/cod009.svg'
                title='API'
              />
            )}

            {mostrarPaginaEsp && (
              <AsideMenuItem
                to='/apps/especialidades'
                icon='/media/icons/duotune/general/gen003.svg'
                title='Especialidades'
              />
            )}

            {mostrarPaginaEspecialistas && (
              <AsideMenuItem
                to='/apps/especialistas'
                icon='/media/icons/duotune/general/gen003.svg'
                title='Especialistas'
              />
            )}

            {mostrarPaginaFormularios && (
              <AsideMenuItem
                to='/apps/formularios'
                icon='/media/icons/duotune/coding/cod007.svg'
                title='Formularios'
              />
            )}

            <div className='menu-item'>
              <div className='menu-content pt-8 pb-0'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
              </div>
            </div>

            {/* Dropdown para Atendimentos */}
            <DropdownMenu title='Atendimentos' icon='/media/icons/duotune/communication/com005.svg'>
              <AsideMenuItem
                to='/apps/atendimentosMedicos/lista'
                title='Lista de atendimentos'
                icon='/media/icons/duotune/files/fil001.svg'
              />
              {mostrarPaginaImportar && (
                <AsideMenuItem
                  to='/apps/importacao/lista'
                  title='Importar atendimentos'
                  icon='/media/icons/duotune/files/fil003.svg'
                />
              )}
              {mostrarPaginaAssinarAso && (
                <AsideMenuItem
                  to='/apps/atendimentosAso/lista'
                  title={`Assinar Aso's`}
                  icon='/media/icons/duotune/communication/com008.svg'
                />
              )}
              {mostrarPaginaAvaliacoesPendentes && (
                <AsideMenuItem
                  to='/apps/avaliacoes/pendentes/lista'
                  title='Assinar Avaliações'
                  icon='/media/icons/duotune/files/fil008.svg'
                />
              )}
              {mostrarPaginaAvaliacoesConcluidas && (
                <AsideMenuItem
                  to='/apps/avaliacoes/concluidas/lista'
                  title='Avaliações'
                  icon='/media/icons/duotune/files/fil008.svg'
                />
              )}
              {mostrarPaginaMeusAtendimentos && (
                <AsideMenuItem
                  to='/apps/meus-atendimentos'
                  icon='/media/icons/duotune/communication/com005.svg'
                  title='Meus Atendimentos'
                />
              )}
            </DropdownMenu>

            {mostrarPaginaDownloadAso && (
              <AsideMenuItem
                to='/apps/downloadAso/download'
                icon='/media/icons/duotune/arrows/arr044.svg'
                title={`Baixar ASO's`}
              />
            )}

            {mostrarPaginaLogs && (
              <DropdownMenu title='Logs' icon='/media/icons/duotune/finance/fin006.svg'>
                <AsideMenuItem
                  to='/apps/logs/consulta'
                  icon='/media/icons/duotune/arrows/arr005.svg'
                  title='Por Consulta'
                />
                <AsideMenuItem
                  to='/apps/logs/usuario'
                  icon='/media/icons/duotune/arrows/arr005.svg'
                  title='Por Usuário'
                />
              </DropdownMenu>
            )}

            {mostrarPaginaRelatorios && (
              <AsideMenuItem
                to='/apps/relatorios/atendimentos'
                icon='/media/icons/duotune/files/fil001.svg'
                title='Relatórios'
              />
            )}

            {mostrarPaginaRelatorioNps && (
              <AsideMenuItem
                to='/apps/relatorio-nps'
                icon='/media/icons/duotune/files/fil001.svg'
                title='Relatórios NPS'
              />
            )}

            {mostrarPaginaPacientes && (
              <AsideMenuItem
                to='/apps/pacientes-management/pacientes'
                icon='/media/icons/duotune/communication/com006.svg'
                title='Pacientes'
              />
            )}
          </>
        )
      ) : (
        <></>
      )}

      {isMobile && (
        <div className='m-2 px-2'>
          <button
            className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'
            onClick={logout}
          >
            <span className='menu-icon d-flex align-items-center' data-kt-element='icon'>
              <i className='bi bi-box-arrow-right m-8 mx-5'></i>
              <span>Sair</span>
            </span>
          </button>
        </div>
      )}
    </div>
  )
}
