import { ImportacaoErroListToolbar } from './ImportacaoErroListToolbar'

const ImportacaoErroListHeader = () => {
  return (
    <div className='border-0 pt-6'>
      <div className='card-toolbar'>
        <ImportacaoErroListToolbar />
      </div>
    </div>
  )
}

export { ImportacaoErroListHeader }
