import { KTSVG, ModalSize } from '../../../../../../../_metronic/helpers'
import { useModal } from '../../../../../../components'
import { CargoClienteEditModalForm } from '../../cargo-edit-modal/CargoClienteEditModalForm'
import { useListView } from '../../core/ListViewProvider'

const CargoClienteListToolbar = () => {
  const { showModal } = useModal()

  const openAddCargo = () => {
    showModal({
      title: 'Cargo',
      content: <CargoClienteEditModalForm />,
      size: ModalSize.Medium,
    })
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={openAddCargo}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar
      </button>
    </div>
  )
}

export { CargoClienteListToolbar }
