import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { EspecialistasListWrapper } from './EspecialistasList'
const apiBreadcrumbs: Array<PageLink> = [
  {
    title: 'Especialistas',
    path: '/apps/especialistas',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EspecialistasPage = () => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={apiBreadcrumbs}>Especialistas</PageTitle>
            <EspecialistasListWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default EspecialistasPage
