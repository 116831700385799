import React, { useEffect, useState } from 'react'

interface IDateInputProps {
  type?: string
  name?: string
  placeholder?: string
  value?: string | number | undefined
  required?: boolean
  className?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  hasMax?: boolean
  errorMessage?: string
  isValid?: boolean
  isInvalid?: boolean
}

export const DateInput: React.FC<IDateInputProps> = ({
  placeholder,
  value: initialValue,
  required,
  className,
  disabled,
  onChange,
  hasMax = undefined,
  errorMessage,
  isInvalid,
  isValid,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => setValue(initialValue), [initialValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) onChange(e)
  }

  return (
    <div className='w-100'>
      <input
        {...props}
        type='date'
        className={`form-control form-control-solid ${className} ${
          isInvalid ? 'is-invalid' : isValid ? 'is-valid' : ''
        }`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        max={hasMax ? new Date().toISOString().split('T')[0] : undefined}
      />
      {errorMessage && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{errorMessage}</span>
          </div>
        </div>
      )}
    </div>
  )
}

DateInput.defaultProps = {
  name: '',
  placeholder: 'Digite a data inicial',
  value: '',
  required: false,
  disabled: false,
  className: '',
  onChange: () => {},
}
