import { FC, useState } from 'react'
import {
  Button,
  ButtonIcon,
  Input,
  NavBar,
  NavBarItem,
  TextArea,
} from '../../../../../../components'
import { IconPaths, KTCard, showAlert } from '../../../../../../../_metronic/helpers'
import { adicionarProcedimento } from '../../../../atendimento/core/_requests'
import { useSearchParams } from 'react-router-dom'
import { AtendimentoDtoProcedimento } from '../../core/_models'

type Receita = {
  medicamento: string
  dosagem: string
  administracao: string
}

type ExameComplementar = {
  exame: string
  justificativa: string
}

type Atestado = {
  tipo: string
  validade: string
  observacoes: string
}

export type ResultJSON = {
  diagnostico: string
  receita: Receita[]
  orientacoes_medicas: string[]
  atestados: Atestado[]
  exames_complementares: ExameComplementar[]
}

type NavbarItem = {
  label: string
  value: string | Receita[] | ExameComplementar[] | Atestado[] | undefined
}

type IResultadoIAModal = {
  resultData?: ResultJSON | undefined
}

export const ResultadoIAModal: FC<IResultadoIAModal> = ({ resultData }) => {
  const [searchParams] = useSearchParams()
  const atendimentoId = String(searchParams.get('atendimento'))

  const [selected, setSelected] = useState<string>('Diagnóstico')
  const [navbarData, setNavbarData] = useState<NavbarItem[]>([
    { label: 'Diagnóstico', value: resultData?.diagnostico },
    { label: 'Orientações Médicas', value: resultData?.orientacoes_medicas.join('\n') },
    { label: 'Receitas', value: resultData?.receita },
    { label: 'Exames', value: resultData?.exames_complementares },
    { label: 'Atestados', value: resultData?.atestados },
  ])

  const updateNavbarItem = (label: string, newValue: any) => {
    setNavbarData((prev) =>
      prev.map((item) => (item.label === label ? { ...item, value: newValue } : item))
    )
    if (resultData) {
      switch (label) {
        case 'Diagnóstico':
          resultData.diagnostico = newValue
          break
        case 'Orientações médicas':
          resultData.orientacoes_medicas = newValue.split('\n')
          break
        case 'Receitas':
          resultData.receita = newValue
          break
        case 'Exames':
          resultData.exames_complementares = newValue
          break
        case 'Atestados':
          resultData.atestados = newValue
          break
      }
    }
  }

  const renderContent = () => {
    const selectedItem = navbarData.find((item) => item.label === selected)

    if (!selectedItem) return <p>Sem dados disponíveis.</p>

    const { value } = selectedItem

    if (typeof value === 'string') {
      return (
        <TextArea
          value={value}
          onChange={(e) => updateNavbarItem(selected, e.target.value)}
          placeholder={selected}
        />
      )
    }

    if (Array.isArray(value)) {
      if (selected === 'Receitas') {
        return (
          <>
            <div className='row mb-2'>
              <div className='col-4'>
                <strong>Medicamento</strong>
              </div>
              <div className='col-3'>
                <strong>Dosagem</strong>
              </div>
              <div className='col-4'>
                <strong>Adminstração</strong>
              </div>
            </div>
            {(value as Receita[]).map((item, index) => (
              <div key={index} className='mb-3'>
                <div className='row'>
                  <div className='col-4'>
                    <Input
                      value={item.medicamento}
                      placeholder='Medicamento'
                      onChange={(e) => {
                        const updatedValue = [...(value as Receita[])]
                        updatedValue[index].medicamento = e.target.value
                        updateNavbarItem(selected, updatedValue)
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    <Input
                      value={item.dosagem}
                      placeholder='Dosagem'
                      onChange={(e) => {
                        const updatedValue = [...(value as Receita[])]
                        updatedValue[index].dosagem = e.target.value
                        updateNavbarItem(selected, updatedValue)
                      }}
                    />
                  </div>
                  <div className='col-4'>
                    <Input
                      value={item.administracao}
                      placeholder='Administração'
                      onChange={(e) => {
                        const updatedValue = [...(value as Receita[])]
                        updatedValue[index].administracao = e.target.value
                        updateNavbarItem(selected, updatedValue)
                      }}
                    />
                  </div>
                  <div className='d-flex col-1 justify-content-center align-items-center'>
                    <ButtonIcon
                      color='black'
                      icon={IconPaths.Trash}
                      onClick={() => {
                        const updatedValue = (value as Receita[]).filter(
                          (_: any, i: number) => i !== index
                        )
                        updateNavbarItem(selected, updatedValue)
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </>
        )
      }

      if (selected === 'Exames') {
        return (
          <>
            <div className='row mb-2'>
              <div className='col-5'>
                <strong>Exame</strong>
              </div>
              <div className='col-5'>
                <strong>Justificativa</strong>
              </div>
            </div>
            {(value as ExameComplementar[]).map((item, index) => {
              const createProcedimento = async () => {
                const procedimento: AtendimentoDtoProcedimento = {
                  id: null,
                  nome: (value as ExameComplementar[])[index].exame,
                  descricao: (value as ExameComplementar[])[index].justificativa,
                  data: null,
                }
                await adicionarProcedimento(Number(atendimentoId), procedimento)
                showAlert({
                  title: 'Sucesso!',
                  text: 'Procedimento criado com sucesso!',
                  icon: 'success',
                })
              }
              return (
                <div key={index} className='row mb-3'>
                  <div className='col-5'>
                    <Input
                      value={item.exame}
                      onChange={(e) => {
                        const updatedValue = [...(value as ExameComplementar[])]
                        updatedValue[index].exame = e.target.value
                        updateNavbarItem(selected, updatedValue)
                      }}
                      placeholder='Exame'
                    />
                  </div>
                  <div className='col-5'>
                    <TextArea
                      value={item.justificativa}
                      rows={3}
                      onChange={(e) => {
                        const updatedValue = [...(value as ExameComplementar[])]
                        updatedValue[index].justificativa = e.target.value
                        updateNavbarItem(selected, updatedValue)
                      }}
                      placeholder='Justificativa'
                    />
                  </div>
                  <div className='col-2 d-flex justify-content-center align-items-center'>
                    <ButtonIcon
                      color='black'
                      icon={IconPaths.Trash}
                      onClick={() => {
                        const updatedValue = (value as ExameComplementar[]).filter(
                          (_: any, i: number) => i !== index
                        )
                        updateNavbarItem(selected, updatedValue)
                      }}
                    />
                    <Button className='btn-sm btn-primary' onClick={createProcedimento}>
                      Criar Exame
                    </Button>
                  </div>
                </div>
              )
            })}
          </>
        )
      }

      if (selected === 'Atestados') {
        return (
          <>
            <div className='row mb-2'>
              <div className='col-3'>
                <strong>Atestado</strong>
              </div>
              <div className='col-3'>
                <strong>Validade</strong>
              </div>
              <div className='col-5'>
                <strong>Observações</strong>
              </div>
            </div>
            {(value as Atestado[]).map((item, index) => (
              <div key={index} className='row mb-3'>
                <div className='col-3'>
                  <Input
                    value={item.tipo}
                    placeholder='Atestado'
                    onChange={(e) => {
                      const updatedValue = [...(value as Atestado[])]
                      updatedValue[index].tipo = e.target.value
                      updateNavbarItem(selected, updatedValue)
                    }}
                  />
                </div>
                <div className='col-3'>
                  <Input
                    value={item.validade}
                    placeholder='Validade'
                    onChange={(e) => {
                      const updatedValue = [...(value as Atestado[])]
                      updatedValue[index].validade = e.target.value
                      updateNavbarItem(selected, updatedValue)
                    }}
                  />
                </div>
                <div className='col-5'>
                  <TextArea
                    value={item.observacoes}
                    placeholder='Observações'
                    rows={3}
                    onChange={(e) => {
                      const updatedValue = [...(value as Atestado[])]
                      updatedValue[index].observacoes = e.target.value
                      updateNavbarItem(selected, updatedValue)
                    }}
                  />
                </div>
                <div className='col-1 d-flex justify-content-center align-items-center'>
                  <ButtonIcon
                    color='black'
                    icon={IconPaths.Trash}
                    onClick={() => {
                      const updatedValue = (value as Atestado[]).filter(
                        (_: any, i: number) => i !== index
                      )
                      updateNavbarItem(selected, updatedValue)
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        )
      }
    }

    return <p>Conteúdo não suportado!</p>
  }

  const copyToClipboard = (data: string | string[] | object[]) => {
    let formattedData: string

    if (typeof data === 'string') {
      formattedData = data
    } else if (Array.isArray(data)) {
      if (data.length > 0 && typeof data[0] === 'object') {
        formattedData = (data as object[])
          .map((item) =>
            Object.entries(item)
              .map(([_, value]) => `${value}`)
              .join(' - ')
          )
          .join('\n')
      } else {
        formattedData = (data as string[]).join('\n')
      }
    } else {
      formattedData = String(data)
    }

    navigator.clipboard.writeText(formattedData)
  }

  const copyData = () => {
    const selectedItem = navbarData.find((item) => item.label === selected)
    if (selectedItem && selectedItem.value) {
      copyToClipboard(selectedItem.value)
    }
  }

  return (
    <div>
      <KTCard className='bg-gray-400'>
        <NavBar>
          {navbarData.map(({ label }, index) => (
            <NavBarItem
              isActive={label === selected}
              onClick={() => setSelected(label)}
              key={label}
              isFirst={index === 0}
              isLast={index === navbarData.length - 1}
            >
              {label}
            </NavBarItem>
          ))}
        </NavBar>
        <div className='content p-6'>{renderContent()}</div>
      </KTCard>
      <div className='d-flex flex-end'>
        <Button className='btn-primary mt-5' onClick={copyData}>
          Copiar {selected}
        </Button>
      </div>
    </div>
  )
}
