import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useModal } from './ModalContext'

export const CustomModal: React.FC = () => {
  const { isOpen, closeModal, modalContent, modalTitle, modalSize, fetchStates } = useModal()

  const isLoading = Object.values(fetchStates).some((state) => state.isLoading)

  return (
    <Modal className={modalSize} show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className='text-center py-5'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Carregando...</span>
            </Spinner>
            <p className='mt-3'>Carregando os dados, por favor espere...</p>
          </div>
        ) : (
          modalContent
        )}
      </Modal.Body>
    </Modal>
  )
}
