import * as Yup from 'yup'

import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'

import {
  DocumentoType,
  ID,
  isNotEmpty,
  OptionsHelper,
  ButtonType,
} from '../../../../../../_metronic/helpers'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { IMaskInput } from 'react-imask'
import { ClienteView } from '../core/_models'
import { createEmpresaCliente, editEmpresaCliente, getEmpresaClienteById } from '../core/_request'
import {
  Button,
  CustomSelect,
  FetchState,
  Input,
  Label,
  ListLoading,
  LoadingIndicator,
  Radio,
  useModal,
} from '../../../../../components'

const EmpresaClienteEditModalForm: FC = () => {
  const { fetchStates, closeModal } = useModal()

  const { data: cliente, isLoading: isEmpresaLoading } = (fetchStates['cliente'] ||
    {}) as FetchState<ClienteView>

  const { refetch } = useQueryResponse()
  const [idEmpresa, setIdEmpresa] = useState<ID>(undefined)
  const [empresaCliente, setEmpresaCliente] = useState<ClienteView | undefined>()

  const editEmpresaClienteSchema = Yup.object().shape({
    tipo: Yup.string().required('Tipo de documento é obrigatório'),
    cnpj: Yup.string()
      .nullable()
      .when([], {
        is: () => formik.values.tipo === 'CNPJ',
        then: Yup.string().required('O campo CNPJ obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    cpf: Yup.string()
      .nullable()
      .when([], {
        is: () => formik.values.tipo === 'CPF',
        then: Yup.string().required('O campo CPF obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    caepf: Yup.string()
      .nullable()
      .when([], {
        is: () => formik.values.tipo === 'CAEPF',
        then: Yup.string().required('O campo CAEPF obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    nome: Yup.string().required('Nome é obrigatório'),
    empresa: Yup.string().required('Empresa é obrigatório'),
  })

  const empresaClienteForEdit = {
    ...empresaCliente,
    nome: empresaCliente?.nome,
    cnpj: empresaCliente?.tipoDocumento === 'CNPJ' ? empresaCliente?.documento : '',
    cpf: empresaCliente?.tipoDocumento === 'CPF' ? empresaCliente?.documento : '',
    caepf: empresaCliente?.tipoDocumento === 'CAEPF' ? empresaCliente?.documento : '',
    tipo: cliente?.tipoDocumento || DocumentoType.Cnpj,
    empresa: empresaCliente?.empresa?.nome || '',
    idEmpresa: empresaCliente?.empresa?.id !== undefined ? empresaCliente.empresa?.id : undefined,
  }

  const refresh = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    closeModal()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: empresaClienteForEdit,
    validationSchema: editEmpresaClienteSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          editEmpresaCliente(
            values.id,
            String(values.nome),
            String(values?.cnpj),
            values.tipo,
            String(values.cpf),
            String(values.caepf)
          )
            .then((result) => {
              Swal.fire({
                text: 'Empregador ASO alterada com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => refresh(true))
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              if (errors !== undefined) {
                setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
                setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                setFieldError('caepf', errors['caepf'] !== undefined ? errors.caepf[0] : '')
                setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')
              } else {
                Swal.fire({
                  text: 'Não foi possível alterar o Empregador ASO!',
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
            })
        } else {
          createEmpresaCliente(
            idEmpresa,
            String(values.nome),
            String(values?.cnpj),
            values.tipo,
            String(values.cpf),
            String(values.caepf)
          )
            .then((result) => {
              Swal.fire({
                text: 'Empregador ASO criada com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => refresh(true))
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              if (errors !== undefined) {
                setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
                setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                setFieldError('caepf', errors['caepf'] !== undefined ? errors.caepf[0] : '')
                setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')
              } else {
                Swal.fire({
                  text: 'Não foi possível criar o Empregador ASO!',
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
            })
        }
      } catch (error: any) {
        const errors = error.response.data.data.errors
        setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
        setFieldError('caepf', errors['caepf'] !== undefined ? errors.caepf[0] : '')
        setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')

        console.error(error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useEffect(() => setEmpresaCliente(cliente), [fetchStates])

  return (
    <>
      {formik.isSubmitting || isEmpresaLoading ? (
        <ListLoading />
      ) : (
        <form
          id='kt_modal_add_empresa_form'
          className='form'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column'
            id='kt_modal_add_empresa_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_empresa_header'
            data-kt-scroll-wrappers='#kt_modal_add_empresa_scroll'
          >
            {empresaCliente?.empresa !== undefined ? (
              <>
                <div className='fv-row mb-7'>
                  <label className='fw-bold fs-6 mb-2'>Empresa</label>
                  <span className='menu-icon d-flex align-items-center'>
                    <span>{empresaCliente.empresa?.nomeFantasia}</span>
                  </span>
                </div>
              </>
            ) : (
              <div className='fv-row mb-7'>
                <Label className='required mb-2'>Empresa</Label>
                <CustomSelect
                  placeholder='Selecione uma empresa'
                  onChange={(data) => {
                    setIdEmpresa(data.value)
                    formik.setFieldValue('empresa', data.value)
                  }}
                  fetchOptions={OptionsHelper.getOptionsEmpresas}
                  className={clsx(
                    { 'is-invalid': idEmpresa === undefined },
                    {
                      'is-valid': idEmpresa !== undefined,
                    }
                  )}
                />
                {formik.errors.empresa && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.empresa}</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className='fv-row mb-7'>
              <Label className='required mb-2'>Nome</Label>

              <Input
                placeholder='Digite o nome do empregador'
                {...formik.getFieldProps('nome')}
                isInvalid={!!(formik.touched.nome && formik.errors.nome)}
                isValid={formik.touched.nome && !formik.errors.nome}
                errorMessage={formik.errors.nome}
                disabled={formik.isSubmitting || isEmpresaLoading}
              />
            </div>

            <div className='fv-row mb-7'>
              <div className='d-flex align-items-center mb-3'>
                <Label className='required mb-2 me-2'>Tipo</Label>
                <Radio
                  id='tipos'
                  options={[
                    { id: 1, descricao: DocumentoType.Cpf },
                    { id: 2, descricao: DocumentoType.Cnpj },
                    { id: 3, descricao: DocumentoType.Caepf },
                  ]}
                  onChange={(e) => formik.setFieldValue('tipo', e.target.value)}
                  value={formik.values.tipo}
                />
              </div>
            </div>

            <div className='fv-row mb-7'>
              {formik.values.tipo === DocumentoType.Cpf && (
                <>
                  <Label className='required mb-2'>CPF</Label>
                  <IMaskInput
                    placeholder='Digite o CPF'
                    {...formik.getFieldProps('cpf')}
                    mask='000.000.000-00'
                    type='text'
                    name='cpf'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
                      { 'is-valid': formik.touched.cpf && !formik.errors.cpf }
                    )}
                    disabled={formik.isSubmitting || isEmpresaLoading}
                  />
                  {formik.touched.cpf && formik.errors.cpf && formik.values.tipo === 'CPF' && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.cpf}</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {formik.values.tipo === DocumentoType.Cnpj && (
                <>
                  <Label className='required mb-2'>CNPJ</Label>
                  <IMaskInput
                    placeholder='Digite o CNPJ'
                    {...formik.getFieldProps('cnpj')}
                    mask='00.000.000/0000-00'
                    type='text'
                    name='cnpj'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.cnpj && formik.errors.cnpj },
                      { 'is-valid': formik.touched.cnpj && !formik.errors.cnpj }
                    )}
                    disabled={formik.isSubmitting || isEmpresaLoading}
                  />
                  {formik.touched.cnpj && formik.errors.cnpj && formik.values.tipo === 'CNPJ' && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.cnpj}</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {formik.values.tipo === DocumentoType.Caepf && (
                <>
                  <Label className='required mb-2'>CAEPF</Label>
                  <IMaskInput
                    placeholder='Digite o CAEPF'
                    {...formik.getFieldProps('caepf')}
                    mask='000.000.000/000-00'
                    type='text'
                    name='caepf'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.caepf && formik.errors.caepf },
                      { 'is-valid': formik.touched.caepf && !formik.errors.caepf }
                    )}
                    disabled={formik.isSubmitting || isEmpresaLoading}
                  />
                  {formik.touched.caepf &&
                    formik.errors.caepf &&
                    formik.values.tipo === DocumentoType.Caepf && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.caepf}</span>
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <Button
              type={ButtonType.Reset}
              onClick={refresh}
              className='btn-secondary me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting || isEmpresaLoading}
            >
              Cancelar
            </Button>
            <Button
              type={ButtonType.Submit}
              className='btn-primary'
              data-kt-users-modal-action='submit'
              disabled={isEmpresaLoading || formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>Enviar</span>
              {(formik.isSubmitting || isEmpresaLoading) && <LoadingIndicator />}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}

export { EmpresaClienteEditModalForm }
