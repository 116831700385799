import { Column } from 'react-table'
import { LogAtendimentoView } from '../../core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader, DataColumn } from '../../../../../../components'

export const LogsConsultaIdColumns: ReadonlyArray<Column<LogAtendimentoView>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data'
        className='min-w-75px'
      />
    ),
    id: 'data',
    Cell: ({ ...props }) => <DataColumn data={props.data[props.row.index].data} inline={true} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Responsável'
        className='min-w-75px'
        isAbleToSort={false}
      />
    ),
    id: 'usuario.nome',
    accessor: (row) => (row.usuario ? row.usuario.nome : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ação'
        className='min-w-75px'
        isAbleToSort={false}
      />
    ),
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Descrição'
        className='min-w-75px'
      />
    ),
    id: 'descricao',
    accessor: 'descricao',
  },
]
