import { FC } from 'react'
import { AtendimentoView } from '../../core/_models'
import { FetchState, ListLoading, useModal } from '../../../../../components'

const AtendimentoEditModalForm: FC = () => {
  const { fetchStates, closeModal } = useModal()

  const { data: atendimento, isLoading } = (fetchStates['atendimento'] ||
    {}) as FetchState<AtendimentoView>

  const formatDate = (dateStr: string) => {
    var data = new Date(dateStr)
    let newDate = `${data.getDay}/${data.getMonth}/${data.getFullYear} - ${data.getHours}:${data.getMinutes}`
    return newDate
  }

  return (
    <>
      <div id='kt_modal_add_atendimento'>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Protocolo</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{atendimento?.protocolo}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Estado</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{atendimento?.estado?.descricao}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Data de Início</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{formatDate(String(atendimento?.inicio))}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Data de Término</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{formatDate(String(atendimento?.termino))}</span>
            </div>
          </div>
          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={closeModal}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={isLoading}
            >
              Cancelar
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={isLoading}
            >
              <span className='indicator-label'>Enviar</span>
              {isLoading && (
                <span className='indicator-progress'>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      {isLoading && <ListLoading />}
    </>
  )
}

export { AtendimentoEditModalForm }
