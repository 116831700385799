import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getFormularioById } from '../../../formularios-create/core/_requests'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { deleteFormularioById, toggleStatus } from '../../core/_requests'
import { KTSVG, onDeleteAlert, QUERIES } from '../../../../../../../_metronic/helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { useListView } from '../../core/ListViewProvider'
import { Dropdown, DropdownItem } from '../../../../../../components'

type Props = {
  row: any
}

const FormularioActionsCell: FC<Props> = ({ row }) => {
  const { setItemIdForUpdate } = useListView()
  const navigate = useNavigate()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => MenuComponent.reinitialization(), [])

  const editForm = () => {
    const { id } = row.original
    getFormularioById(id)
      .then((result) => {
        navigate('/apps/formularios/editar' + '?id=' + id)
      })
      .catch((error) => {
        const err = error as AxiosError
        Swal.fire({
          text: 'Erro ao buscar formulário! ' + err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const getVinculos = () => {
    const { id } = row.original
    setItemIdForUpdate(id)
    getFormularioById(id)
      .then((result) => {
        navigate('/apps/formularios/vinculos' + '?id=' + id)
      })
      .catch((error) => {
        const err = error as AxiosError
        Swal.fire({
          text: 'Erro ao buscar vinculo! ' + err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const toggleActive = useMutation(
    () => toggleStatus({ id: row.original.id, ativo: !row.original.ativo }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${QUERIES.FORMULARIOS_LIST}-${query}`])
        Swal.fire({
          text: `Formulário ${row.original.ativo ? 'inativado' : 'ativado'}`,
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      },
      onError(error, variables, context) {
        const err = error as AxiosError
        Swal.fire({
          text: err.response?.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      },
    }
  )

  // TALVEZ ALGUM ERRO NO DELETAR APARECEU
  const deleteForm = useMutation(() => deleteFormularioById(row.original.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.FORMULARIOS_LIST}-${query}`])
      Swal.fire({
        text: `Formulário deletado`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const handleDelete = () => onDeleteAlert({ onAction: deleteForm.mutateAsync })

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={editForm}>Editar</DropdownItem>
      <DropdownItem
        /* data-kt-users-table-filter={row.original.ativa ? 'delete_row' : 'active_row'} */
        onClick={async () => await toggleActive.mutateAsync()}
      >
        {row.original.ativo ? 'Inativar' : 'Ativar'}
      </DropdownItem>
      <DropdownItem onClick={getVinculos}>Vinculos</DropdownItem>
      <DropdownItem onClick={handleDelete}>Excluir</DropdownItem>
    </Dropdown>
  )
}

export { FormularioActionsCell }
