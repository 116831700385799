/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { ID, ModalSize } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap'
import { gerarToken, webhook } from '../../core/_requests'
import { getEmpresaById } from '../../../../empresa/empresas-list/core/_requests'
import { Button as CustomButton, useModal } from '../../../../../../components'
import { EmpresaView } from '../../../../empresa/empresas-list/core/_models'
import { TokenModal } from '../../component/modal/TokenModal'
import { WebHookModal } from '../../component/modal/WebHookModal'

type Props = {
  id: ID
}

const EmpresasActionsCell: FC<Props> = ({ id }) => {
  const { showModal } = useModal()
  const [showTokenModal, setShowTokenModal] = useState(false)
  const [showWebhookModal, setShowWebhookModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState<string>('')
  const [copied, setCopied] = useState<boolean>(false)
  const [empresa, setEmpresa] = useState<EmpresaView>()
  const [webhookUrl, setWebhookUrl] = useState<string>(empresa?.apiWebhook as string)

  const handleGenerateTokenClick = () => {
    showModal({
      title: 'Gerar Token',
      content: <TokenModal />,
      size: ModalSize.Medium,
      fetchers: {
        accessToken: async () => {
          const { accessToken } = await gerarToken(id)
          return accessToken
        },
      },
    })
  }

  const openWebhookModal = () => {
    showModal({
      title: 'Configurar WebHook',
      content: <WebHookModal />,
      size: ModalSize.Medium,
      fetchers: {
        empresa: () => getEmpresaById(id),
      },
    })
  }

  return (
    <>
      <div className='d-flex flex-end'>
        <CustomButton className='btn-primary btn-sm me-2' onClick={handleGenerateTokenClick}>
          Gerar Token
        </CustomButton>
        <CustomButton className='btn-secondary btn-sm' onClick={openWebhookModal}>
          WebHook
        </CustomButton>
      </div>
    </>
  )
}

export { EmpresasActionsCell }
