import { FC, useState } from 'react'
import { ButtonType, IconPaths } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { useAuth } from '../../../../../auth'
import { alteraAtendimento } from '../../../../atendimento/core/_requests'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { AtendimentoMedicoInfo } from '../AtendimentoInfo/AtendimentoInfo'
import { Button, FetchState, ListLoading, useModal } from '../../../../../../components'

const AtendimentoMedicoEditModalForm: FC = () => {
  const { fetchStates, closeModal } = useModal()

  const { data: atendimentoMedico, isLoading } = (fetchStates['atendimentoMedico'] ||
    {}) as FetchState<AtendimentoView>

  const { refetch } = useQueryResponse()
  const currentUser = useAuth().currentUser
  const [copy, setCopy] = useState(false)
  const navigate = useNavigate()
  const API_URL = 'https://one.lauduz.com'
  const url = `${API_URL}/tele-consulta/${atendimentoMedico?.protocolo}`

  const mensagem = `
  https://api.whatsapp.com/send?phone=+55${String(
    atendimentoMedico?.paciente?.pessoa?.telefone
  ).replace(/[-()\s]/g, '')}
  &text=Olá, estou entrando em contato sobre o seu atendimento. 
  Link da chamada: ${url}
`
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    closeModal()
  }

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url)
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 3000)
  }

  function redirectTeleAtendimento() {
    navigate('/apps/atendimentosMedicos/teleAtendimento?atendimento=' + atendimentoMedico?.id)
  }

  function iniciarAtendimento() {
    alteraAtendimento(Number(atendimentoMedico?.id), 2, Number(currentUser?.pessoa?.id), true, null)
      .then((result) => {
        redirectTeleAtendimento()
        closeModal()
      })
      .catch((error) => {
        Swal.fire({
          text: error.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  return (
    <>
      {atendimentoMedico ? (
        <>
          <div className='menu-item px-8'>
            <AtendimentoMedicoInfo atendimentoMedico={atendimentoMedico} />
            <div className='d-flex flex-column mt-4 align-items-center '>
              <div className='d-flex flex-column '>
                {!!atendimentoMedico?.urlSalaVideo ? (
                  <>
                    {!!atendimentoMedico?.paciente?.pessoa?.telefone && (
                      <a href={mensagem} target='_blank' className='btn btn-success mb-2'>
                        Enviar Mensagem no WhatsApp
                      </a>
                    )}
                    <button className='btn btn-primary mb-2' onClick={handleCopyUrl}>
                      URL da Chamada
                    </button>
                    {copy && <p className='text-success'>Link copiado com sucesso</p>}
                  </>
                ) : (
                  <label className='fw-bold fs-6'>Link da chamada não gerado</label>
                )}
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-center mt-4'>
            <Button
              type={ButtonType.Reset}
              className=' btn-secondary me-3'
              onClick={cancel}
              data-kt-users-modal-action='cancel'
            >
              Cancelar
            </Button>
            <Button
              className='btn btn-primary btn-active-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              onClick={iniciarAtendimento}
              icon={IconPaths.Record}
            >
              Iniciar
            </Button>
          </div>
        </>
      ) : (
        <ListLoading />
      )}
    </>
  )
}

export { AtendimentoMedicoEditModalForm }
