import { Column } from 'react-table'
import { ImportacaoDetalhe } from '../../core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader } from '../../../../../../components'

const importacoesDetalhesColumns: ReadonlyArray<Column<ImportacaoDetalhe>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Protocolo de atendimento'
      />
    ),
    id: 'atendimento',
    accessor: 'atendimento',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Prontuário' />
    ),
    id: 'prontuario',
    accessor: (row) => row.prontuario || '-',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Paciente' />
    ),
    id: 'paciente',
    accessor: (row) => row.paciente || '-',
  },
]

export { importacoesDetalhesColumns }
