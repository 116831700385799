import { KTCard } from '../../../../../_metronic/helpers'
import { RiscoClienteListHeader } from './components/header/RiscoClienteListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { RiscoClienteTable } from './table/RiscoClienteTable'

const RiscoClienteList = () => {
  return (
    <>
      <KTCard>
        <RiscoClienteListHeader />
        <RiscoClienteTable />
      </KTCard>
    </>
  )
}

const RiscoClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RiscoClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { RiscoClienteListWrapper }
