import { useState, useEffect } from 'react'
import { OptionsHelper, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useAuth } from '../../../../../auth'
import { CustomSelect, DateInput, Input, IOption, Label } from '../../../../../../components'

const RelatorioNpsListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const { currentUser } = useAuth()

  const [empresa, setEmpresa] = useState<IOption>()
  const [tipoAtendimento, setTipoAtendimento] = useState<IOption>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [protocoloSel, setProtocoloSel] = useState<string>('')
  const [dataSel, setDataSel] = useState<string>('')
  const [dataConsulta, setDataConsulta] = useState<string>('')
  const [estadoSel, setEstadoSel] = useState<IOption>()
  const [especialidadeSel, setEspecialidadeSel] = useState<IOption>()
  const [pacienteSel, setPacienteSel] = useState('')
  const [optionsTiposAtendimento, setOptionsTiposAtendimento] = useState<IOption[]>([])

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const updateFilter = () => {
    const search = [
      empresa ? `paciente.empresa:${empresa.value}` : '',
      tipoAtendimento ? `tipo:${tipoAtendimento.value}` : '',
      dataConsulta ? `inicio>${dataConsulta}T00:00:00` : '',
      dataSel ? `solicitacao>${dataSel}T00:00:00` : '',
      estadoSel ? `estado:${estadoSel.value}` : '',
      especialidadeSel ? `especialidade:${especialidadeSel.value}` : '',
      protocoloSel ? `protocolo~*${protocoloSel}*` : '',
      pacienteSel ? `paciente.pessoa.nome~*${pacienteSel}*` : '',
    ]
      .filter(Boolean)
      .join(',')
    setSearchTerm(search)
  }

  useEffect(() => {
    updateFilter()
  }, [
    empresa,
    tipoAtendimento,
    dataConsulta,
    dataSel,
    estadoSel,
    especialidadeSel,
    pacienteSel,
    protocoloSel,
  ])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: searchTerm,
      })
    }
  }, [searchTerm, debouncedSearchTerm])

  useEffect(() => {
    if (pacienteSel === '') {
      let search = ''
      if (dataSel) search += 'solicitacao>' + dataSel + 'T00:00:00'
      if (estadoSel) search += (search ? ',' : '') + 'estado.id:' + estadoSel.value
      if (protocoloSel) search += (search ? ',' : '') + 'protocolo~*' + protocoloSel + '*'

      setSearchTerm(search)
    }
  }, [pacienteSel])

  const getTiposAtendimento = () =>
    setOptionsTiposAtendimento(OptionsHelper.getOptionsTiposAtendimentoUsuario(currentUser))

  useEffect(() => {
    getTiposAtendimento()
  }, [])

  return (
    <div>
      <div className='row mb-0'>
        <div className='col-lg-4 mb-2'>
          <Label className='mb-1'>Empresa</Label>
          <CustomSelect
            placeholder='Selecione uma empresa'
            onChange={(empresa) => setEmpresa(empresa)}
            fetchOptions={OptionsHelper.getOptionsEmpresas}
          />
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='mb-1'>Estado</Label>
          <CustomSelect
            placeholder='Selecione um estado'
            onChange={(estado) => setEstadoSel(estado)}
            fetchOptions={OptionsHelper.getOptionsEstadosAtendimento}
            value={estadoSel}
          />
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='mb-1'>Protocolo</Label>
          <Input
            placeholder='Digite o protocolo'
            value={protocoloSel}
            onChange={(e) => setProtocoloSel(e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-0'>
        <div className='col-lg-4'>
          <div className='mb-3'>
            <Label className='mb-1'>Paciente</Label>
            <Input
              placeholder='Digite o Pacientes'
              value={pacienteSel}
              onChange={(e) => setPacienteSel(e.target.value)}
            />
          </div>
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='mb-1'>Tipos de atendimento</Label>
          <CustomSelect
            placeholder='Selecione um tipo'
            onChange={(tipoAtendimento) => setTipoAtendimento(tipoAtendimento)}
            options={optionsTiposAtendimento}
            value={tipoAtendimento}
          />
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='mb-1'>Especialidade</Label>
          <CustomSelect
            placeholder='Selecione uma especialidade'
            onChange={(data) => setEspecialidadeSel(data)}
            fetchOptions={OptionsHelper.getOptionsEspecialidades}
            value={especialidadeSel}
          />
        </div>
      </div>
      <div className='row mb-0'>
        <div className='col-lg-4 mb-2'>
          <Label className='mb-1'>Agendamentos a partir de:</Label>
          <DateInput
            placeholder='Digite data inicial'
            value={dataSel}
            onChange={(e) => setDataSel(e.target.value)}
          />
        </div>
        <div className='col-lg-4 mb-2'>
          <Label className='mb-1'>Consultas iniciadas a partir de:</Label>
          <DateInput
            placeholder='Digite data inicial'
            value={dataConsulta}
            onChange={(e) => setDataConsulta(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export { RelatorioNpsListSearchComponent }
