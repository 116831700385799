/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from 'react'
import { Module } from '../memed/domain/Module'
import { AtendimentoView } from '../../atendimento/core/_models'
import { FuncionarioMemed } from '../../funcionarios-management/funcionarios-list/core/_models'
import { adicionaAlergia } from '../../paciente-management/pacientes-list/core/_requests'
import {
  adicionaPrescricao,
  adicionaProcedimento,
  getAtendimentoById,
} from '../../atendimento/core/_requests'
import Swal from 'sweetalert2'
import { Alergia, Medicacao, Prescricao, Procedimento, Retorno } from '../memed/_models'
import moment from 'moment'
import { getMemedFuncionario } from '../../funcionarios-management/funcionarios-list/core/_requests'
import { ID } from '../../../../../_metronic/helpers'
import { useParams } from 'react-router-dom'

function removerCaracteresEspeciais(string: string) {
  return string.replace(/([.-])/g, '')
}
function removerEspacosBrancos(string: string) {
  return string.replace(/\s/g, '')
}

const MemedReceita = () => {
  const [loading, setLoading] = useState(true)
  const [medico, setMedico] = useState<FuncionarioMemed | undefined>()
  const [selectedAtendimento, setSelectedAtendimento] = useState<AtendimentoView | undefined>()
  const alergias: Alergia[] = []
  const procedimentos: Procedimento[] = []
  const medicacoes: Medicacao[] = []
  let prescricao: Prescricao
  const MEMED_URL =
    window.location.hostname.includes('homolog') || window.location.hostname.includes('localhost')
      ? 'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js'
      : 'https://partners.memed.com.br/integration.js'

  const { atendimentoId } = useParams<{ atendimentoId: string }>()

  useEffect(() => {
    getMedicoMemed()
    if (atendimentoId) {
      defineAtendimento()
    }
  }, [])

  useEffect(() => {
    if (medico && selectedAtendimento) {
      initMemedScript()
    }
  }, [medico, selectedAtendimento])

  function getMedicoMemed() {
    getMemedFuncionario(Number(atendimentoId))
      .then((fMemed) => {
        setMedico(fMemed)
      })
      .catch((error: any) => {
        Swal.fire({
          text: 'Não é possível prescrever.' + error,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const defineAtendimento = () => {
    getAtendimentoById(Number(atendimentoId)).then((a) => {
      setSelectedAtendimento(a)
    })
  }

  function trataDadosRetorno(retorno: Retorno) {
    retorno.prescricao.paciente._relationships.allergy.forEach((alergia) => {
      let al: Alergia = { descricao: String(alergia.ingredient_name) }
      alergias.push(al)
    })
    retorno.prescricao.medicamentos.forEach((m) => {
      if (m.tipo === 'exame') {
        let exame: Procedimento = {
          nome: String(m.nome),
          descricao: m.descricao === '' ? String(m.sanitized_posology) : String(m.descricao),
        }
        procedimentos.push(exame)
      } else {
        let medicamento: Medicacao = {
          identificador: String(m.real_id),
          nome: String(m.nome),
          descricao: String(m.descricao),
          titulo: String(m.titulo),
          subTitulo: String(m.subtitulo),
          posologia: String(m.sanitized_posology),
          composicao: String(m.composicao),
          apresentacao: '',
          quantidade: Number(m.quantidade),
          fabricante: String(m.fabricante),
          titularidade: String(m.titularidade),
          receituario: String(m.receituario),
          tipo: String(m.tipo),
          tarja: String(m.tarja),
          unit: String(m.form_units[0].singular),
          cid: String(m.cid),
          via: String(m.via),
          controleEspecial: Boolean(m.controle_especial),
          altoCusto: Boolean(m.alto_custo),
          descontinuado: Boolean(m.descontinuado),
          tratamentoPrevio: Boolean(m.tratamento_previo),
          descricaoTratamento: String(m.descricao_tratamento),
        }
        medicacoes.push(medicamento)
      }
    })
    prescricao = {
      data:
        retorno.prescricao.prescriptionDateOriginal !== undefined
          ? retorno.prescricao.prescriptionDateOriginal
          : '2023-01-01 00:00:00',
      medicamentos: medicacoes,
      identificadoMedico: String(medico?.idExterno),
      identificadorPrescricao: String(retorno.prescricao.id),
    }
  }

  function enviarDados() {
    alergias.forEach((a) => {
      adicionaAlergia(selectedAtendimento?.paciente?.id, a.descricao).catch((erro) => {
        Swal.fire({
          text: erro.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
    })

    const dt = new Date()
    if (prescricao !== undefined) {
      adicionaPrescricao(selectedAtendimento?.id, prescricao)
        .then((data) => {
          procedimentos.forEach((p) => {
            adicionaProcedimento(
              selectedAtendimento?.id,
              moment(dt).format('YYYY-MM-DD'),
              p.nome,
              p.descricao
            ).catch((erro) => {
              Swal.fire({
                text: erro.response?.data.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
          })
        })
        .catch((erro) => {
          Swal.fire({
            text: erro.response?.data.data.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        })
    }
  }

  function initMemedScript() {
    const script = document.createElement('script')
    script.src = String(MEMED_URL)
    script.dataset.token = medico?.token
    script.dataset.container = 'memed_div'
    script.addEventListener('load', prescricaoMemed)
    document.body.appendChild(script)
  }

  function scriptLoaded() {
    setLoading(false)
  }

  function prescricaoMemed() {
    window.MdSinapsePrescricao.event.add('core:moduleInit', async function (module: Module) {
      if (module.name === 'plataforma.prescricao') {
        scriptLoaded()
        await window.MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
          buttonClose: false,
        })
        window.MdHub.event.add('prescricaoImpressa', function (prescriptionData: any) {
          trataDadosRetorno(prescriptionData)
          enviarDados()
        })
        await window.MdHub.command.send('plataforma.prescricao', 'setPaciente', {
          idExterno: selectedAtendimento?.paciente?.id,
          nome: selectedAtendimento?.paciente?.nome,
          telefone:
            selectedAtendimento?.paciente?.pessoa?.telefone !== null
              ? removerEspacosBrancos(
                  removerCaracteresEspeciais(
                    String(selectedAtendimento?.paciente?.pessoa?.telefone)
                  )
                )
              : '99999999999',
          cpf: String(selectedAtendimento?.paciente?.pessoa?.cpf),
        })
        window.MdHub.module.show('plataforma.prescricao')
      }
    })
  }

  return (
    <div id='memed_div' className='h-100'>
      {loading && (
        <div className='d-flex flex-center flex-row-fluid pt-12'>
          {selectedAtendimento !== undefined && (
            <span className='indicator-progress fw-bold' style={{ display: 'block' }}>
              O módulo de prescrição está sendo carregado...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </div>
      )}
    </div>
  )
}

const PrescricaoWrapper = () => <MemedReceita />

export { PrescricaoWrapper }
