import { useState, useEffect } from 'react'
import {
  initialQueryState,
  OptionsHelper,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomSelect, DateInput, Input, IOption, Label } from '../../../../../../components'

interface ISearch {
  nome?: string
}

const LogsUsuarioListSearchComponent = () => {
  const { updateState } = useQueryRequest()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [search, setSearch] = useState<ISearch>()

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const updateFilter = () => {
    const aux: string[] = []

    if (search?.nome) aux.push(`pessoa.nome~*${search.nome}*`)
    setSearchTerm(aux.filter(Boolean).join(','))
  }

  useEffect(() => {
    updateFilter()
  }, [search])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: searchTerm,
        sort: 'pessoa.nome',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [searchTerm, debouncedSearchTerm])

  return (
    <div className='row mb-0'>
      <div className='col-lg-6'>
        <Label className='pb-2'>Usuário</Label>
        <Input
          placeholder='Digite o usuário'
          value={search?.nome}
          onChange={(e) => setSearch((prev) => ({ ...prev, nome: e.target.value }))}
        />
      </div>
    </div>
  )
}

export { LogsUsuarioListSearchComponent }
