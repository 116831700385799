import axios, { AxiosResponse } from 'axios'
import { FuncionarioDtoEspecialidades, FuncionarioDtoEspecialidadesAdd } from './_models'
import { PessoasQueryResponse } from '../../../auth'

const API_URL = process.env.REACT_APP_API_URL

export const getProfisionaisDeSaude = (query: string): Promise<PessoasQueryResponse> => {
  return axios
    .get(`${API_URL}/funcionarios/medicos?${query}`)
    .then((d: AxiosResponse<PessoasQueryResponse>) => d.data)
}

export const getProfissionalVinculo = (pessoaId: number): Promise<FuncionarioDtoEspecialidades> => {
  return axios
    .get(`${API_URL}/funcionarios/especialidades/${pessoaId}`)
    .then((d: AxiosResponse<FuncionarioDtoEspecialidades>) => d.data)
}

export const createVinculoEspecialidade = (
  vinculo: FuncionarioDtoEspecialidadesAdd
): Promise<FuncionarioDtoEspecialidades> => {
  return axios
    .post(`${API_URL}/funcionarios/especialidades`, vinculo)
    .then((d: AxiosResponse<FuncionarioDtoEspecialidades>) => d.data)
}
