import { IconPaths } from '../../../../../../../_metronic/helpers'
import { Button, useModal } from '../../../../../../components'
import { EmpresaEditModalForm } from '../../empresa-edit-modal/EmpresaEditModalForm'

const EmpresasListToolbar = () => {
  const { showModal } = useModal()

  const openAddEmpresa = () => showModal({ title: 'Empresa', content: <EmpresaEditModalForm /> })

  return (
    <Button className='btn btn-primary' onClick={openAddEmpresa} icon={IconPaths.Add}>
      Adicionar
    </Button>
  )
}

export { EmpresasListToolbar }
