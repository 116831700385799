/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import {
  getPacienteById,
  getPacienteLogado,
} from '../paciente-management/pacientes-list/core/_requests'
import {
  agendaAtendimentoProfissional,
  alteraAtendimentoASO,
  alteraEmpresaClienteAtendimento,
  alteraTipoAso,
  getAtendimentoById,
  getAtendimentoPacienteById,
  getTiposASO,
  getTiposAtendimentosByPaciente,
  iniciarAtendimentoPaciente,
} from './core/_requests'
import { AtendimentoView, TipoAtendimentoEmpresa } from './core/_models'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ID } from '../../../../_metronic/helpers'
import { getEmpresaById } from '../empresa/empresas-list/core/_requests'
import { EmpresaView, TipoAtendimentoMap } from '../empresa/empresas-list/core/_models'
import Select from 'react-select'
import { AxiosError } from 'axios'
import { useQueryResponse } from './core/QueryResponseProvider'
import { getEspecialidadesSemQuery } from '../especialidades/especialidades-list/core/_requests'
import { Label, ToggleInput, useModal } from '../../../components'
import { getEmpresaClienteAso } from '../empresa/empresasCliente-list/core/_request'
import { getCoordernadores } from '../empresa/empresasClienteCoordenador-list/core/_request'
import { getCargosCliente } from '../empresa/empresasClienteCargo-list/core/_request'
import { getSetoresCliente } from '../empresa/empresasClienteSetor-list/core/_request'
import { EspecialidadeView } from '../especialidades/especialidades-list/core/_models'
import { ExamesModal } from '../atendimento-ASO/components/modal'

type Props = {
  idPacienteParam?: ID
}

type tiposEspecialidadeOptions = {
  value: undefined
  label: string
}
const AtendimentoFindEmpresa: FC<Props> = ({ idPacienteParam }) => {
  const { closeModal } = useModal()
  const { refetch } = useQueryResponse()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [emAtendimento, setEmAtendimento] = useState(false)
  const [atendimento, setAtendimento] = useState<AtendimentoView>()
  const [idPacienteEmpresa, setIdPacienteEmpresa] = useState('')
  const [nomePaciente, setNomePaciente] = useState('')
  const [isAso, setIsAso] = useState<boolean>(false)
  const [tipoAtendimento, setTipoAtendimento] = useState<TipoAtendimentoEmpresa>()
  const [isPacienteAso, setIsPacienteAso] = useState<boolean>(false)
  const [dataSolicitacao, setDataSolicitacao] = useState('')
  const [empresaPaciente, setEmpresaPaciente] = useState<EmpresaView>()
  const [optionsCoordenadores, setOptionsCoordenadores] = useState<any>([])
  const navigate = useNavigate()
  const [optionsTipos, setOptionsTipos] = useState<any>([])
  const INITIAL_DATA = {
    value: undefined,
    label: 'Selecione um valor',
  }
  const [selectTipoAtendimento, setSelectTipoAtendimento] = useState(INITIAL_DATA)
  const [selectData, setselectData] = useState(INITIAL_DATA)
  const [selectDataCargo, setselectDataCargo] = useState(INITIAL_DATA)
  const [selectDataSetor, setselectDataSetor] = useState(INITIAL_DATA)
  const [idempresaCliente, setIdEmpresaCliente] = useState<ID | undefined>(undefined)
  const [optionsCliente, setOptionsCliente] = useState<any>([])
  const [optionsCargos, setOptionsCargos] = useState<any>([])
  const [optionsSetores, setOptionsSetores] = useState<any>([])
  const [tipoSel, setTipoSel] = useState<String | undefined>(undefined)
  const [optionsTipo, setOptionsTipo] = useState<any>([])
  const [coordenadorPcmso, setCoordenadorPcmso] = useState(INITIAL_DATA)
  const [showProcedimento, setShowProcedimento] = useState(false)
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  const [especialidade, setEspecialidade] = useState<ID>()
  const [selectEspecialidade, setSelectEspecialidade] = useState()
  const [retorno, setRetorno] = useState<boolean>(false)
  const [optionsEspecialidades, setOptionsEspecialidades] = useState<tiposEspecialidadeOptions[]>(
    []
  )

  var options: any = []

  function getEmpresa(id: ID) {
    getEmpresaById(id).then((empresa) => {
      setEmpresaPaciente(empresa)
      getOptionsTipos(empresa?.tiposAtendimentoMap)
    })
  }
  function getOptionsTipos(tipos: TipoAtendimentoMap[] | undefined) {
    const results: any = []
    if (tipos !== undefined) {
      tipos.forEach((value) => {
        results.push({
          label: value.descricao,
          value: value.name,
        })
      })
      setOptionsTipos([...results])
    }
  }
  function getEmpresasPacientelogado() {
    if (idPacienteParam !== undefined) {
      getPacienteById(idPacienteParam).then((result) => {
        getEmpresa(result?.empresa?.id)
        getOptionsEmpresas(result?.empresa?.id)
        setIdPacienteEmpresa(String(result?.id))
        getOptionsTiposAso()
        setNomePaciente(String(result?.pessoa?.nome))
        let possuiAso = result?.dadosAso !== null && result?.dadosAso !== undefined ? true : false
        let possuiRg =
          result?.pessoa?.rg !== null && result?.pessoa?.rg !== undefined ? true : false
        let possuiCtps =
          result?.pessoa?.ctps !== null && result?.pessoa?.ctps !== undefined ? true : false
        AsoEmpresa(String(result?.empresa?.id))
        setIsPacienteAso(possuiAso && possuiRg && possuiCtps)
      })
    } else {
      getPacienteLogado()
        .then((pacienteEmpresas) => {
          if (pacienteEmpresas.length === 1) {
            // getEmpresa(pacienteEmpresas[0].empresa?.id)
            setIdPacienteEmpresa(pacienteEmpresas[0].id)
            setNomePaciente(String(pacienteEmpresas[0].pessoa?.nome))
            let possuiAso =
              pacienteEmpresas[0].dadosAso !== null && pacienteEmpresas[0] !== undefined
                ? true
                : false
            let possuiRg =
              pacienteEmpresas[0].pessoa?.rg !== null &&
              pacienteEmpresas[0].pessoa?.rg !== undefined
                ? true
                : false
            let possuiCtps =
              pacienteEmpresas[0].pessoa?.ctps !== null &&
              pacienteEmpresas[0].pessoa?.ctps !== undefined
                ? true
                : false
            AsoPacienteEmpresa(pacienteEmpresas[0].id)
            setIsPacienteAso(possuiAso && possuiRg && possuiCtps)
          } else {
            // var empresas = pacienteEmpresas.map(mapResponseToValuesAndLabels)
            for (let i = 0; i < pacienteEmpresas.length; i++) {
              let nomeEmpresa = String(pacienteEmpresas[i].empresa?.nomeFantasia)
              var id = pacienteEmpresas[i].id
              options.push({ value: id, label: nomeEmpresa })
            }
          }
        })
        .catch((error: any) => {
          console.error(error)
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        })
    }
  }

  function AsoPacienteEmpresa(id: String) {
    if (id !== '') {
      getTiposAtendimentosByPaciente(Number(id))
        .then((tipos) => {
          tipos?.map((t) => {
            if (t.name === 'ASO') {
              setTipoAtendimento(t)
            }
          })
        })
        .catch((erro) => {
          console.error(erro)
        })
    }
  }
  function AsoEmpresa(id: String) {
    if (id !== '') {
      getEmpresaById(Number(id))
        .then((empresa) => {
          empresa?.tiposAtendimentoMap?.map((t) => {
            if (t.name === 'ASO') {
              setTipoAtendimento(t)
            }
          })
        })
        .catch((erro) => {
          console.error(erro)
        })
    }
  }
  function getOptionsEmpresas(idEmpresa: ID) {
    getEmpresaClienteAso(idEmpresa, 'page=0&size=100')
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.data?.forEach((value) => {
            results.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsCliente([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function getOptionsCoordenadores() {
    getCoordernadores(idempresaCliente)
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          //@ts-ignore
          data?.forEach((value) => {
            results.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsCoordenadores([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (idempresaCliente) {
      getOptionsCoordenadores()
    }
  }, [idempresaCliente])

  function getOptionsCargos(idCliente: ID) {
    getCargosCliente(empresaPaciente?.id, 'page=0&size=100&search=cliente:' + idCliente)
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.data?.forEach((value) => {
            results.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsCargos([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  function getOptionsSetores(idCliente: ID) {
    getSetoresCliente(empresaPaciente?.id, 'page=0&size=100&search=cliente:' + idCliente)
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.data?.forEach((value) => {
            results.push({
              label: value.nome,
              value: value.id,
            })
          })
          setOptionsSetores([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  function getOptionsTiposAso() {
    getTiposASO()
      .then((data) => {
        const results: any = []
        if (data !== undefined) {
          data.forEach((value) => {
            // if (atendimento?.tipoAso === value.name) {
            //   setTipo(value)
            // }
            results.push({
              label: value.descricao,
              value: value.name,
            })
          })
          setOptionsTipo([...results])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const handleChangeTipo = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setTipoSel(e.value)
    // tipoSel !== '0' ? setIsValid(true) : setIsValid(false)
  }
  const handleChangeCliente = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setselectData(e)
    setIdEmpresaCliente(e.value)
    setselectDataCargo(INITIAL_DATA)
    setselectDataSetor(INITIAL_DATA)
    getOptionsCargos(e.value)
    getOptionsSetores(e.value)
  }

  const handleChangeCoordenador = (e: any) => {
    setCoordenadorPcmso(e)
  }
  const handleChangeSetor = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setselectDataSetor(e)
  }
  const handleChangeCargo = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setselectDataCargo(e)
  }
  const validateAtendimento = () => {
    return !dataSolicitacao && !tipoAtendimento
      ? false
      : true &&
          (isAso
            ? !!selectData.value &&
              selectDataCargo.value &&
              !!selectDataSetor.value &&
              !!coordenadorPcmso.value &&
              !!idempresaCliente
            : true)
  }
  const validadeAtendimentoAsoCliente = () => {
    if (
      isAso &&
      selectData.value &&
      selectDataCargo.value &&
      selectDataSetor.value &&
      coordenadorPcmso.value &&
      !!idempresaCliente
    ) {
      return true
    }
    return false
  }
  const defineIntervalWait = (idAtendimento: ID) => {
    let interval = setInterval(async () => {
      if (atendimento === undefined) {
        clearInterval(interval)
      }
      getAtendimentoById(idAtendimento)
        .then((atendimentoApi) => {
          if (atendimentoApi?.estado?.id === 2) {
            setEmAtendimento(true)
            clearInterval(interval)
            navigate('/apps/atendimentosMedicos/teleAtendimento?atendimento=' + atendimentoApi.id)
          }
          clearInterval(interval)
        })
        .catch((error: any) => {
          clearInterval(interval)
          console.error(error)
        })
    }, 5000)
  }
  const alteraAtendimentoCliente = (atendimentoIniciado: AtendimentoView) => {
    alteraEmpresaClienteAtendimento(
      atendimentoIniciado?.id,
      idempresaCliente,
      coordenadorPcmso.value
    )
      .then((empresaCliente) => {
        let matricula =
          atendimentoIniciado?.dadosAso?.matricula !== undefined
            ? atendimentoIniciado?.dadosAso?.matricula
            : ''
        alteraAtendimentoASO(
          atendimentoIniciado?.id,
          matricula,
          selectDataCargo.label,
          selectDataSetor.label,
          selectDataCargo.value,
          selectDataSetor.value,
          idempresaCliente,
          coordenadorPcmso.value
        )
          .then((at) => {
            alteraTipoAso(atendimentoIniciado?.id, String(tipoSel))
              .then((result) => {
                defineAtendimento(atendimentoIniciado?.id)
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            const err = error as AxiosError
            Swal.fire({
              text: 'Erro ao ao víncular! ' + err.response?.data.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
      })
      .catch((error) => {
        const err = error as AxiosError
        Swal.fire({
          text: 'Erro ao ao víncular! ' + err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }
  const defineAtendimento = (id?: ID) => {
    getAtendimentoById(id || atendimento?.id).then((a) => {
      setAtendimento(a)
      // verificaEstadoAso(a?.id)
    })
  }

  const handleEspecialidades = async () => {
    try {
      const data = await getEspecialidadesSemQuery()
      return data?.data as unknown as EspecialidadeView[]
    } catch (error) {
      console.error('Erro ao obter os tipos de atendimentos:', error)
      return []
    }
  }

  const filterEspecialidadesByTipo = (
    tipo: string | null,
    fetchedOptions: EspecialidadeView[]
  ): tiposEspecialidadeOptions[] => {
    return fetchedOptions
      .filter((especialidade) =>
        especialidade?.tiposAtendimentoMap?.some((tipoMap) => tipoMap.name === tipo)
      )
      .map((especialidade) => ({
        value: especialidade.id as undefined,
        label: especialidade.nome,
      }))
  }

  useEffect(() => {
    const fetchOptions = async () => {
      const fetchedOptions = await handleEspecialidades()
      setOptionsEspecialidades(
        filterEspecialidadesByTipo(
          selectTipoAtendimento?.value as unknown as string,
          fetchedOptions
        )
      )
    }

    fetchOptions()
  }, [selectTipoAtendimento])

  useEffect(() => {
    getEmpresasPacientelogado()
  }, [idPacienteParam])

  const handleIniciarAtendimentoClick = () => {
    setLoading(true)
    setHasErrors(undefined)
    let isValid = validateAtendimento()
    //verificar se a empresa do Paciente é ASO
    // let isApto = !isAso ? true : isAso && isPacienteAso ? true : false
    if (isValid) {
      if (idPacienteParam !== undefined) {
        agendaAtendimentoProfissional(
          idPacienteEmpresa,
          selectTipoAtendimento?.value as unknown as string,
          retorno,
          dataSolicitacao,
          isAso ? coordenadorPcmso.value : null,
          isAso ? idempresaCliente : null,
          especialidade
        )
          .then((pacienteEmpresas) => {
            setAtendimento(pacienteEmpresas.data)
            if (validadeAtendimentoAsoCliente()) {
              alteraAtendimentoCliente(pacienteEmpresas.data)
              //@ts-ignore
              defineAtendimento(pacienteEmpresas.data.id)
              setLoading(false)
            }
            //@ts-ignore
            defineAtendimento(pacienteEmpresas.data.id)
            Swal.fire({
              text: 'Atendimento criado com sucesso!',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
            setDisableSubmitButton(true)
            setShowProcedimento(true)

            setLoading(false)
            refetch()
          })
          .catch((error: any) => {
            console.error(error)
            Swal.fire({
              text: 'Não foi possível verificar o atendimento!',
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
      } else {
        iniciarAtendimentoPaciente(
          idPacienteEmpresa,
          selectTipoAtendimento?.value as unknown as string,
          'FUNCAO'
        )
          .then((pacienteEmpresas) => {
            setAtendimento(pacienteEmpresas.data)
            let interval = setInterval(async () => {
              if (atendimento !== undefined && atendimento.id === -1) {
                clearInterval(interval)
              }
              await getAtendimentoPacienteById(pacienteEmpresas.data.id)
                .then((atendimentoApi) => {
                  if (atendimentoApi?.estado?.id === 2) {
                    // setEmAtendimento(true)
                    // clearInterval(interval)
                    window.location.href =
                      '/apps/atendimentos/teleAtendimento?atendimentoPaciente=' + atendimentoApi.id
                  }

                  setLoading(false)
                  setDisableSubmitButton(true)
                  refetch()
                })
                .catch((error: any) => {
                  clearInterval(interval)
                  console.error(error)
                })
            }, 5000)
          })
          .catch((error: any) => {
            console.error(error)
            Swal.fire({
              text: 'Não foi possível verificar o atendimento!',
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
      }
    } else {
      setLoading(false)
      Swal.fire({
        text: 'Informe os dados necessários para iniciar o atendimento',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }
  return (
    <div
      className='d-flex flex-column'
      id='kt_modal_add_paciente_scroll'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-max-height='auto'
      data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
      data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
      // data-kt-scroll-offset='300px'
    >
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>Erro ao enviar o arquivo</div>
        </div>
      )}
      {hasErrors === false && (
        <div id='msgUser' className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>Os dados foram alterados com sucesso.</div>
        </div>
      )}
      {/* <div className='d-flex flex-center flex-row-fluid'>
        <label>{loading ? "Em instantes o atendimento será iniciado" : ""}</label>
      </div> */}
      <div className='fv-row mb-7'>
        <label className='required fw-bold fs-6 mb-2'>Tipo Atendimento</label>
        <Select
          placeholder='Selecione um tipo'
          onChange={(data: any) => {
            setSelectTipoAtendimento(data)
            data.value === 'ASO' ? setIsAso(true) : setIsAso(false)
          }}
          isDisabled={showProcedimento}
          options={optionsTipos}
          className='form-control form-control-solid'
          // defaultValue={{ label: "Selecione um valor", value: "0" }}
          value={selectTipoAtendimento}
        />
      </div>
      {selectTipoAtendimento.value !== 'ASO' &&
        selectTipoAtendimento.value !== undefined &&
        selectTipoAtendimento.value !== 'SC' && (
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Especialidade</label>
            <Select
              placeholder='Selecione uma especialidade'
              onChange={(data: any) => {
                setSelectEspecialidade(data)
                setEspecialidade(data.value)
              }}
              isDisabled={showProcedimento}
              options={optionsEspecialidades}
              className='form-control form-control-solid'
              // defaultValue={{ label: "Selecione um valor", value: "0" }}
              value={selectEspecialidade}
            />
          </div>
        )}

      <div className='fv-row mb-7'>
        <label className='fw-bold fs-6 mb-2 my-3'>
          <span className='required'>Data Atendimento</span>
        </label>

        <input
          type='datetime-local'
          max={new Date().toISOString().split('T')[0]}
          className='form-control form-control-lg textbox-n form-control-solid'
          placeholder='Data atendimento'
          disabled={showProcedimento}
          onChange={(e) => setDataSolicitacao((String(e.target.value) + ':00').replace('T', ' '))}
          defaultValue={new Date().toISOString()}
        />
      </div>
      <div className='fv-row mb-7'>
        <div className='d-flex align-items-center'>
          <Label className='fw-bold fs-6 my-3 me-2'>Consulta de retorno?</Label>
          <ToggleInput checked={retorno} onChange={(checked) => setRetorno(checked)} />
        </div>
      </div>
      {isAso && (
        <>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 required'>Tipo Aso</label>
          </div>
          <div className='fv-row mb-1'>
            <Select
              placeholder='Tipo Aso'
              isDisabled={showProcedimento}
              onChange={handleChangeTipo}
              options={optionsTipo}
              className='form-control form-control-solid'
              defaultValue={selectData}
              required={selectTipoAtendimento.value === 'O'}
            />
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2 required'>Empregador ASO</label>
            <Select
              isDisabled={showProcedimento}
              placeholder='Selecione uma empresa'
              onChange={handleChangeCliente}
              options={optionsCliente}
              className='form-control form-control-solid'
              // defaultValue={{ label: "Selecione um valor", value: "0" }}
              value={selectData}
              required={selectTipoAtendimento.value === 'O'}
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2 required'>Coordenador pcmso</label>
            <Select
              isDisabled={showProcedimento}
              placeholder='Selecione um coordenador'
              onChange={handleChangeCoordenador}
              options={optionsCoordenadores}
              className='form-control form-control-solid'
              // defaultValue={{ label: "Selecione um valor", value: "0" }}
              value={coordenadorPcmso}
              required={selectTipoAtendimento.value === 'O'}
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2 required'>Setor</label>
            <Select
              isDisabled={showProcedimento}
              placeholder='Selecione um setor'
              onChange={handleChangeSetor}
              options={optionsSetores}
              className='form-control form-control-solid'
              // defaultValue={{ label: "Selecione um valor", value: "0" }}
              value={selectDataSetor}
              required={selectTipoAtendimento.value === 'O'}
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2 required'>Cargo</label>

            <Select
              isDisabled={showProcedimento}
              placeholder='Selecione um cargo'
              onChange={handleChangeCargo}
              options={optionsCargos}
              className='form-control form-control-solid'
              // defaultValue={{ label: "Selecione um valor", value: "0" }}
              value={selectDataCargo}
              required={selectTipoAtendimento.value === 'O'}
            />
          </div>
        </>
      )}

      {/* {!loading && (
        <div className='d-flex flex-center flex-row-fluid pt-12'>
          <label className='required fw-bold fs-6 mb-2'>Empresa</label>
          <Select
            className="basic-single"
            placeholder='Selecione empresa'
            onChange={handleChange}
            options={options}
          />
        </div>
      )} */}
      {loading ? (
        <div className='fv-row mb-6'>
          {atendimento === undefined && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Por favor aguarde o seu protocolo de atendimento
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </div>
      ) : (
        <>
          {atendimento !== undefined && (
            <>
              <span className='indicator-progress fw-bold' style={{ display: 'block' }}>
                Protocolo de atendimento: {atendimento?.protocolo} <br />
                Estado: {atendimento?.estado?.descricao}
              </span>
              <span className='fw-bold text-success' style={{ display: 'block' }}>
                Atendimento Solicitado! Acesse a lista de atendimentos para acompanhá-lo.
              </span>
            </>
          )}
        </>
      )}

      {showProcedimento && (
        <ExamesModal atendimento={atendimento} defineAtendimento={defineAtendimento} />
      )}

      {!disableSubmitButton && (
        <div className='d-flex flex-center flex-row-fluid pt-12'>
          <button
            type='reset'
            className='btn btn-light me-3'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            Cancelar
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            hidden={loading || !validateAtendimento()}
            onClick={handleIniciarAtendimentoClick}
          >
            {!loading && 'Solicitar Atendimento'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export { AtendimentoFindEmpresa }
