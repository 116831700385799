import { Column } from 'react-table'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader } from '../../../../../../components'

const personalAtendimentosColumns: ReadonlyArray<Column<AtendimentoView>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Protocolo'
        className='min-w-75px'
      />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Paciente'
        className='min-w-75px'
      />
    ),
    id: 'paciente',
    accessor: (row) => (row.paciente ? row.paciente.nome : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Tipo'
        className='min-w-75px'
      />
    ),
    id: 'tipo',
    accessor: 'tipo',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Especialidade'
        className='min-w-75px'
      />
    ),
    id: 'especialidade',
    accessor: (row) => (row.especialidade ? row.especialidade.nome : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Status'
        className='min-w-75px'
      />
    ),
    id: 'estado.nome',
    accessor: (row) => row.estado?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Profissional de Saúde'
        className='min-w-75px'
      />
    ),
    id: 'responsavel.nome',
    accessor: (row) => (row.responsavel ? row.responsavel.nome : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Empresa'
        className='min-w-75px'
      />
    ),
    id: 'empresa.nomeFantasia',
    accessor: (row) => (row.empresa ? row.empresa.nomeFantasia : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='CNPJ'
        className='min-w-75px'
      />
    ),
    id: 'empresa.cnpj',
    accessor: (row) => (row.empresa ? row.empresa.cnpj : '-'),
  },

  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data'
        className='min-w-75px'
      />
    ),
    id: 'solicitacaoFormatada',
    accessor: 'solicitacaoFormatada',
  },
]

export { personalAtendimentosColumns }
