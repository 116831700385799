import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface ITextEditor {
  value: string
  onChange: (content: string) => void
  disabled?: boolean
  height?: number
}

export const TextEditor: React.FC<ITextEditor> = ({ onChange, value, disabled, height = 300 }) => {
  const [editorContent, setEditorContent] = useState(value || '')

  useEffect(() => {
    setEditorContent(value)
  }, [value])

  const handleEditorChange = (content: string) => {
    const sanitizedValue = content === '<p><br></p>' ? '' : content

    setEditorContent(sanitizedValue)
    onChange(sanitizedValue)
  }

  return (
    <div
      className='d-flex flex-column flex-grow-1 position-relative'
      style={{ height: `${height}px` }}
    >
      <ReactQuill
        theme='snow'
        value={editorContent}
        onChange={handleEditorChange}
        placeholder='Digite sua anotação aqui...'
        className='h-100'
        style={{
          paddingBottom: '50px',
        }}
        readOnly={disabled}
      />
    </div>
  )
}

TextEditor.defaultProps = {
  disabled: false,
}
