import { Column } from 'react-table'
import { AtendimentoPacienteList } from '../../../core/_models'
import { PresencaColumn } from './PresencaColumn'
import { useQueryRequest } from '../../../core/QueryRequestProvider'
import { useListView } from '../../../core/ListViewProvider'
import { AtendimentoActionsCell } from './AtendimentoActionsCell'
import { SelectionHeader, SelectionCell, CustomHeader } from '../../../../../../components'

const AtendimentoColumns: ReadonlyArray<Column<AtendimentoPacienteList>> = [
  {
    Header: (props) => <SelectionHeader useListView={useListView} tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell useListView={useListView} id={props.row.original.id} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Protocolo'
        className='min-w-40px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Data início'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'inicioFormatado',
    accessor: 'inicioFormatado',
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Estado'
        className='min-w-40px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'estado.descricao',
    accessor: (row) => row.estado?.descricao,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Médico'
        className='min-w-40px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'responsavel.nome',
    accessor: (row) => row.responsavel?.nome,
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Presença'
        className='min-w-40px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'presenca',
    Cell: ({ ...props }) => <PresencaColumn atendimento={props.row.original} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Ações'
        className='min-w-40px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <AtendimentoActionsCell atendimento={props.row.original} />,
  },
]

export { AtendimentoColumns }
