import { useSearchParams } from 'react-router-dom'
import { KTCard } from '../../../../../_metronic/helpers'
import { ListViewProvider } from '../formularios-list/core/ListViewProvider'
import { QueryRequestProvider } from '../formularios-list/core/QueryRequestProvider'
import { QueryResponseProvider } from '../formularios-list/core/QueryResponseProvider'
import { FormularioEditWrapper } from './component/formulario-edit/FormularioEditWrapper'
import { useEffect, useState } from 'react'
import { getFormularioById } from './core/_requests'
import { useListView } from './core/ListViewProvider'
import { FormularioView } from './core/_models'
import { ListLoading } from '../../../../components'

const FormularioCreate = () => {
  const { setItemIdForUpdate } = useListView()
  const [searchParams] = useSearchParams()
  const id = Number(searchParams.get('id'))
  const [formulario, setFormulario] = useState<FormularioView>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const form = await getFormularioById(id)

        setFormulario(form)
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching formulario:', error)
        setItemIdForUpdate(undefined)
        setFormulario(undefined)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <KTCard>
      {isLoading ? (
        <ListLoading />
      ) : (
        <>
          <FormularioEditWrapper formulario={formulario} isLoading={isLoading} />
        </>
      )}
    </KTCard>
  )
}

const FormularioCreateWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <FormularioCreate />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { FormularioCreateWrapper }
