import { Column } from 'react-table'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { AtendimentoMedicoActionsCell } from './AtendimentoMedicoActionsCell'
import { PresencaColumn } from '../../../../atendimento/atendimento-list/table/columns/PresencaColumn'
import { OnlineColumn } from '../../../../atendimento/atendimento-list/table/columns/OnlineColumn'
import { CustomHeader, DataColumn, Dot } from '../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const atendimentosMedicosColumns: ReadonlyArray<Column<AtendimentoView>> = [
  {
    Header: () => <span />,
    id: 'expand',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Protocolo' useQueryRequest={useQueryRequest} />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Nome Paciente' useQueryRequest={useQueryRequest} />
    ),
    id: 'paciente.pessoa.nome',
    accessor: (row) => row.paciente?.pessoa?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Sinais' useQueryRequest={useQueryRequest} />
    ),
    id: 'sinais',
    Cell: ({ ...props }) => (
      <Dot
        cor={
          !props.data[props.row.index].dispositivos ||
          props.data[props.row.index].dispositivos?.length === 0
            ? 'bg-secondary'
            : 'bg-success'
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Especialidade' useQueryRequest={useQueryRequest} />
    ),
    id: 'especialidade.nome',
    accessor: (row) => row.especialidade?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data' useQueryRequest={useQueryRequest} />
    ),
    id: 'inicio',
    Cell: ({ ...props }) => (
      <DataColumn
        data={
          props.data[props.row.index].inicio !== null
            ? props.data[props.row.index].inicio
            : props.data[props.row.index].solicitacao
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Presença' useQueryRequest={useQueryRequest} />
    ),
    id: 'presenca',
    Cell: ({ ...props }) => <PresencaColumn atendimento={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Chamada Online' useQueryRequest={useQueryRequest} />
    ),
    id: 'online',
    Cell: ({ ...props }) => <OnlineColumn atendimento={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Ações'
        /* className='text-end' */
        useQueryRequest={useQueryRequest}
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <AtendimentoMedicoActionsCell atendimento={props.data[props.row.index]} />
    ),
  },
]

export { atendimentosMedicosColumns }
