import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { IconPaths, IconSize, ID, InputType } from '../../../../../../../_metronic/helpers'
import {
  createCampo,
  createOpcao,
  deleteOpcoes,
  updateCampo,
  updateOpcao,
} from '../../core/_requests'
import Select from 'react-select'
import {
  Button,
  CustomSelect,
  Input,
  Label,
  TextEditor,
  ToggleInput,
  useModal,
} from '../../../../../../components'
import { validationSchema } from './validation/ValidationSchema'
import { FormularioCampoView, FormularioOpcaoList } from '../../core/_models'

interface FieldConfigModalProps {
  field: FormularioCampoView
  formId: ID
  onUpdate: (config: FormularioCampoView) => void
}

export const FieldConfigModal: React.FC<FieldConfigModalProps> = ({ field, onUpdate, formId }) => {
  const { closeModal } = useModal()

  const [nextOrdem, setNextOrdem] = useState<number>(0)
  const [deletedOptions, setDeletedOptions] = useState<number[]>([])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      rotulo: field.rotulo,
      instrucoes: field.instrucoes || '',
      respostaPadrao: field.respostaPadrao || '',
      tipo: field.tipo,
      obrigatorio: field.obrigatorio || false,
      multiplo: field.multiplo || false,
      opcoes: field.opcoes || [],
      ordem: field.ordem,
    },
    onSubmit: async (values) => {
      const campo = {
        ordem: field.ordem,
        rotulo: values.rotulo,
        respostaPadrao: values.respostaPadrao,
        instrucoes: values.instrucoes,
        tipo: values.tipo,
        obrigatorio: values.obrigatorio,
        multiplo: values.multiplo,
        opcoes: [],
      }
      let id
      if (formId) {
        if (field.id) {
          updateCampo(campo, field.id)
          values.opcoes.forEach((option) =>
            option.id
              ? updateOpcao(option, field.id)
              : createOpcao({ descricao: option.descricao, ordem: option.ordem }, field.id)
          )
          if (deletedOptions.length > 0) deleteOpcoes(deletedOptions, field.id)
        } else {
          const createdField = await createCampo(
            { ...campo, opcoes: values.opcoes.map((option) => option.descricao) },
            formId
          )
          id = createdField.id
        }
      }
      onUpdate({ ...field, ...values, id: field.id ? field.id : id })

      closeModal()
    },
  })

  useEffect(() => {
    const maxOrdem = field.opcoes?.reduce((max, option) => Math.max(max, option.ordem || 0), 0)
    setNextOrdem(maxOrdem + 1)
  }, [])

  const addOption = () => {
    formik.setFieldValue('opcoes', [...formik.values.opcoes, { descricao: '', ordem: nextOrdem }])
    setNextOrdem((prevOrdem) => prevOrdem + 1)
  }

  const updateOptions = (value: string, option: FormularioOpcaoList) => {
    const updatedOptions = formik.values.opcoes?.map((op) =>
      op.ordem === option.ordem ? { ...op, descricao: value } : op
    )
    formik.setFieldValue('opcoes', updatedOptions)
  }

  const removeOption = (option: FormularioOpcaoList) => {
    setDeletedOptions((prev) => (option.id ? [...prev, option.id] : [...prev]))
    const updatedOptions = formik.values.opcoes?.filter((op) => op.ordem !== option.ordem)
    formik.setFieldValue('opcoes', updatedOptions)
  }

  const renderOptionsDefault = () => {
    const data = formik.values.opcoes?.map((option) => ({
      value: option.descricao,
      label: option.descricao,
    }))
    return (
      <div className='fv-row mb-7'>
        <Label className='pb-2 text-gray-700 fw-bold'>Reposta Padrão</Label>
        <CustomSelect
          options={data}
          placeholder='Selecionar Reposta Padrão'
          /*  isMulti={formik.values.multiplo} */
          isRequired={formik.values.obrigatorio}
          onChange={(option) => formik.setFieldValue('respostaPadrao', option ? option.value : '')}
          value={
            formik.values.respostaPadrao
              ? { label: formik.values.respostaPadrao, value: formik.values.respostaPadrao }
              : undefined
          }
        />
      </div>
    )
  }

  return (
    <>
      <div className='fv-row mb-7'>
        <Label className='required form-label text-gray-700'>Rótulo</Label>
        <Input
          {...formik.getFieldProps('rotulo')}
          className={clsx(
            { 'is-invalid': formik.touched.rotulo && formik.errors.rotulo },
            {
              'is-valid': formik.touched.rotulo && !formik.errors.rotulo,
            }
          )}
          required={true}
        />
        {formik.touched.rotulo && formik.errors.rotulo && (
          <div className='text-danger mt-2'>{formik.errors.rotulo}</div>
        )}
      </div>

      <div className='fv-row mb-7'>
        <Label className='form-label text-gray-700'>Instruções</Label>
        <Input {...formik.getFieldProps('instrucoes')} placeholder='Preencha as instruções' />
      </div>

      {formik.values.tipo !== InputType.Radio && formik.values.tipo !== InputType.Select && (
        <div className='fv-row mb-7'>
          <Label className='form-label text-gray-700'>Resposta Padrão</Label>
          {formik.values.tipo === InputType.TextEditor ? (
            <TextEditor
              onChange={(value) => formik.setFieldValue('respostaPadrao', String(value))}
              value={formik.values.respostaPadrao}
            />
          ) : (
            <Input
              type={formik.values.tipo === InputType.Number ? 'number' : 'text'}
              className='form-control'
              onChange={(value) => formik.setFieldValue('respostaPadrao', String(value))}
              value={formik.values.respostaPadrao}
            />
          )}
        </div>
      )}

      <div className='fv-row mb-7'>
        <div className='d-flex justify-content-between'>
          <Label className='form-check-label text-gray-700 ms-0 me-2'>Obrigatório</Label>
          <ToggleInput
            checked={formik.values.obrigatorio}
            onChange={(checked) => formik.setFieldValue('obrigatorio', checked)}
          />
        </div>
      </div>

      {formik.values.tipo === InputType.Select && (
        <div className='fv-row mb-7'>
          <div className='d-flex justify-content-between'>
            <Label className='form-check-label text-gray-700 ms-0 me-2'>
              Permitir múltipla seleção
            </Label>
            <ToggleInput
              checked={formik.values.multiplo}
              onChange={(checked) => formik.setFieldValue('multiplo', checked)}
            />
          </div>
        </div>
      )}

      {(formik.values.tipo === InputType.Radio || formik.values.tipo === InputType.Select) && (
        <>
          <div className='d-flex fv-row mb-7 align-items-center justify-content-between'>
            <Label className='form-label text-gray-700'>Opções</Label>
            <Button
              onClick={addOption}
              className='btn-primary active btn-sm'
              iconSize={IconSize.Small}
              icon={IconPaths.Add}
            >
              Adicionar Opção
            </Button>
          </div>
          {formik.values.opcoes?.map((option: FormularioOpcaoList, index: number) => (
            <div key={index} className='d-flex align-items-center mb-4'>
              <Input
                className='form-control me-2'
                value={option.descricao}
                onChange={(e) => updateOptions(e.target.value, option)}
                placeholder='Digite seu campo'
              />
              <Button
                className='p-0'
                onClick={() => removeOption(option)}
                icon={IconPaths.Trash}
                iconSize={IconSize.Large}
              />
            </div>
          ))}
          {renderOptionsDefault()}
        </>
      )}

      <div className='d-flex justify-content-end'>
        <Button className='btn-primary' onClick={formik.submitForm}>
          Salvar
        </Button>
      </div>
    </>
  )
}
