import { KTCard } from '../../../../../_metronic/helpers'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { LogsUsuarioIdTable } from './table/LogsUsuarioIdTable'

const LogsUsuarioIdList = () => {
  return (
    <>
      <KTCard>
        <LogsUsuarioIdTable />
      </KTCard>
    </>
  )
}

const LogsUsuarioIdListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LogsUsuarioIdList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { LogsUsuarioIdListWrapper }
