import { Column } from 'react-table'
import { ImportacaoErro } from '../../core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader } from '../../../../../../components'

const importacoesErrosColumns: ReadonlyArray<Column<ImportacaoErro>> = [
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Linha' />
    ),
    id: 'linha',
    accessor: (row) => row.linha || '-',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Campo' />
    ),
    id: 'campo',
    accessor: (row) => row.campo || '-',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Erro' />
    ),
    id: 'erro',
    accessor: 'erro',
  },
]

export { importacoesErrosColumns }
