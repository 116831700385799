import { FC } from 'react'
import { AtendimentoView } from '../../atendimento/core/_models'
import ReactHtmlParser from 'react-html-parser'
import { FetchState, useModal } from '../../../../components'

const Anamnese: FC = () => {
  const { fetchStates, closeModal, refetch } = useModal()

  const { data: atendimento, isLoading } = (fetchStates['atendimento'] ||
    {}) as FetchState<AtendimentoView>

  return (
    <div className='card mb-5' data-kt-scroll='true' data-kt-scroll-offset='5px'>
      {atendimento?.anotacoes && atendimento?.anotacoes?.length > 0 ? (
        <div className='flex-row'>
          <p className='text-muted'>
            {atendimento?.anotacoes?.map((anotacao) => (
              <div className='py-2' key={anotacao.dataFormatada}>
                <p className='fw-bolder'>Nota {anotacao.dataFormatada}</p>
                <p>{ReactHtmlParser(anotacao?.anotacao as string)}</p>
              </div>
            ))}
          </p>
        </div>
      ) : (
        <span>Sem anotações</span>
      )}
    </div>
  )
}
export { Anamnese }
