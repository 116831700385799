import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { RelatorioNpsListWrapper } from './relatorio-nps-list/RelatorioNpsList'

const atendimentosBreadcrumbs: Array<PageLink> = [
  /*  {
    title: 'Atendimentos',
    path: '/apps/atendimentosMedicos/lista',
    isSeparator: false,
    isActive: false,
  }, */
]

const RelatorioNpsPage = () => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={atendimentosBreadcrumbs}>Relatório Nps</PageTitle>
            <RelatorioNpsListWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default RelatorioNpsPage
