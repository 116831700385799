import { AxiosError } from 'axios'
import { ReactNode, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { faltaAtendimentoById } from '../../../../atendimento/core/_requests'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useModal } from '../../../../../../components'

interface MarcarFaltaModalProps {
  atendimento: AtendimentoView
}

const MarcarFaltaModal: React.FC<MarcarFaltaModalProps> = ({ atendimento }) => {
  const { closeModal } = useModal()
  const [observacao, setObservacao] = useState('')
  const navigate = useNavigate()
  const { refetch } = useQueryResponse()

  const handleCancelAtendimento = () => {
    if (!observacao) {
      Swal.fire({
        text: 'O motivo do cancelamento é obrigatório.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      return
    }

    try {
      faltaAtendimentoById(atendimento.id, observacao)
      refetch()
      Swal.fire({
        text: 'Atendimento alterado com sucesso',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      closeModal()
      navigate('/apps/atendimentosMedicos/lista')
    } catch (error) {
      const err = error as AxiosError
      Swal.fire({
        text: `${err.response?.data.data.message}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  return (
    <>
      <Form.Group controlId='observacao'>
        <Form.Label>Observação</Form.Label>
        <Form.Control
          type='text'
          placeholder='Digite uma observação'
          value={observacao}
          required={true}
          className={observacao ? '' : 'is-invalid'}
          onChange={(e) => setObservacao(e.target.value)}
        />
      </Form.Group>

      <div className='d-flex justify-content-end mt-6'>
        <Button variant='secondary' onClick={closeModal} className='me-3'>
          Cancelar
        </Button>
        <Button variant='danger' onClick={handleCancelAtendimento}>
          Confirmar
        </Button>
      </div>
    </>
  )
}

export { MarcarFaltaModal }
