import { KTCard } from '../../../../../_metronic/helpers'
import { PersonalAtendimentosListHeader } from './components/header/PersonalAtendimentosListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { PersonalAtendimentosTable } from './table/PersonalAtendimentosTable'

const PersonalAtendimentosList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <PersonalAtendimentosListHeader />
        <PersonalAtendimentosTable />
      </KTCard>
      {/*  {itemIdForUpdate !== undefined && <AtendimentoMedicoEditModal />} */}
    </>
  )
}

const PersonalAtendimentosListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PersonalAtendimentosList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { PersonalAtendimentosListWrapper }
