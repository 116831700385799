/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import {
  ID,
  KTSVG,
  ModalSize,
  onDeleteAlert,
  QUERIES,
} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { deleteCargoCliente, getCargoClienteById } from '../../core/_request'
import { Dropdown, DropdownItem, useModal } from '../../../../../../components'
import { CargoClienteEditModalForm } from '../../cargo-edit-modal/CargoClienteEditModalForm'

type Props = {
  id: ID
}

const CargoActionsCell: FC<Props> = ({ id }) => {
  const { showModal } = useModal()

  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    showModal({
      title: 'Cargo',
      content: <CargoClienteEditModalForm />,
      size: ModalSize.Medium,
      fetchers: {
        cargo: () => getCargoClienteById(id),
      },
    })
  }

  const deleteItem = useMutation(() => deleteCargoCliente(id), {
    onSuccess: () => {
      Swal.fire({
        text: 'Cargo excluído com sucesso!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then((_) => queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`]))
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const handleDelete = () => onDeleteAlert({ onAction: deleteItem.mutateAsync })

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={openEditModal}>Alterar</DropdownItem>
      <DropdownItem onClick={handleDelete}>Remover</DropdownItem>
    </Dropdown>
  )
}

export { CargoActionsCell }
