import { ImportacaoErroListHeader } from './ImportacaoErroListHeader'
import { ImportacaoErroListTable } from '../table/ImportacaoErroListTable'
import { KTCard } from '../../../../../../_metronic/helpers'

export const ImportacaoErroList = () => {
  return (
    <KTCard>
      <ImportacaoErroListHeader />
      <ImportacaoErroListTable />
    </KTCard>
  )
}
