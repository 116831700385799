import { AxiosError } from 'axios'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { marcarPresencaPaciente } from '../../../../atendimento/core/_requests'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { AtendimentoMedicoInfo } from '../AtendimentoInfo/AtendimentoInfo'
import { useState } from 'react'
import { FetchState, ListLoading, useModal } from '../../../../../../components'

const MarcarPresencaModal: React.FC = () => {
  const { fetchStates, closeModal } = useModal()

  const { data: atendimento, isLoading } = (fetchStates['atendimento'] ||
    {}) as FetchState<AtendimentoView>

  const { refetch } = useQueryResponse()
  const [disable, setDisable] = useState(true)

  const handlePresencaAtendimento = async () => {
    try {
      await marcarPresencaPaciente(atendimento?.id)
      Swal.fire({
        text: 'Presença marcarcada com sucesso',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      refetch()
      closeModal()
    } catch (error) {
      const err = error as AxiosError
      Swal.fire({
        text: `${error}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <ListLoading />
      ) : (
        <>
          <AtendimentoMedicoInfo
            atendimentoMedico={atendimento}
            isMarcarPresencaModal
            setDisable={setDisable}
          />
          <div className='d-flex justify-content-end mt-6'>
            <Button variant='secondary' onClick={closeModal} className='me-3'>
              Cancelar
            </Button>
            <Button variant='success' onClick={handlePresencaAtendimento} disabled={disable}>
              Marcar Presença
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export { MarcarPresencaModal }
