import * as Yup from 'yup'

export const especialidadeSchema = Yup.object({
  nome: Yup.string().required('O nome é obrigatório'),
  tipoAtendimento: Yup.array()
    .of(
      Yup.object({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, 'Selecione ao menos um tipo de atendimento'),
})
