import { FC, ReactNode } from 'react'
import { ButtonType, IconPaths, KTSVG } from '../../../_metronic/helpers'

type IButtonIcon = {
  onClick?: () => void
  icon?: IconPaths | null
  type?: ButtonType
  className?: string
  color?: string
}

export const ButtonIcon: FC<IButtonIcon> = ({ onClick, icon, type, className, color }) => {
  return (
    <button type={type} className={`btn p-0 ${className}`} onClick={onClick}>
      {icon ? <KTSVG path={icon} className='svg-icon-2' color={color} /> : null}
    </button>
  )
}

ButtonIcon.defaultProps = {
  icon: null,
  type: ButtonType.Button,
  className: '',
}
