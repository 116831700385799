import { KTCard } from '../../../../../_metronic/helpers'
import { LogsConsultaListHeader } from './components/header/LogsConsultaListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { LogsConsultaTable } from './table/LogsConsultaTable'

const LogsConsultaList = () => {
  return (
    <>
      <KTCard>
        <LogsConsultaListHeader />
        <LogsConsultaTable />
      </KTCard>
    </>
  )
}

const LogsConsultaListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LogsConsultaList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { LogsConsultaListWrapper }
