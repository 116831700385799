import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import {
  AtendimentoView,
  DispositivoList,
  TiposDispositivos,
} from '../../../atendimento/core/_models'
import React from 'react'
import { HistoricoColumns } from './columns/_columns'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { CustomHeaderColumn, CustomPagination, ListLoading } from '../../../../../components'

const HistoricoTable = () => {
  const atendimentosMedicos = useQueryResponseData()
  const atendimentosFormatados = atendimentosMedicos.sort((a, b) => {
    const dataA = a.inicio ? new Date(a.inicio) : new Date(0)
    const dataB = b.inicio ? new Date(b.inicio) : new Date(0)
    //@ts-ignore
    return dataB - dataA
  })

  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => atendimentosFormatados, [atendimentosFormatados])
  const columns = useMemo(() => HistoricoColumns, [])

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data as AtendimentoView[],
  })

  const getIconsPath = (dispositivos: DispositivoList[], tipo: TiposDispositivos) => {
    const dispositivoEncontrado = dispositivos.find((d) => d.tipo === tipo)

    if (dispositivoEncontrado) {
      return `/media/icons/duotune/sinais/${tipo.toLowerCase()}-on.png`
    } else {
      return `/media/icons/duotune/sinais/${tipo.toLowerCase()}.png`
    }
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_historico_clinico'
          className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
              {headers.map((column: ColumnInstance<AtendimentoView>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<AtendimentoView>, i) => {
                prepareRow(row)
                return (
                  <React.Fragment key={`row-${i}-${row.id}`}>
                    <tr>
                      {row.cells.map((cell, j) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.column.id === 'sinais' ? (
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className='d-flex justify-content-around align-items-center mb-2'>
                                  <img
                                    src={getIconsPath(
                                      row?.original?.dispositivos as DispositivoList[],
                                      'BALANCA'
                                    )}
                                    width={33}
                                    alt='Ícone de balança'
                                  />
                                  <img
                                    src={getIconsPath(
                                      row?.original?.dispositivos as DispositivoList[],
                                      'PRESSAO'
                                    )}
                                    width={35}
                                    alt='Ícone de aferidor de pressão arterial'
                                  />
                                  <img
                                    src={getIconsPath(
                                      row?.original?.dispositivos as DispositivoList[],
                                      'OXIMETRO'
                                    )}
                                    width={25}
                                    alt='Ícone de oxímetro'
                                  />
                                </div>
                                <div className='d-flex justify-content-around align-items-center'>
                                  <img
                                    src={getIconsPath(
                                      row?.original?.dispositivos as DispositivoList[],
                                      'TERMOMETRO'
                                    )}
                                    width={25}
                                    alt='Ícone de termometro'
                                  />
                                  <img
                                    src={getIconsPath(
                                      row?.original?.dispositivos as DispositivoList[],
                                      'CAMERA'
                                    )}
                                    width={25}
                                    alt='Ícone de inspeção'
                                  />
                                  <img
                                    src={getIconsPath(
                                      row?.original?.dispositivos as DispositivoList[],
                                      'OTOSCOPIO'
                                    )}
                                    width={25}
                                    alt='Ícone de otoscópio'
                                  />
                                  <img
                                    src={getIconsPath(
                                      row?.original?.dispositivos as DispositivoList[],
                                      'GLICOSIMETRO'
                                    )}
                                    width={25}
                                    alt='Ícone de Glicosímetro'
                                  />
                                </div>
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  </React.Fragment>
                )
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CustomPagination useQueryRequest={useQueryRequest} useQueryResponse={useQueryResponse} />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { HistoricoTable }
