/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContextSemPaginacao,
  initialQueryResponseSemPaginacao,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { useSearchParams } from 'react-router-dom'
import { getVinculosGroupByEspecialidade } from './_requests'
import { Vinculos } from './_models'
import { useModal } from '../../../../../components'

const QueryResponseContext = createResponseContextSemPaginacao<Vinculos>(
  initialQueryResponseSemPaginacao
)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isOpen } = useModal()
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const [searchParams] = useSearchParams()
  const formId = Number(searchParams.get('id'))

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.VINCULOS_LIST}-${query}-${isOpen}`,
    () => getVinculosGroupByEspecialidade(formId, query),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !isOpen,
    }
  )

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response,
        query,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading }
