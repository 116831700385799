import { AxiosError } from 'axios'
import Swal, { SweetAlertResult } from 'sweetalert2'
import { ID } from './crud-helper/models'
import { buildErrorMessage } from './BuildErrorMessage'

export const alertCertificado = async (
  onConfirm: Function,
  selected: { formularioId: ID; atendimentoId: ID } | ID[]
) => {
  try {
    const result = await Swal.fire({
      title: 'Digite a senha do Certificado',
      input: 'password',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary',
      },
      inputAttributes: {
        maxlength: '16',
        autocapitalize: 'off',
        autocorrect: 'off',
      },

      preConfirm: (senha) => {
        if (!senha || senha.length < 6) {
          Swal.showValidationMessage(`A senha deve conter no mínimo 6 caracteres`)
          return false
        }
        return senha
      },
    })

    if (result.isConfirmed && result.value) {
      Swal.showLoading()
      await onConfirm(selected, result.value)
      Swal.hideLoading()
      return true
    } else {
      return false
    }
  } catch (error) {
    Swal.hideLoading()
    throw error
  }
}

export const onDeleteAlert = async (options: {
  title?: string
  text?: string
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question'
  confirmButtonText?: string
  onAction: Function
}): Promise<boolean> => {
  const result = await Swal.fire({
    title: options.title || 'Você tem certeza?',
    text: options.text || 'Essa ação não pode ser desfeita!',
    icon: options.icon || 'warning',
    showCancelButton: true,
    confirmButtonText: options.confirmButtonText || 'Sim, excluir!',
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-secondary',
    },
    buttonsStyling: false,
  })

  if (result.isConfirmed) {
    await options.onAction()
    return true
  } else {
    return false
  }
}

export const showError = (error: any) =>
  showAlert({
    title: 'Erro!',
    html: error.response
      ? buildErrorMessage(error.response.data.data)
      : 'Um erro inesperado aconteceu.',
    icon: 'error',
  })

export const showAlert = (options: {
  title: string
  text?: string
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question'
  html?: string
  showLoading?: boolean
}): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title: options.title,
    text: options.text ? options.text : '',
    html: options.html ? options.html : '',
    icon: options.icon,
    buttonsStyling: false,
    confirmButtonText: 'Ok',
    customClass: { confirmButton: 'btn btn-primary' },
    ...(options.showLoading && {
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    }),
  })
}
