/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC } from 'react'
import Pagination from 'react-js-pagination'
import { initialQueryState, QueryResponseContextProps } from '../../../_metronic/helpers'

export type IPagination = {
  useQueryRequest: () => {
    state: { sort?: string; order?: 'asc' | 'desc' }
    updateState: (newState: any) => void
  }
  useQueryResponse: () => QueryResponseContextProps<any>
}

export const CustomPagination: FC<IPagination> = ({ useQueryRequest, useQueryResponse }) => {
  const { response, isLoading } = useQueryResponse()
  const { updateState } = useQueryRequest()

  const pagination = response || initialQueryState

  const updatePage = (page: number) => {
    if (isLoading || pagination.page === page) {
      return
    }

    updateState({ page, size: pagination.size || 10 })
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div>
          <Pagination
            activePage={pagination.page + 1}
            itemsCountPerPage={pagination.size}
            totalItemsCount={pagination.totalElements}
            pageRangeDisplayed={5}
            onChange={(p) => updatePage(p - 1)}
            innerClass='pagination'
            itemClass='page-item'
            linkClass='page-link'
            hideFirstLastPages={true}
            prevPageText='Anterior'
            nextPageText='Próximo'
          />
        </div>
      </div>
    </div>
  )
}
