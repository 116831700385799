import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponse, useQueryResponseData } from '../core/QueryResponseProvider'
import { KTCardBody } from '../../../../../../_metronic/helpers'

import { LogsConsultaColumns } from './columns/_columns'
import { AtendimentoList } from '../../../atendimento/core/_models'
import { useQueryRequest } from '../core/QueryRequestProvider'
import {
  CustomHeaderColumn,
  CustomRow,
  CustomPagination,
  ListLoading,
} from '../../../../../components'

const LogsConsultaTable = () => {
  const logs = useQueryResponseData()
  const { isLoading } = useQueryResponse()
  const data = useMemo(() => logs, [logs])
  const columns = useMemo(() => LogsConsultaColumns, [])

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_meus_atendimentos'
          className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-secondary'>
              {headers.map((column: ColumnInstance<AtendimentoList>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<AtendimentoList>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CustomPagination useQueryRequest={useQueryRequest} useQueryResponse={useQueryResponse} />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { LogsConsultaTable }
