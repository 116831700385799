/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { IconPaths, IconSize, ModalSize } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { AtendimentoView } from '../../../../atendimento/core/_models'
import { downloadProntuario, getAtendimentoById } from '../../../../atendimento/core/_requests'
import { Button, Dropdown, DropdownItem, useModal } from '../../../../../../components'
import {
  AnotacaoModal,
  AtendimentoMedicoEditModalForm,
  CancelarAtendimentoModal,
  MarcarFaltaModal,
  MarcarPresencaModal,
  ReabrirAtendimentoModal,
} from '../../component/modal'
import { ExamesModal } from '../../../../atendimento-ASO/components/modal'

type Props = {
  atendimento: AtendimentoView
}

const AtendimentoMedicoActionsCell: FC<Props> = ({ atendimento }) => {
  const { showModal } = useModal()

  const isCancelado = atendimento.estado?.nome === 'Cancelado'

  const showExames = () => {
    showModal({
      title: `Exames de ${atendimento.paciente?.pessoa?.nome} - ${atendimento.protocolo}`,
      content: <ExamesModal atendimento={atendimento} />,
    })
  }

  const showAnotacao = () => {
    showModal({
      title: atendimento.estado?.nome !== 'Pendente' ? 'Adicionar Pendência' : 'Resolver Pendência',
      content: (
        <AnotacaoModal
          atendimentoId={atendimento.id}
          isRequired={atendimento.estado?.nome !== 'Pendente'}
        />
      ),
    })
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const showCancelarAtendimento = () => {
    showModal({
      title: `Cancelar atendimento de ${atendimento.paciente?.pessoa?.nome}?`,
      content: <CancelarAtendimentoModal atendimento={atendimento} />,
      size: ModalSize.Medium,
    })
  }

  const showFaltouAtendimento = () => {
    showModal({
      title: `Marcar falta do paciente ao atendimento de ${atendimento.paciente?.pessoa?.nome}?`,
      content: <MarcarFaltaModal atendimento={atendimento} />,
      size: ModalSize.Medium,
    })
  }

  const handleDownloadProntuario = () => {
    downloadProntuario(atendimento.id)
      .then((anexo) => {
        if (anexo !== undefined) {
          var a = document.createElement('a') //Create <a>
          a.href = 'data:application/pdf;base64,' + (anexo !== undefined ? anexo.contentBase64 : '') //Image Base64 Goes here
          a.download = String(anexo.name) //File name Here
          a.click()
        }
      })
      .catch((error) => {
        const errors = error.response.data.data.message

        Swal.fire({
          text: `Não foi possível realizar o download: ${errors}.`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const showReabrirAtendimento = () => {
    showModal({
      title: `Reabrir o atendimento de ${atendimento.paciente?.pessoa?.nome}?`,
      content: <ReabrirAtendimentoModal atendimentoId={atendimento.id} />,
      size: ModalSize.Medium,
    })
  }

  const showMarcarPresenca = () => {
    showModal({
      title: 'Atendimento',
      content: <MarcarPresencaModal />,
      fetchers: {
        atendimento: () => getAtendimentoById(atendimento?.id),
      },
    })
  }

  const showAtendimento = () => {
    showModal({
      title: 'Atendimento Médico',
      content: <AtendimentoMedicoEditModalForm />,
      fetchers: {
        atendimentoMedico: () => getAtendimentoById(atendimento?.id),
      },
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        <div className='me-2'>
          <Button
            className='btn-primary btn-sm fs-7'
            iconSize={IconSize.Small}
            icon={IconPaths.Search}
            onClick={showAtendimento}
          >
            Visualizar
          </Button>
        </div>
        <Dropdown name='Ações' size={175}>
          {atendimento?.presencaPacienteFormatada === null && (
            <DropdownItem onClick={showMarcarPresenca}>Marcar presença</DropdownItem>
          )}
          {!isCancelado && (
            <DropdownItem onClick={showCancelarAtendimento}> Cancelar atendimento</DropdownItem>
          )}

          <DropdownItem onClick={showFaltouAtendimento}>Marcar falta paciente</DropdownItem>
          {atendimento?.estado?.nome === 'Finalizado' && (
            <DropdownItem onClick={handleDownloadProntuario}>Baixar atendimento</DropdownItem>
          )}
          {atendimento?.estado?.nome !== 'Iniciado' &&
            atendimento?.estado?.nome !== 'Aguardando' && (
              <DropdownItem onClick={showReabrirAtendimento}>Reabrir atendimento</DropdownItem>
            )}
          <DropdownItem onClick={showExames}>Inserir Exames</DropdownItem>

          {atendimento?.estado?.nome == 'Pendente' ? (
            <DropdownItem onClick={showAnotacao}>Resolver Pendência</DropdownItem>
          ) : (
            <DropdownItem onClick={showAnotacao}>Marcar como Pendente</DropdownItem>
          )}
        </Dropdown>
      </div>
    </>
  )
}

export { AtendimentoMedicoActionsCell }
