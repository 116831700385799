import { FC } from 'react'

type IToggleInput = {
  onChange: (checked: boolean) => void
  checked: boolean
  className?: string
  disabled?: boolean
}

export const ToggleInput: FC<IToggleInput> = ({ onChange, className, checked, disabled }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)

  return (
    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid '>
      <input
        className={`form-check-input ${className}`}
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  )
}

ToggleInput.defaultProps = {
  className: '',
  disabled: false,
}
