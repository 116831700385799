import Swal from 'sweetalert2'
import { IconPaths, showAlert } from '../../../../../../../_metronic/helpers'
import { Button, SelectionHeaderContainer } from '../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { RelatorioNpsListSearchComponent } from './RelatorioNpsSearchComponent'
import { getCsvNps, getPdfNps } from '../../core/_requests'

const RelatorioNpsListHeader = () => {
  const { state } = useQueryRequest()

  const downloadPdf = async () => {
    showAlert({
      title: 'Aguarde...',
      text: 'Carregando os dados...',
      showLoading: true,
    })

    try {
      const file = await getPdfNps(`search=${state.search}` as string)

      if (file !== undefined) {
        if (!file.contentBase64) throw new Error('Sem dados para download.')

        const a = document.createElement('a')
        a.href = 'data:application/pdf;base64,' + file.contentBase64
        a.download = String(file.name)
        a.click()
      }
      Swal.close()
      showAlert({
        title: 'Sucesso!',
        text: 'Os dados foram baixados com sucesso.',
        icon: 'success',
      })
    } catch (error) {
      if (error instanceof Error) {
        Swal.close()
        showAlert({
          title: 'Erro!',
          text: error.message || 'Houve um erro ao baixar os dados.',
          icon: 'error',
        })
      }
    }
  }

  const downloadCsv = async () => {
    showAlert({
      title: 'Aguarde...',
      text: 'Carregando os dados...',
      showLoading: true,
    })

    try {
      const file = await getCsvNps(`search=${state.search}` as string)

      if (file !== undefined) {
        if (!file.contentBase64) throw new Error('Sem dados para download.')
        const binaryString = window.atob(file.contentBase64)

        const arrayBuffer = new ArrayBuffer(binaryString.length)
        const uint8Array = new Uint8Array(arrayBuffer)

        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i)
        }

        const blob = new Blob([uint8Array], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file.name || 'download.csv'
        link.click()
      }

      Swal.close()
      showAlert({
        title: 'Sucesso!',
        text: 'Os dados foram baixados com sucesso.',
        icon: 'success',
      })
    } catch (error) {
      if (error instanceof Error) {
        Swal.close()
        showAlert({
          title: 'Erro!',
          text: error.message || 'Houve um erro ao baixar os dados.',
          icon: 'error',
        })
      }
    }
  }

  return (
    <div className='container border-0 pt-6'>
      <RelatorioNpsListSearchComponent />
      <SelectionHeaderContainer>
        <Button className='btn-primary me-2' icon={IconPaths.Pdf} onClick={downloadPdf}>
          Baixar em PDF
        </Button>
        <Button className='btn-primary' icon={IconPaths.Csv} onClick={downloadCsv}>
          Baixar em csv
        </Button>
      </SelectionHeaderContainer>
    </div>
  )
}

export { RelatorioNpsListHeader }
