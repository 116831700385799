/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, ModalSize, onDeleteAlert, QUERIES } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { inativarEmpresa } from '../../../empresas-list/core/_requests'
import { useNavigate } from 'react-router-dom'
import { Dropdown, DropdownItem, useModal } from '../../../../../../components'
import { getEmpresaClienteById } from '../../core/_request'
import { EmpresaClienteEditModalForm } from '../../empresa-cliente-edit-modal/EmpresaClienteEditModalForm'
type Props = {
  id: ID
}

const EmpresasActionsCell: FC<Props> = ({ id }) => {
  const { showModal } = useModal()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    showModal({
      title: 'Empregador ASO',
      content: <EmpresaClienteEditModalForm />,
      size: ModalSize.Medium,
      fetchers: {
        cliente: () => getEmpresaClienteById(id),
      },
    })
  }

  const openCliente = (path: String) => {
    getEmpresaClienteById(id)
      .then((result) => {
        //id refere-se a um cliente da empresa que retorna da consulta
        navigate(
          '/apps/empresasCliente/' + path + '?cliente=' + id + '&empresa=' + result?.empresa?.id
        )
      })
      .catch((error) => {
        const err = error as AxiosError
        Swal.fire({
          text: 'Erro ao buscar empresa! ' + err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const deleteItem = useMutation(() => inativarEmpresa(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const handleDelete = () => onDeleteAlert({ onAction: deleteItem.mutateAsync })

  return (
    <>
      <Dropdown name='Ações'>
        <DropdownItem onClick={() => openCliente('coordenadores')}>Coordenadores</DropdownItem>
        <DropdownItem onClick={() => openCliente('setores')}>Setores</DropdownItem>
        <DropdownItem onClick={() => openCliente('cargos')}>Cargos</DropdownItem>
        <DropdownItem onClick={() => openCliente('riscos')}>Riscos</DropdownItem>
        <DropdownItem onClick={() => openCliente('exames')}>Exames</DropdownItem>
        <hr />
        <DropdownItem onClick={openEditModal}>Alterar</DropdownItem>
        <DropdownItem onClick={handleDelete}>Excluir</DropdownItem>
      </Dropdown>
    </>
  )
}

export { EmpresasActionsCell }
