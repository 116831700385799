import { FC, useRef } from 'react'
import {
  ModalSize,
  onDeleteAlert,
  QUERIES,
  showAlert,
  showError,
} from '../../../../../../../_metronic/helpers'
import { EspecialidadeView } from '../../core/_models'
import EspecialidadeEditModalForm from '../../../especialidade-edit-modal-form/EspecialidadeEditModalForm'
import { CustomModal, Dropdown, DropdownItem, useModal } from '../../../../../../components'
import { useMutation, useQueryClient } from 'react-query'
import { updateEspecialidadeStatus } from '../../core/_requests'
import { useQueryResponse } from '../../core/QueryResponseProvider'

type Props = {
  especialidade: EspecialidadeView
}

const EspecialidadeActionsCell: FC<Props> = ({ especialidade }) => {
  const { showModal } = useModal()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  const toggleStatus = useMutation(
    () => updateEspecialidadeStatus(especialidade.id, !especialidade.ativa),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${QUERIES.ESPECIALIDADES_LIST}-${query}`])
        showAlert({
          title: 'Sucesso!',
          text: `Especialidade ${especialidade.ativa ? 'inativada' : 'ativada'}`,
          icon: 'success',
        })
      },
      onError(error: any) {
        showError(error)
      },
    }
  )

  const openModal = () =>
    showModal({
      content: <EspecialidadeEditModalForm especialidade={especialidade} />,
      title: 'Editar Especialidade',
      size: ModalSize.Medium,
    })

  const handleStatus = () =>
    onDeleteAlert({
      text: `Você tem certeza que quer ${
        especialidade.ativa ? 'inativar' : 'ativar'
      } a especialidade ${especialidade.nome}`,
      onAction: toggleStatus.mutateAsync,
      icon: `${especialidade.ativa ? 'warning' : 'info'}`,
      confirmButtonText: `Sim, ${especialidade.ativa ? 'inativar' : 'ativar'}`,
    })

  return (
    <Dropdown name='Ações'>
      <DropdownItem onClick={openModal}>Editar</DropdownItem>
      <DropdownItem onClick={handleStatus}>
        {especialidade.ativa ? 'Desativar' : 'Ativar'}
      </DropdownItem>
    </Dropdown>
  )
}

export { EspecialidadeActionsCell }
