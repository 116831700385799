import * as Yup from 'yup'

export const createValidationSchema = Yup.object().shape({
  nome: Yup.string().required('Nome do formulário é obrigatório'),
  descricao: Yup.string().required('Descrição é obrigatória'),
  assinar: Yup.boolean().required('Indique se o formulário deve ser assinado'),
  assinarPaciente: Yup.boolean().required(
    'Indique se o formulário deve ser assinado pelo paciente'
  ),
  ativo: Yup.boolean().required('Indique se o formulário está ativo'),
  campos: Yup.array()
    .min(1, 'Deve haver pelo menos uma pergunta.')
    .required('No mínimo uma pergunta é obrigatória'),
})

export const updateValidationSchema = Yup.object().shape({
  nome: Yup.string()
    .required('Nome do formulário é obrigatório')
    .max(100, 'O nome deve ter no máximo 100 caracteres')
    .min(0, 'O nome não pode ser vazio'),
  descricao: Yup.string()
    .required('Descrição é obrigatória')
    .max(512, 'A descrição deve ter no máximo 512 caracteres'),
  assinar: Yup.boolean().required('Indique se o formulário deve ser assinado'),
  assinarPaciente: Yup.boolean().required(
    'Indique se o formulário deve ser assinado pelo paciente'
  ),
  ativo: Yup.boolean().required('Indique se o formulário está ativo'),
})
