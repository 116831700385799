import { Column } from 'react-table'
import { LogsConsultaActionsCell } from './LogsConsultaActionsCell'
import { AtendimentoList } from '../../../../atendimento/core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader } from '../../../../../../components'

const LogsConsultaColumns: ReadonlyArray<Column<AtendimentoList>> = [
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Responsável' />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Paciente' />
    ),
    id: 'paciente.pessoa.nome',
    accessor: (row) => row.paciente?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Responsável' />
    ),
    id: 'responsavel.pessoa.nome',
    accessor: (row) => row.responsavel?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Solicitação' />
    ),
    id: 'solicitacao',
    accessor: (row) => row.solicitacaoFormatada || '-',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Ações' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <LogsConsultaActionsCell atendimentoId={props.row.original.id} />,
  },
]

export { LogsConsultaColumns }
