import { Column } from 'react-table'
import { LogAcessoView } from '../../core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader } from '../../../../../../components'

export const LogsUsuarioIdColumns: ReadonlyArray<Column<LogAcessoView>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data'
        className='min-w-75px'
      />
    ),
    id: 'data',
    accessor: 'dataFormatada',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Responsável'
        className='min-w-75px'
        isAbleToSort={false}
      />
    ),
    id: 'usuario.nome',
    accessor: (row) => (row.usuario ? row.usuario.nome : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Descrição'
        className='min-w-75px'
      />
    ),
    id: 'descricao',
    accessor: (row) => (row.descricao ? row.descricao : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Método'
        className='min-w-75px'
      />
    ),
    id: 'metodo',
    accessor: (row) => (row.metodo ? row.metodo : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Url'
        className='min-w-75px'
      />
    ),
    id: 'url',
    accessor: (row) => (row.url ? row.url : '-'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Status'
        className='min-w-75px'
      />
    ),
    id: 'status',
    accessor: (row) => (row.status ? row.status : '-'),
  },
]
