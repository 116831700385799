import { FC, Suspense, lazy, useEffect, useState } from 'react'
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom'
import { Permission, WithChildren } from '../../_metronic/helpers'

import ApiPage from '../modules/apps/api/ApiPage'
import { AtendimentosListWrapper } from '../modules/apps/atendimento/atendimento-list/AtendimentoList'
import AtendimentosMedicosPage from '../modules/apps/atendimento_medicos/AtendimentoMedicoPage'
import AvaliacoesPage from '../modules/apps/avaliacoes/AvaliacoesPage'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { DashboardAdmin } from '../pages/dashboard/DashboardAdmin'
import { DashboardEmpresa } from '../pages/dashboard/DashboardEmpresa'
import { DashboardProfissional } from '../pages/dashboard/DashboardProfissional'
import DownloadAsoPage from '../modules/apps/atendimento-ASO/DownloadAsoPage'
import EmpresasClientePage from '../modules/apps/empresa/EmpresaPageCliente'
import EmpresasPage from '../modules/apps/empresa/EmpresaPage'
import EspecialidadePage from '../modules/apps/especialidades/EspecialidadesPage'
import FormulariosPage from '../modules/apps/formularios/FormulariosPage'
import ImportacaoPage from '../modules/apps/importacao/ImportacaoPage'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { MenuTestPage } from '../pages/MenuTestPage'
import RelatorioPage from '../modules/apps/relatorios/RelatorioPage'
import Swal from 'sweetalert2'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { getLoggedUser } from '../modules/auth/core/_requests'
import { useAuth } from '../modules/auth'
import PersonalAtendimentosPage from '../modules/apps/meus-atendimentos/PersonalAtendimentosPage'
import LogsPage from '../modules/apps/logs/LogsPage'
import RelatorioNpsPage from '../modules/apps/relatorio-nps/RelatorioNpsPage'
import EspecialistasPage from '../modules/apps/especialistas/EspecialistasPage'

interface RoutesPropsData extends RouteProps {
  role?: string[]
}

const PrivateRoutes: React.FC<RoutesPropsData> = ({ role, ...rest }) => {
  const [permissions, setPermissions] = useState<Permission[]>([])

  const { currentUser } = useAuth()

  function loadRoles() {
    getLoggedUser()
      .then((responseData) => {
        if (responseData.data.grupo !== undefined) {
          const role = responseData.data.grupo.nome
          if (role === Permission.Usuario) {
            setPermissions([Permission.Usuario])
          } else if (role === Permission.Medico) {
            setPermissions([Permission.Medico, Permission.Usuario])
          } else if (role === Permission.Admin) {
            setPermissions([
              Permission.Medico,
              Permission.Usuario,
              Permission.Admin,
              Permission.SuperGerente,
            ])
          } else if (role === Permission.SuperAdmin) {
            setPermissions([
              Permission.Medico,
              Permission.Usuario,
              Permission.Admin,
              Permission.SuperAdmin,
            ])
          } else if (role === Permission.SuperMedico) {
            setPermissions([Permission.SuperMedico])
          } else if (role === Permission.SuperGerente) {
            setPermissions([Permission.SuperGerente])
          }
        } else {
          Swal.fire({
            text: 'Erro ao processar os dados do usuário',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    loadRoles()
  }, [])

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const PacientePage = lazy(() => import('../modules/apps/paciente-management/PacientesPage'))
  const AtendimentosAsoPage = lazy(
    () => import('../modules/apps/atendimento-ASO/AtendimentoAsoPage')
  )
  const AtendimentosPage = lazy(() => import('../modules/apps/atendimento/AtendimentosPage'))
  const FuncionarioPage = lazy(
    () => import('../modules/apps/funcionarios-management/FuncionariosPage')
  )
  const ResetPasswordPage = lazy(() => import('../modules/auth/components/ResetPasswordPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}

        <Route
          path='confirm-account'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <>
          {/* <Route
            path='apps/atendimentos/*'
            element={
              <SuspensedView>
                <AtendimentosPage />
              </SuspensedView>
            }
          /> */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          {/* <Route path='*' element={<Navigate to='/apps/atendimentos/paciente' />} /> */}
        </>
        {permissions.find(
          (r) =>
            r === Permission.Medico || r === Permission.SuperMedico || r === Permission.SuperGerente
        ) ? (
          <>
            <Route
              path='apps/pacientes-management/*'
              element={
                <SuspensedView>
                  <PacientePage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/atendimentosAso/*'
              element={
                <SuspensedView>
                  <AtendimentosAsoPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/downloadAso/*'
              element={
                <SuspensedView>
                  <DownloadAsoPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/relatorios/*'
              element={
                <SuspensedView>
                  <RelatorioPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/atendimentosMedicos/*'
              element={
                <SuspensedView>
                  <AtendimentosMedicosPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/avaliacoes/*'
              element={
                <SuspensedView>
                  <AvaliacoesPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/importacao/*'
              element={
                <SuspensedView>
                  <ImportacaoPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/meus-atendimentos/*'
              element={
                <SuspensedView>
                  <PersonalAtendimentosPage />
                </SuspensedView>
              }
            />

            <Route path='auth/*' element={<Navigate to='/apps/atendimentosMedicos/lista' />} />
            {permissions.find((r) => r === Permission.SuperGerente) ? (
              <Route index element={<Navigate to='/apps/relatorios' />} />
            ) : (
              <Route index element={<Navigate to='/apps/atendimentosMedicos/lista' />} />
            )}

            {permissions.find((r) => r === Permission.SuperAdmin) ? (
              <Route
                path='apps/logs/*'
                element={
                  <SuspensedView>
                    <LogsPage />
                  </SuspensedView>
                }
              />
            ) : null}
          </>
        ) : (
          <>{/* <Route path='*' element={<Navigate to='/error/404' />} /> */}</>
        )}

        {permissions.find((r) => r === Permission.SuperAdmin) && (
          <Route
            path='apps/relatorio-nps/*'
            element={
              <SuspensedView>
                <RelatorioNpsPage />
              </SuspensedView>
            }
          />
        )}
        {permissions.find(
          (r) =>
            r === Permission.Admin ||
            r === Permission.SuperMedico ||
            r === Permission.SuperAdmin ||
            r === Permission.SuperGerente
        ) ? (
          <>
            <Route
              path='apps/funcionarios-management/*'
              element={
                <SuspensedView>
                  <FuncionarioPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/especialistas/*'
              element={
                <SuspensedView>
                  <EspecialistasPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/empresas/*'
              element={
                <SuspensedView>
                  <EmpresasPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/api'
              element={
                <SuspensedView>
                  <ApiPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/especialidades'
              element={
                <SuspensedView>
                  <EspecialidadePage />
                </SuspensedView>
              }
            />
            {/* admin, supermedico, surperAdmin */}
            <Route
              path='apps/formularios/*'
              element={
                <SuspensedView>
                  <FormulariosPage />
                </SuspensedView>
              }
            />

            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/admin' />} />
            <Route path='admin' element={<DashboardAdmin />} />
            <Route path='empresa' element={<DashboardEmpresa />} />
            <Route path='profissional' element={<DashboardProfissional />} />
            <Route index element={<DashboardProfissional />} />
          </>
        ) : (
          <>{/* <Route path='*' element={<Navigate to='/error/404' />} /> */}</>
        )}
        {permissions.find(
          (r) =>
            r === Permission.Admin ||
            r === Permission.Medico ||
            r === Permission.SuperMedico ||
            r === Permission.SuperAdmin
        ) ? (
          <Route
            path='apps/empresasCliente/*'
            element={
              <SuspensedView>
                <EmpresasClientePage />
              </SuspensedView>
            }
          />
        ) : (
          <></>
        )}
        {permissions.find((r) => r === Permission.Usuario) ? (
          <>
            <Route
              path='apps/atendimentos/*'
              element={
                <SuspensedView>
                  <AtendimentosPage />
                </SuspensedView>
              }
            />
            <Route path='paciente' element={<AtendimentosListWrapper />} />
            <Route path='auth/*' element={<Navigate to='/apps/atendimentos/paciente' />} />
            <Route index element={<Navigate to='/apps/atendimentos/paciente' />} />
          </>
        ) : (
          <></>
        )}
        {permissions.find((r) => r === Permission.Usuario) &&
        currentUser?.vinculos?.find((r) => r.cargo === 'COLABORADOR') ? (
          <>
            <Route
              path='apps/empresasCliente/*'
              element={
                <SuspensedView>
                  <EmpresasClientePage />
                </SuspensedView>
              }
            />
          </>
        ) : (
          <></>
        )}
        {/* Page PAciente */}
        {/* {permissions.find(r => r === permissaoPaciente) ? (
          <Route path='*' element={<Navigate to='/apps/atendimentos/paciente' />} />
        ) : (
          <></>
        )} */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
