import { Column } from 'react-table'
import { FormularioActionsCell } from './FormulariosActionsCell'
import { Badge, CustomHeader } from '../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { FormularioList } from '../../core/_models'

const especialidadesColumns: ReadonlyArray<Column<FormularioList>> = [
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Nome' />
    ),
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Descrição' />
    ),
    id: 'descricao',
    accessor: 'descricao',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Status' />
    ),
    accessor: 'ativo',
    Cell: ({ value }) => <Badge isActive={value} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <FormularioActionsCell row={props.row} />,
  },
]

export { especialidadesColumns }
