import { KTCard } from '../../../../../../_metronic/helpers'
import { ImportacaoListTable } from '../table/ImportacaoListTable'
import { ImportacaoListHeader } from './header/ImportacaoListHeader'

export const ImportacaoList = () => {
  return (
    <>
      <KTCard>
        <ImportacaoListHeader />
        <ImportacaoListTable />
      </KTCard>
    </>
  )
}
