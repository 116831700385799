import { useState, useEffect } from 'react'

import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useListView } from '../../core/ListViewProvider'
import { Label, TextInput, useModal } from '../../../../../components'
import { initialQueryState, useDebounce } from '../../../../../../_metronic/helpers'

const EspecialistasListSearchComponent = () => {
  const { updateState } = useQueryRequest()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        filter: [],
        search: searchTerm,
        sort: 'nome',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [searchTerm, debouncedSearchTerm])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value ? `nome~*${e.target.value}*` : ''
    setSearchTerm(search)
  }

  return (
    <div className='col-md-5 col-12 col-12 justify-content-center'>
      <Label className='pb-1'>Profissional de Saúde</Label>
      <TextInput placeholder='Digite o nome' onChange={onChange} />
    </div>
  )
}

export { EspecialistasListSearchComponent }
