import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { KTCard } from '../../../../../_metronic/helpers'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { CoordenadorClienteTable } from './table/CoordenadorClienteTable'
import { ClienteView } from '../empresasCliente-list/core/_models'
import { getEmpresaClienteById } from '../empresasCliente-list/core/_request'
import { ListLoading } from '../../../../components'

let storedCliente: number | undefined
let storedSetEmpresa: React.Dispatch<React.SetStateAction<ClienteView | undefined>> | undefined
let storedSetIsLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined

const fetchData = async () => {
  try {
    if (storedCliente !== undefined && storedSetEmpresa && storedSetIsLoading) {
      const response = await getEmpresaClienteById(storedCliente)
      storedSetEmpresa(response)
      storedSetIsLoading(false)
    }
  } catch (error) {
    console.error('Error fetching Empregador ASO:', error)
    if (storedSetEmpresa && storedSetIsLoading) {
      storedSetEmpresa(undefined)
      storedSetIsLoading(false)
    }
  }
}

export const refreshEmpresaCliente = () => {
  fetchData()
}

const CoordenadorClienteList = () => {
  const [searchParams] = useSearchParams()

  const cliente = Number(searchParams.get('cliente'))
  const [empresa, setEmpresa] = useState<ClienteView>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    storedCliente = cliente
    storedSetEmpresa = setEmpresa
    storedSetIsLoading = setIsLoading
    fetchData()
  }, [cliente])

  return (
    <>
      <KTCard>
        {isLoading ? (
          <ListLoading />
        ) : (
          <>
            <CoordenadorClienteTable empresa={empresa} isLoading={isLoading} />
          </>
        )}
      </KTCard>
    </>
  )
}

const CoordenadorClienteListWrapper = () => (
  <QueryRequestProvider>
    <ListViewProvider>
      <CoordenadorClienteList />
    </ListViewProvider>
  </QueryRequestProvider>
)

export { CoordenadorClienteListWrapper }
