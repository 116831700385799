import { FC, useEffect, useRef, useState } from 'react'

import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { Button, CustomModal, useModal } from '../../../../../../components'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../../auth'
import {
  alertCertificado,
  FormularioSituacao,
  IconPaths,
  IconSize,
  showAlert,
} from '../../../../../../../_metronic/helpers'
import { signAvaliacao } from '../../core/_requests'
import { getFormularioAtendimento } from '../../../../atendimento/core/_requests'
import { FormularioAtendimentoList } from '../../core/_models'
import { FormularioModal } from '../../components/modal/FormularioModal'
import { AxiosError } from 'axios'

type Props = {
  formularioAtendimento: FormularioAtendimentoList
}

export const AvaliacoesPendentesActionsCell: FC<Props> = ({ formularioAtendimento }) => {
  const { showModal } = useModal()

  const user = useAuth().currentUser
  const possuiCertificado = user?.pessoa?.certificadoDigital

  const isMedico = user?.vinculos?.some((vinculo) => vinculo.cargo === 'MEDICO') || false

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleAssinarAvaliacao = async () => {
    if (possuiCertificado) {
      try {
        const isConfirmed = await alertCertificado(signAvaliacao, {
          formularioId: formularioAtendimento.formulario.id,
          atendimentoId: formularioAtendimento.atendimento.id,
        })
        if (isConfirmed)
          showAlert({
            title: 'Sucesso!',
            text: 'Avaliação assinada.',
            icon: 'success',
          })
      } catch (error) {
        const err = error as AxiosError
        showAlert({
          title: 'Erro ao assinar!',
          text: err.response?.data.data.message,
          icon: 'error',
        })
      }
    } else {
      showAlert({
        title: 'Ausência de certificado!',
        text: 'Informe primeiramente o seu certificado na edição do perfil.',
        icon: 'error',
      })
    }
  }

  const showAvaliacao = () => {
    getFormularioAtendimento(
      formularioAtendimento.atendimento.id,
      formularioAtendimento.formulario.id
    ).then((data) => {
      showModal({
        title: `Avaliação de ${formularioAtendimento.paciente.nome}`,
        content: <FormularioModal data={data.respostas} />,
      })
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        {isMedico ? (
          <div className='me-2'>
            <Button
              className='btn-secondary btn-sm me-2'
              onClick={handleAssinarAvaliacao}
              icon={IconPaths.Key}
              iconSize={IconSize.Small}
            >
              Assinar
            </Button>
          </div>
        ) : null}

        {isMedico && formularioAtendimento.situacao !== FormularioSituacao.Assinado ? (
          <Button
            className='btn-secondary btn-sm'
            onClick={showAvaliacao}
            icon={IconPaths.Search}
            iconSize={IconSize.Small}
          >
            Visualizar
          </Button>
        ) : null}
      </div>
    </>
  )
}
