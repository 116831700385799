import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { useQueryResponse } from '../especialidades-list/core/QueryResponseProvider'
import { createEspecialidade, editEspecialidade } from '../especialidades-list/core/_requests'
import { EspecialidadeView } from '../especialidades-list/core/_models'
import {
  ButtonType,
  OptionsHelper,
  showAlert,
  showError,
  Tipo,
} from '../../../../../_metronic/helpers'
import { Button, CustomSelect, Input, Label, ToggleInput, useModal } from '../../../../components'
import { especialidadeSchema } from './validation/especialidadeSchema'

interface EspecialidadeEditModalFormProps {
  especialidade?: EspecialidadeView
}

const EspecialidadeEditModalForm: React.FC<EspecialidadeEditModalFormProps> = ({
  especialidade,
}) => {
  const { closeModal } = useModal()
  const { refetch } = useQueryResponse()

  const formik = useFormik({
    initialValues: {
      nome: especialidade?.nome || '',
      assinaturaPacienteObrigatoria: especialidade?.assinaturaPacienteObrigatoria || false,
      tiposAtendimento: especialidade?.tiposAtendimentoMap
        ? OptionsHelper.createOptions(especialidade.tiposAtendimentoMap, 'descricao', 'name')
        : [],
    },
    validationSchema: especialidadeSchema,

    onSubmit: async (values) => {
      const data = {
        ...values,
        tiposAtendimento: values.tiposAtendimento.map((option) => option.value) as Tipo[],
      }

      try {
        if (especialidade?.id) {
          await editEspecialidade({ ...data, id: especialidade.id })
          showAlert({
            title: 'Sucesso!',
            text: 'Especialidade editada com sucesso!',
            icon: 'success',
          })
        } else {
          await createEspecialidade(data)
          showAlert({
            title: 'Sucesso!',
            text: 'Especialidade cadastrada com sucesso!',
            icon: 'success',
          })
        }
        refetch()
      } catch (error: any) {
        showError(error)
      } finally {
        refetch()
        closeModal()
      }
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className='pb-4'>
        <Label className='pb-2'>Nome da Especialidade</Label>
        <Input
          placeholder='Digite o nome da especialidade'
          {...formik.getFieldProps('nome')}
          isInvalid={!!formik.errors.nome && formik.touched.nome}
          isValid={formik.touched.nome && !formik.errors.nome}
        />

        <Form.Control.Feedback type='invalid'>{formik.errors.nome}</Form.Control.Feedback>
      </div>
      <div className='pb-4'>
        <Label className='pb-2'>Tipo de Atendimento</Label>
        <CustomSelect
          isMulti
          placeholder='Selecione um ou mais tipos de atendimento'
          onChange={(values) => formik.setFieldValue('tiposAtendimento', values)}
          fetchOptions={OptionsHelper.getOptionsTiposAtendimento}
          value={formik.values.tiposAtendimento}
        />
        {formik.errors.tiposAtendimento && formik.touched.tiposAtendimento && (
          <div className='invalid-feedback d-block'>{formik.errors.tiposAtendimento as string}</div>
        )}
      </div>

      <div className='d-flex flex-end align-items-center pt-5'>
        <Label className='pe-2'>Requer Assinatura do Paciente</Label>
        <ToggleInput
          onChange={(value) => formik.setFieldValue('assinaturaPacienteObrigatoria', value)}
          checked={formik.values.assinaturaPacienteObrigatoria}
        />
      </div>

      <div className='d-flex flex-end pt-5'>
        <Button className='btn-secondary me-2' type={ButtonType.Reset} onClick={closeModal}>
          Cancelar
        </Button>
        <Button className='btn-primary' type={ButtonType.Submit}>
          Salvar
        </Button>
      </div>
    </Form>
  )
}

export default EspecialidadeEditModalForm
