import { KTCard } from '../../../../_metronic/helpers'
import { EspecialistasListHeader } from './component/header/EspecialistasListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { EspecialistasTable } from './table/EspecialistasTable'

const EspecialistasList = () => {
  return (
    <KTCard>
      <EspecialistasListHeader />
      <EspecialistasTable />
    </KTCard>
  )
}

const EspecialistasListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EspecialistasList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { EspecialistasListWrapper }
