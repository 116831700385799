/* eslint-disable react/jsx-no-target-blank */

import { Modal } from 'react-bootstrap'
import { useState } from 'react'

import { AtendimentoView } from '../../../atendimento/core/_models'
import { IconPaths, IconSize, KTSVG, ModalSize, Tipo } from '../../../../../../_metronic/helpers'
import { Cronometro } from '../../../atendimento/cronometro/Cronometro'
import { differenceInSeconds } from 'date-fns'
import {
  alteraAtendimento,
  getAtendimentoById,
  getFormularioAtendimento,
} from '../../../atendimento/core/_requests'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../auth'
import { useNavigate } from 'react-router-dom'
import { Anamnese } from '../Anamnese'
import { Button, useModal } from '../../../../../components'
import {
  AnotacaoModal,
  DiagnosticoIAModal,
  MarcarFaltaModal,
  ResultadoIAModal,
  ResultJSON,
} from '../../atendimentos-medico-list/component/modal'
import { DispositivosModal, FormCIDModal } from '../modal'
import { FormularioModal } from '../modal/formulario-answer/FormularioModal'
import { Aso } from '../../../atendimento-ASO/aso-list/component/modal/Aso'
import { ExamesModal } from '../../../atendimento-ASO/components/modal'

type Props = {
  atendimento: AtendimentoView
}
export const AsideMenuAtendimentoMain: React.FC<Props> = ({ atendimento }) => {
  const { showModal } = useModal()

  const [atendimentoEx, setAtendimentoEx] = useState<AtendimentoView | undefined>(atendimento)

  const { currentUser } = useAuth()

  const navigate = useNavigate()
  const isMedico = currentUser?.vinculos?.some((vinculo) => vinculo?.cargo === 'MEDICO')

  const [answered, setOnAnswered] = useState(false)
  const [jsonData, setJsonData] = useState<ResultJSON | undefined>()

  const handleAnswered = () => setOnAnswered(true)

  const [copy, setCopy] = useState(false)

  const [showExame, setShowExame] = useState(false)
  const handleCloseExame = () => setShowExame(false)
  const handleShowExame = () => setShowExame(true)

  const dataSolicitacao = new Date(String(atendimento.inicio))
  const dataAtual = new Date()
  const diffSecons = differenceInSeconds(dataAtual, dataSolicitacao)
  dataAtual.setSeconds(dataAtual.getSeconds() + diffSecons)
  const API_URL = 'https://one.lauduz.com'
  const url = `${API_URL}/tele-consulta/${atendimento?.protocolo}`

  const mensagem = `
  https://api.whatsapp.com/send?phone=+55${String(atendimento?.paciente?.pessoa?.telefone).replace(
    /[-()\s]/g,
    ''
  )}
  &text=Olá, estou entrando em contato sobre o seu atendimento. 
  Link da chamada: ${url}
`

  const showInformacoesDispositivo = () =>
    showModal({
      title: 'Informações dos dispositivos',
      content: <DispositivosModal />,
      fetchers: {
        atendimento: () => getAtendimentoById(atendimento.id),
      },
    })

  const showCID = () =>
    showModal({
      title: 'CID Atendimento',
      content: <FormCIDModal />,
      size: ModalSize.Medium,
      fetchers: {
        atendimento: () => getAtendimentoById(atendimento.id),
      },
    })

  const showAnamnese = () =>
    showModal({
      title: 'Anamnese',
      content: <Anamnese />,
      size: ModalSize.Medium,
      fetchers: {
        atendimento: () => getAtendimentoById(atendimento.id),
      },
    })

  const showAso = () =>
    showModal({
      title: 'Emitir ASO',
      content: <Aso id={atendimento?.id} />,
      size: ModalSize.ExtraLarge,
    })

  const showFormularioResposta = () => {
    if (atendimento.formularios && atendimento.formularios.length > 0) {
      const { nome, id: formId } = atendimento.formularios[0].formulario

      showModal({
        title: `${nome}`,
        content: <FormularioModal onAnswered={handleAnswered} />,
        fetchers: {
          formularioAtendimento: () => getFormularioAtendimento(atendimento.id, formId),
        },
      })
    }
  }

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url)
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 3000)
  }

  const defineAtendimento = () => {
    getAtendimentoById(atendimento?.id).then((a) => {
      setAtendimentoEx(a)
    })
  }

  function redirectHistorico() {
    window.open(
      '/apps/atendimentosMedicos/historicoClinico?paciente=' + atendimento.paciente?.id,
      '_blank',
      'noreferrer'
    )
  }

  function redirectPrescricao() {
    window.open(`/apps/atendimentosMedicos/prescricao/${atendimento.id}`, '_blank', 'noreferrer')
  }

  function calcularIdade(dataNascimento: string) {
    const hoje = new Date()
    const nascimento = new Date(dataNascimento)
    let idade = hoje.getFullYear() - nascimento.getFullYear()
    const mesAtual = hoje.getMonth()
    const mesNascimento = nascimento.getMonth()

    if (
      mesAtual < mesNascimento ||
      (mesAtual === mesNascimento && hoje.getDate() < nascimento.getDate())
    ) {
      idade--
    }

    return idade
  }

  function finalizarAtendimento() {
    alteraAtendimento(Number(atendimento.id), 3, Number(currentUser?.pessoa?.id), true, null)
      .then((result) => {
        Swal.fire({
          text: 'Atendimento Finalizado',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          navigate('/apps/atendimentosMedicos/lista')
        })
      })
      .catch((error) => {
        Swal.fire({
          text: error.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const showFaltouAtendimento = () => {
    showModal({
      title: `Marcar falta do paciente ao atendimento de ${atendimento.paciente?.pessoa?.nome}?`,
      content: <MarcarFaltaModal atendimento={atendimento} />,
      size: ModalSize.Medium,
    })
  }

  const showAnotacaoModal = () =>
    showModal({
      title: 'Adicionar Pendência',
      content: <AnotacaoModal isRequired={true} atendimentoId={atendimento.id} />,
    })

  const showIaDiagnostics = () => {
    if (atendimento.formularios && atendimento.formularios.length > 0) {
      const { id: formId } = atendimento.formularios[0].formulario
      showModal({
        title: 'Gerar Análise com IA',
        content: <DiagnosticoIAModal onClose={setJsonData} />,
        fetchers: {
          atendimento: () => getAtendimentoById(atendimento.id),
          formularioAtendimento: () => getFormularioAtendimento(atendimento.id, formId),
        },
      })
    } else {
      showModal({
        title: 'Gerar Análise com IA',
        content: <DiagnosticoIAModal onClose={setJsonData} />,
        fetchers: {
          atendimento: () => getAtendimentoById(atendimento.id),
        },
      })
    }
  }

  const showIaResults = () => {
    showModal({
      title: 'Análise da IA',
      content: <ResultadoIAModal resultData={jsonData} />,
      size: ModalSize.ExtraLarge,
    })
  }

  return (
    <>
      <div className='menu-item'>
        {atendimento.id !== -1 ? (
          <Cronometro expiryTimestamp={dataAtual} />
        ) : (
          <>Sem Informações sobre a data de solicitação</>
        )}
        <div className='d-flex flex-column'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_connected_accounts'
            aria-expanded='true'
            aria-controls='kt_account_connected_accounts'
          >
            <div className='card-title mt-3'>
              <div className='fw-bold text-muted'>{atendimento.protocolo}</div>
            </div>
            <div className='card-title m-1 mt-3'>
              <div className='fw-bold text-muted'>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/general/view2.svg' className='svg-icon-2' />
                </span>
                <span className='ms-2'>Nome Paciente</span>
              </div>
              <div className='fw-bolder d-flex align-items-center fs-5 mt-3'>
                {atendimento.paciente?.nome}
              </div>
            </div>
          </div>

          <div id='kt_account_connected_accounts' className='collapse'>
            <div className='fw-bold text-muted'>CPF</div>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {atendimento.paciente?.pessoa?.cpf}
            </div>
            <div className='fw-bold text-muted'>Sexo</div>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {atendimento.paciente?.pessoa?.sexo}
            </div>
            <div className='fw-bold text-muted'>Data Nascimento</div>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {atendimento?.paciente?.pessoa?.dataNascimentoFormatada} (
              {calcularIdade(atendimento?.paciente?.pessoa?.dataNascimento as string)} anos)
            </div>
          </div>
        </div>
      </div>
      <div className='menu-item '>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={showInformacoesDispositivo}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Verificar Dispositivos
        </Button>
      </div>
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={redirectHistorico}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Histórico Clínico
        </Button>
      </div>
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={showCID}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          CID
        </Button>
      </div>
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={redirectPrescricao}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Prescrição
        </Button>
      </div>
      {atendimento.tipo === Tipo.ASO ? (
        <div className='menu-item'>
          <Button
            className='fw-bolder btn-sm fs-7'
            onClick={showAso}
            icon={IconPaths.NewFile}
            iconSize={IconSize.Small}
          >
            Emitir ASO
          </Button>
        </div>
      ) : (
        <>
          <div className='menu-item'>
            <Button
              className='fw-bolder btn-sm fs-7'
              onClick={handleShowExame}
              icon={IconPaths.NewFile}
              iconSize={IconSize.Small}
            >
              Exames
            </Button>
          </div>
        </>
      )}
      <div className='menu-item'>
        <Button
          className='fw-bolder btn-sm fs-7'
          onClick={showAnamnese}
          icon={IconPaths.NewFile}
          iconSize={IconSize.Small}
        >
          Ver anamnese
        </Button>
      </div>
      <div className='d-flex flex-column mt-4'>
        {jsonData && (
          <Button className='btn-secondary mb-2' onClick={showIaResults}>
            Resultado IA
          </Button>
        )}
        <Button className='btn-info active mb-2' onClick={showIaDiagnostics}>
          Gerar análise com IA
        </Button>
        <Button className='btn-primary mb-2' onClick={showAnotacaoModal}>
          Adicionar Pendência
        </Button>
        {atendimento.formularios && atendimento.formularios?.length > 0 ? (
          <Button className='btn-secondary mb-2' onClick={showFormularioResposta}>
            {answered ? 'Alterar Avaliação' : 'Preencher Avaliação'}
          </Button>
        ) : null}

        <Button className='btn-primary mb-2' onClick={handleCopyUrl}>
          URL da Chamada
        </Button>
        {copy && <p className='text-success'>Link copiado com sucesso</p>}
        {!!atendimento?.paciente?.pessoa?.telefone && (
          <a href={mensagem} target='_blank' className='btn btn-success mb-2'>
            Enviar Mensagem no WhatsApp
          </a>
        )}
        {isMedico && (
          <button
            type='button'
            className='btn btn-secondary fw-bold fs-6 mb-2'
            onClick={showFaltouAtendimento}
          >
            Marcar falta paciente
          </button>
        )}
        {isMedico && (
          <button
            type='button'
            className='btn btn-danger fw-bold fs-6 mb-2'
            onClick={finalizarAtendimento}
          >
            Finalizar Atendimento
          </button>
        )}
      </div>
      {atendimento?.id && (
        <>
          <Modal className='modal-xl' show={showExame} onHide={handleCloseExame}>
            <Modal.Header closeButton>
              <Modal.Title>Exames</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ExamesModal defineAtendimento={defineAtendimento} atendimento={atendimento} />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
