import { KTCard } from '../../../../../_metronic/helpers'
import { SetorClienteListHeader } from './components/header/SetorClienteListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { SetorClienteTable } from './table/SetorClienteTable'

const SetorClienteList = () => {
  return (
    <>
      <KTCard>
        <SetorClienteListHeader />
        <SetorClienteTable />
      </KTCard>
    </>
  )
}

const SetorClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SetorClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { SetorClienteListWrapper }
