import { KTCard } from '../../../../../_metronic/helpers'
import { ExameClienteListHeader } from './components/header/ExameClienteListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ExameClienteTable } from './table/ExameClienteTable'

const ExameClienteList = () => {
  return (
    <>
      <KTCard>
        <ExameClienteListHeader />
        <ExameClienteTable />
      </KTCard>
    </>
  )
}

const ExameClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ExameClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ExameClienteListWrapper }
