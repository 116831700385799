import React, { FC, useMemo } from 'react'
import { PropsWithChildren } from 'react'
import { HeaderProps } from 'react-table'
import clsx from 'clsx'
import { initialQueryState } from '../../../_metronic/helpers'

type Props<T extends object> = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<T>>
  useQueryRequest: () => {
    state: { sort?: string; order?: 'asc' | 'desc' }
    updateState: (newState: any) => void
  }
  isAbleToSort?: boolean
}

export const CustomHeader = <T extends object>({
  className,
  title,
  tableProps,
  useQueryRequest,
  isAbleToSort = true,
}: Props<T>) => {
  const id = tableProps.column.id
  const { state, updateState } = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])

  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    if (id === 'actions' || id === 'selection' || !isAbleToSort) {
      return
    }

    if (!isSelectedForSorting) {
      updateState({ sort: id, order: 'asc', ...initialQueryState })
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        updateState({ sort: id, order: 'desc', ...initialQueryState })
        return
      }

      updateState({ sort: undefined, order: undefined, ...initialQueryState })
    }
  }

  return (
    <div
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`,
        id === 'actions' && 'text-end'
      )}
      style={{ cursor: id === 'actions' || !isAbleToSort ? 'default' : 'pointer' }}
      onClick={sortColumn}
    >
      <span>{title}</span>
    </div>
  )
}
