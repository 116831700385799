import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContextSemPaginacao,
  initialQueryResponseSemPaginacao,
  QUERIES,
  stringifyRequestQuerySemPaginacao,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { getAtendimentosRelatorio } from './_requests'
import { useQueryRequest } from './QueryRequestProvider'
import { AtendimentoReport } from '../../../meus-atendimentos/meus-atendimentos-list/core/_models'
import { useModal } from '../../../../../components'

const QueryResponseContext = createResponseContextSemPaginacao<AtendimentoReport>(
  initialQueryResponseSemPaginacao
)

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isOpen } = useModal()

  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuerySemPaginacao(state))
  const updatedQuery = useMemo(() => stringifyRequestQuerySemPaginacao(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}-${isOpen}`,
    () => {
      if (state.search !== '') {
        return getAtendimentosRelatorio(query)
      }
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: !isOpen }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = (): AtendimentoReport[] => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response || []
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export { useQueryResponseData, useQueryResponseLoading, QueryResponseProvider }
