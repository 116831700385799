import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { LogsConsultaListWrapper } from './logs-consulta-list/LogsConsultaList'
import { LogsUsuarioListWrapper } from './logs-usuario-list/LogsUsuarioList'
import { LogsConsultaIdListWrapper } from './logs-consulta-id/LogsConsultaIdList'
import { LogsUsuarioIdListWrapper } from './logs-usuario-id/LogsUsuarioIdList'

const consultaBreadcrumbs: Array<PageLink> = [
  {
    title: 'Atendimentos',
    path: '/apps/logs/consulta',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const usuarioBreadcrumbs: Array<PageLink> = [
  {
    title: 'Usuários',
    path: '/apps/logs/usuario',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LogsPage = () => {
  return (
    <Routes>
      <Route
        path='consulta'
        element={
          <>
            <PageTitle breadcrumbs={consultaBreadcrumbs}>Atendimentos</PageTitle>
            <LogsConsultaListWrapper />
          </>
        }
      />
      <Route
        path='consulta/:id'
        element={
          <>
            <PageTitle breadcrumbs={consultaBreadcrumbs}>Logs</PageTitle>
            <LogsConsultaIdListWrapper />
          </>
        }
      />
      <Route
        path='usuario'
        element={
          <>
            <PageTitle breadcrumbs={usuarioBreadcrumbs}>Usuários</PageTitle>
            <LogsUsuarioListWrapper />
          </>
        }
      />
      <Route
        path='usuario/:id'
        element={
          <>
            <PageTitle breadcrumbs={usuarioBreadcrumbs}>Logs</PageTitle>
            <LogsUsuarioIdListWrapper />
          </>
        }
      />

      <Route index element={<Navigate to='/apps/logs/consulta' />} />
    </Routes>
  )
}

export default LogsPage
