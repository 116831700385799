import { SelectionHeaderContainer } from '../../../../../../components'
import { useListView } from '../../../core/ListViewProvider'
import { AtendimentosListSearchComponent } from './AtendimentosListSearchComponent'
import { UsersListToolbar } from './UserListToolbar'
import { UsersListGrouping } from './UsersListGrouping'

const AtendimentosListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='border-0 pt-6'>
      <AtendimentosListSearchComponent />
      <div className='container mt-3'>
        <SelectionHeaderContainer selected={selected}>
          {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        </SelectionHeaderContainer>
      </div>
    </div>
  )
}

export { AtendimentosListHeader }
