import { Button, useModal } from '../../../../../../components'
import { AtendimentoFindEmpresa } from '../../../AtendimentoFindEmpresa'

const UsersListToolbar = () => {
  const { showModal } = useModal()
  const showNovoAtendimento = () =>
    showModal({
      title: 'Novo Atendimento',
      content: <AtendimentoFindEmpresa />,
    })

  return (
    <Button className='btn-primary' onClick={showNovoAtendimento}>
      Solicitar Atendimento
    </Button>
  )
}

export { UsersListToolbar }
