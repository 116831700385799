import { FC } from 'react'

type Props = {
  data: string | undefined
  inline?: boolean
}

const DataColumn: FC<Props> = ({ data, inline = false }) => {
  const formattedDate = data
    ? {
        date: new Date(data).toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        time: new Date(data).toLocaleTimeString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
      }
    : undefined

  return (
    <>
      {!formattedDate ? (
        'N/A'
      ) : !inline ? (
        <>
          <span>{formattedDate.date}</span>
          <br />
          <span>{formattedDate.time}</span>
        </>
      ) : (
        <>
          <span>
            {formattedDate.date} {formattedDate.time}
          </span>
        </>
      )}
    </>
  )
}

export { DataColumn }
