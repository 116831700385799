import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import {
  AvatarDto,
  EmpresasQueryResponse,
  EmpresaView,
  EnderecoList,
  EnderecoView,
  TipoEnderecoList,
} from './_models'
import { ArquivoDto } from '../../../atendimento/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const TIPO_ENDERECO_URL = `${API_URL}/endereco/tipos`
const EMPRESA_URL = `${API_URL}/empresas`
const GET_EMPRESAS_URL = `${API_URL}/empresas`

const GET_ASO_AUTENTICADO_URL = `${API_URL}/relatorio`

const getEmpresas = (query: string): Promise<EmpresasQueryResponse> => {
  return axios
    .get(`${GET_EMPRESAS_URL}?${query}`)
    .then((d: AxiosResponse<EmpresasQueryResponse>) => d.data)
}

const getEmpresaById = (id: ID): Promise<EmpresaView> => {
  return axios
    .get(`${EMPRESA_URL}/${id}`)
    .then((response: AxiosResponse<EmpresaView>) => response.data)
}

export const getEnderecoByIdEmpresa = (id: ID): Promise<EnderecoList[]> => {
  return axios
    .get(`${EMPRESA_URL}/endereco/${id}`)
    .then((response: AxiosResponse<EnderecoList[]>) => response.data)
}
export const getTiposEndereco = () => {
  return axios
    .get<TipoEnderecoList[]>(TIPO_ENDERECO_URL)
    .then((response: AxiosResponse<TipoEnderecoList[]>) => response.data)
}

const createEmpresa = (
  nome: string,
  nomeFantasia: string,
  cnpj: string,
  tiposAtendimento: String[],
  permiteNovoPaciente: boolean,
  grupoTelegram: string,
  logo: AvatarDto | undefined
): Promise<undefined> => {
  return axios
    .post(EMPRESA_URL, {
      nome,
      nomeFantasia,
      cnpj,
      permiteNovoPaciente: permiteNovoPaciente,
      tiposAtendimento,
      grupoTelegram,
      logo,
    })
    .then((response) => {
      return response.data
    })
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}

export const deleteSelectedEmpresaCliente = (empresaIds: Array<ID>): Promise<void> => {
  const requests = empresaIds.map((id) => axios.delete(`${EMPRESA_URL}/cliente/${id}`))
  return axios.all(requests).then(() => {})
}

export const deleteColaboradorCliente = (idEmpresaCliente: ID, idCoordenador: ID) => {
  return axios.delete(`${EMPRESA_URL}/cliente/colaborador/${idEmpresaCliente}`, {
    data: {
      id: idCoordenador,
    },
  })
}
export const getAsoAutenticado = (hashAuth: string) => {
  return axios
    .get(`${GET_ASO_AUTENTICADO_URL}/${hashAuth}`)
    .then((response: AxiosResponse<ArquivoDto>) => response.data)
}

/* export const vincularRiscosClienteAtendimento = (
  idAtendimento: ID,
  riscos: Array<RiscoCliente>
): Promise<void> => {
  const requests = riscos.map((risco) =>
    adicionaFatorRisco(idAtendimento, String(risco.fatorRisco), '0')
  )
  return axios.all(requests).then(() => {})
} */

export const createEnderecoEmpresa = (
  idEmpresa: ID,
  tipo: Number,
  cep: string,
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string,
  uf: string
): Promise<EnderecoView> => {
  return axios.post(`${EMPRESA_URL}/endereco/${idEmpresa}`, {
    tipo: tipo,
    cep: cep,
    logradouro: logradouro,
    numero: numero,
    complemento: complemento,
    bairro: bairro,
    cidade: cidade,
    uf: uf,
  })
}
export const updateEnderecoEmpresa = (
  idEmpresa: ID,
  idEndereco: Number,
  tipo: Number,
  cep: string,
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string,
  uf: string
): Promise<EnderecoView> => {
  return axios.put(`${EMPRESA_URL}/endereco/${idEmpresa}`, {
    id: idEndereco,
    tipo: tipo,
    cep: cep,
    logradouro: logradouro,
    numero: numero,
    complemento: complemento,
    bairro: bairro,
    cidade: cidade,
    uf: uf,
  })
}

const updateEmpresa = (
  idEmpresa: ID,
  nome: string,
  nomeFantasia: string,
  cnpj: string,
  tiposAtendimento: String[],
  permiteNovoPaciente: boolean,
  grupoTelegram: string,
  logo: AvatarDto | undefined
): Promise<undefined> => {
  return axios
    .put(EMPRESA_URL + '/' + idEmpresa, {
      id: idEmpresa,
      nome,
      nomeFantasia,
      cnpj,
      permiteNovoPaciente: permiteNovoPaciente,
      tiposAtendimento,
      grupoTelegram,
      logo,
    })
    .then((response) => {
      return response.data
    })
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}

const deleteEmpresa = (empresaId: ID): Promise<void> => {
  return axios.delete(`${EMPRESA_URL}/${empresaId}`)
}
export const inativarEmpresa = (idEmpresa: ID): Promise<undefined> => {
  return axios
    .put(EMPRESA_URL + '/' + idEmpresa, {
      ativa: false,
      id: idEmpresa,
    })
    .then((response) => {
      return response.data
    })
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}

export const ativarEmpresa = (idEmpresa: ID): Promise<undefined> => {
  return axios
    .put(EMPRESA_URL + '/' + idEmpresa, {
      ativa: true,
      id: idEmpresa,
    })
    .then((response) => {
      return response.data
    })
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}
const deleteSelectedEmpresa = (empresaIds: Array<ID>): Promise<void> => {
  const requests = empresaIds.map((id) => axios.delete(`${EMPRESA_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getLogo = (empresaId: ID): Promise<string> => {
  return axios
    .get(`${EMPRESA_URL}/logo/${empresaId}`)
    .then((resp: AxiosResponse<{ data: string }>) => resp.data.data)
}

export {
  getEmpresas,
  deleteEmpresa,
  deleteSelectedEmpresa,
  getEmpresaById,
  createEmpresa,
  updateEmpresa,
  getLogo,
}
