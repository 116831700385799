import { FC, useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { Button, FetchState, useModal } from '../../../../../../components'
import { EmpresaView } from '../../../../empresa/empresas-list/core/_models'
import { webhook } from '../../core/_requests'
import Swal from 'sweetalert2'

export const WebHookModal: FC = () => {
  const { fetchStates, closeModal } = useModal()

  const { data: empresa, isLoading } = (fetchStates['empresa'] || {}) as FetchState<EmpresaView>

  const [webhookUrl, setWebhookUrl] = useState<string>('')

  useEffect(() => setWebhookUrl(empresa?.apiWebhook || ''), [empresa])

  const handleWebhookSubmit = async () => {
    try {
      await webhook(empresa?.id, webhookUrl)
      window.location.reload()
      Swal.fire({
        text: 'WebHook configurado com sucesso!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      closeModal()
    } catch (error) {
      Swal.fire({
        text: 'Erro ao configurar WebHook.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <span className='indicator-progress' style={{ display: 'block' }}>
          Aguarde... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        <InputGroup>
          <FormControl
            type='text'
            placeholder='Insira a URL do WebHook'
            value={empresa?.apiWebhook}
            onChange={(e) => setWebhookUrl(e.target.value)}
          />
        </InputGroup>
      )}
      <div className='d-flex justify-content-center mt-5'>
        <Button className='btn-secondary me-3' onClick={closeModal}>
          Fechar
        </Button>
        <Button className='btn-primary' onClick={handleWebhookSubmit}>
          Enviar
        </Button>
      </div>
    </>
  )
}
