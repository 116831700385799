import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { GridCols, ID, Status, useDebounce } from '../../../_metronic/helpers'
import Select, {
  OnChangeValue,
  GroupBase,
  StylesConfig,
  MultiValue,
  SingleValue,
  components,
  MultiValueProps,
  MultiValueRemoveProps,
} from 'react-select'

export type IOption = {
  label: string
  value: ID | string | Status | boolean
}

type ICustomSelect = {
  onChange?: (value: any) => void // FIX type any
  onBlur?: (value: any) => void // FIX type any
  placeholder?: string
  label?: string
  value?: IOption | IOption[]
  isMulti?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  isClearable?: boolean
  customStyles?: StylesConfig<IOption>
  className?: string
  name?: string
  showMultiValueRemove?: boolean
  fetchOptions?: (inputValue: string, selectAll: boolean) => Promise<IOption[]>
  options?: IOption[]
}

export const CustomSelect: FC<ICustomSelect> = ({
  onChange,
  onBlur,
  placeholder,
  value,
  isMulti,
  customStyles,
  className,
  isDisabled,
  isRequired,
  isClearable,
  name: label,
  showMultiValueRemove,
  fetchOptions,
  options: syncOptions,
}) => {
  const [options, setOptions] = useState<IOption[]>([])
  const [extendedOptions, setExtendedOptions] = useState<IOption[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const debouncedInput = useDebounce(inputValue, 300) ?? ''

  const selectAllOption: IOption = {
    label: 'Selecionar Todos',
    value: 'selectAll',
  }

  const handleChange = async (selected: MultiValue<IOption> | SingleValue<IOption>) => {
    if (!isMulti) {
      onChange?.(selected)
      return
    }

    if (Array.isArray(selected) && selected.length === 0) {
      setSelectAll(false)
      if (onChange) onChange([])
      return
    }

    if (Array.isArray(selected) && selected.some((opt) => opt.value === 'selectAll')) {
      if (!selectAll) {
        try {
          setLoading(true)
          const allOptions = (await fetchOptions?.('', true)) || syncOptions
          setOptions(allOptions || [])
          setSelectAll(true)
          onChange?.(allOptions || [])
        } catch (error) {
          console.error('Erro ao pegar todas opcoes', error)
        } finally {
          setLoading(false)
        }
      }
    } else {
      setSelectAll(false)
      onChange?.(selected)
    }
  }

  const MultiValueRemove = (props: MultiValueRemoveProps<any, boolean>) => {
    return showMultiValueRemove ? <components.MultiValueRemove {...props} /> : null
  }

  useEffect(() => {
    setExtendedOptions(isMulti ? [selectAllOption, ...options] : [...options])
  }, [options])

  useEffect(() => {
    const loadOptions = async () => {
      if (fetchOptions && !syncOptions) {
        setLoading(true)
        try {
          const result = await fetchOptions(debouncedInput, selectAll)
          setOptions(result)
        } catch (error) {
          console.error('Error fetching options:', error)
        } finally {
          setLoading(false)
        }
      } else if (syncOptions) {
        setOptions(syncOptions)
      } else {
        setOptions([])
      }
    }

    loadOptions()
  }, [debouncedInput, fetchOptions, syncOptions, selectAll])

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue)
  }

  const customNoOptionsMessage = () => 'Sem Opções'

  return (
    <Select
      name={label}
      isClearable={isClearable}
      className={`${className}`}
      classNamePrefix='react-select'
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={onBlur}
      options={extendedOptions}
      value={value}
      isMulti={isMulti}
      isLoading={loading}
      isDisabled={isDisabled}
      onInputChange={handleInputChange}
      noOptionsMessage={customNoOptionsMessage}
      components={{
        MultiValueRemove,
      }}
      required={isRequired}
      styles={{
        control: (provided: any) => ({
          ...provided,
          width: '100%',
          height: '100%',
          border: 'none',
          padding: 4,
          backgroundColor: isDisabled ? 'var(--bs-gray-300)' : 'var(--bs-gray-100)',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          opacity: isDisabled ? 0.7 : 1,
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: 'var(--bs-gray-100)',
          zIndex: 9999,
          padding: 4,
        }),
        input: (provided: any) => ({
          ...provided,
          color: 'var(--bs-gray-700)',
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? 'var(--bs-secondary)'
            : state.isFocused
            ? 'var(--bs-gray-300)'
            : 'transparent',
          color: 'var(--bs-gray-700)',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }),
        singleValue: (provided: any) => ({
          ...provided,
          color: isDisabled ? 'var(--bs-gray-600)' : 'var(--bs-gray-700)',
        }),
        placeholder: (base: any) => ({
          ...base,
          color: isDisabled ? 'var(--bs-gray-600)' : 'var(--bs-gray-700)',
        }),

        ...customStyles,
      }}
    />
  )
}

CustomSelect.defaultProps = {
  onBlur: () => {},
  placeholder: 'Selecione...',
  label: 'Preencha props label',
  isMulti: false,
  isDisabled: false,
  isRequired: false,
  isClearable: true,
  showMultiValueRemove: true,
  className: '',
  name: '',
}
