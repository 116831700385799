import { useState, useEffect } from 'react'
import { initialQueryState, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { DateInput, Input, Label } from '../../../../../../components'

interface ISearch {
  protocolo?: string
  paciente?: string
  solicitacao?: string
  profissionalSaude?: string
}

const LogsConsultaListSearchComponent = () => {
  const { updateState } = useQueryRequest()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const [search, setSearch] = useState<ISearch>({
    protocolo: '',
    paciente: '',
    solicitacao: '',
    profissionalSaude: '',
  })

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const updateFilter = () => {
    const aux: string[] = []

    if (search?.protocolo) aux.push(`protocolo~*${search.protocolo}*`)
    if (search?.paciente) aux.push(`paciente.pessoa.nome~*${search.paciente}*`)
    if (search?.solicitacao) aux.push(`solicitacao>${search.solicitacao}T00:00:00`)
    if (search?.profissionalSaude) aux.push(`responsavel.pessoa.nome~*${search.profissionalSaude}*`)

    setSearchTerm(aux.filter(Boolean).join(','))
  }

  useEffect(() => {
    updateFilter()
  }, [search])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: searchTerm,
        sort: 'solicitacao',
        order: 'desc',
        ...initialQueryState,
      })
    }
  }, [searchTerm, debouncedSearchTerm])

  return (
    <div className='row mb-0'>
      <div className='col-lg-3'>
        <Label className='pb-2'>Protocolo</Label>
        <Input
          placeholder='Digite o Protocolo'
          value={search?.protocolo}
          onChange={(e) => setSearch((prev) => ({ ...prev, protocolo: e.target.value }))}
        />
      </div>
      <div className='col-lg-3'>
        <Label className='pb-2'>Paciente</Label>
        <Input
          placeholder='Digite o Paciente'
          value={search?.paciente}
          onChange={(e) => setSearch((prev) => ({ ...prev, paciente: e.target.value }))}
        />
      </div>
      <div className='col-lg-3'>
        <Label className='pb-2'>Responsável</Label>
        <Input
          placeholder='Digite o Responsável'
          value={search?.profissionalSaude}
          onChange={(e) => setSearch((prev) => ({ ...prev, profissionalSaude: e.target.value }))}
        />
      </div>
      <div className='col-lg-3'>
        <Label className='pb-2'>Solicitação</Label>
        <DateInput
          placeholder='Selecione'
          value={search?.solicitacao}
          onChange={(e) => setSearch((prev) => ({ ...prev, solicitacao: e.target.value }))}
        />
      </div>
    </div>
  )
}

export { LogsConsultaListSearchComponent }
