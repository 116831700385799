/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { NPSType } from '../../../_metronic/helpers'

type Props = {
  series: Record<NPSType, number>
  label: string
}

const PieChart: React.FC<Props> = ({ series, label }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series, label])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const isEmpty = Object.values(series).every((value) => value === 0)

    const chart = new ApexCharts(chartRef.current, {
      chart: {
        type: 'pie',
      },
      label,
      labels: isEmpty ? [] : [NPSType.Promotores, NPSType.Neutros, NPSType.Detratores],
      series: isEmpty
        ? []
        : [series[NPSType.Promotores], series[NPSType.Neutros], series[NPSType.Detratores]],
      colors: ['#4caf50', '#808080', '#D30000'],
      noData: {
        text: 'Sem dados disponíveis',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: '#808080',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
    })
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <>
      <div className='d-flex flex-column text-center'>
        <h3>{label}</h3>
      </div>
      <div ref={chartRef} id={`kt_charts_pie_chart-${label}`} />
    </>
  )
}

export { PieChart }
