import { Column } from 'react-table'
import { EspecialistasActionsCell } from './EspecialistasActionsCell'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { PessoaList } from '../../../../auth'
import { CustomHeader } from '../../../../../components'

const EspecialistasColumns: ReadonlyArray<Column<PessoaList>> = [
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Nome' />
    ),
    id: 'pessoa.nome',
    accessor: (row) => row.nome,
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='CPF' />
    ),
    id: 'pessoa.cpf',
    accessor: (row) => row.cpf,
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Cargo' />
    ),
    id: 'cargo',
    accessor: () => 'Profissional de Saúde',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <EspecialistasActionsCell pessoaId={props.row.original.id} />,
  },
]

export { EspecialistasColumns }
