import { FC, useEffect, useState } from 'react'
import { AtendimentoView, ProcedimentoAtendimentoView } from '../../../atendimento/core/_models'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ButtonType,
  ID,
  KTSVG,
  getArquivoAnexo,
  onDeleteAlert,
  showAlert,
  toAbsoluteUrl,
} from '../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import {
  adicionarProcedimento,
  deleteProcedimento,
  editarProcedimento,
  getAtendimentoById,
} from '../../../atendimento/core/_requests'
import { Spinner } from 'react-bootstrap'
import {
  Button,
  DateInput,
  Input,
  Label,
  LoadingIndicator,
  useModal,
} from '../../../../../components'
import {
  AnexoDto,
  AtendimentoDtoProcedimento,
  AtendimentoDtoProcedimentoUpdate,
} from '../../../atendimento_medicos/atendimentos-medico-list/core/_models'

type Props = {
  atendimento: AtendimentoView | undefined
  defineAtendimento?: Function
}

export const ExamesModal: FC<Props> = ({ atendimento, defineAtendimento }) => {
  const { closeModal } = useModal()

  const [loading, setLoading] = useState(false)
  const [procedimento, setProcedimento] = useState<ProcedimentoAtendimentoView | undefined>(
    undefined
  )
  const [atendimentoProcedimentos, setAtendimentoProcedimentos] = useState<AtendimentoView>()
  const [anexo, setAnexo] = useState<AnexoDto | undefined>()

  const [isLoading, setIsLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const [arquivo, setArquivo] = useState(edit)

  const procedimentoMedicoSchema = Yup.object().shape({
    data: Yup.string().nullable().required('Data do exame é obrigatória'),
    nome: Yup.string().required('Nome é obrigatório'),
    descricao: Yup.string().nullable(),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nome: procedimento?.nome || '',
      data: procedimento?.data || '',
      descricao: procedimento?.descricao || '',
    },
    validationSchema: procedimentoMedicoSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      setLoading(true)
      try {
        if (procedimento?.id) {
          const proc: AtendimentoDtoProcedimentoUpdate = {
            ...values,
            id: procedimento.id,
            anexo,
          }
          await editarProcedimento(atendimento?.id, proc)
          showAlert({
            title: 'Sucesso!',
            text: 'Procedimento editado com sucesso!',
            icon: 'success',
          })
        } else {
          const proc: AtendimentoDtoProcedimento = {
            ...values,
            id: null,
            anexo,
          }
          await adicionarProcedimento(atendimento?.id, proc)
          showAlert({
            title: 'Sucesso!',
            text: 'Procedimento adicionado com sucesso!',
            icon: 'success',
          })
        }

        defineAtendimento?.(atendimento?.id)
      } catch (ex: any) {
        const errors = ex.response?.data?.data?.errors || {}
        setFieldError('data', errors['data']?.[0] || '')
        setFieldError('descricao', errors['descricao']?.[0] || '')
        setFieldError('nome', errors['nome']?.[0] || '')

        showAlert({
          title: 'Erro!',
          text: ex.response?.data.data.message || 'Erro ao editar procedimento',
          icon: 'error',
        })
      } finally {
        formik.resetForm()
        setAnexo(undefined)
        setProcedimento(undefined)
        setSubmitting(false)
        setLoading(false)
        defineAtendimento?.(atendimento?.id)
        getAtendimento()
      }
    },
  })

  const editProcedimento = (id: ID) => {
    setEdit(true)
    const procedimentoEdit = atendimentoProcedimentos?.procedimentos?.find((p) => p.id === id)

    if (procedimentoEdit) {
      setProcedimento(procedimentoEdit)
      formik.setValues({
        nome: procedimentoEdit.nome as string,
        data: procedimentoEdit.data as string,
        descricao: procedimentoEdit.descricao as string,
      })
      defineAtendimento?.()
    }
  }

  const excluiProcedimento = async (id: ID) => {
    try {
      const isConfirmed = await onDeleteAlert({
        onAction: () => deleteProcedimento(atendimento?.id, id),
      })
      if (isConfirmed) {
        showAlert({
          title: 'Sucesso!',
          text: 'Avaliação assinada.',
          icon: 'success',
        })
        getAtendimento()
      }
    } catch (error: any) {
      await showAlert({
        title: 'Erro!',
        text: 'Não foi possível excluir! ' + error.response?.data.data.message,
        icon: 'success',
      })
      defineAtendimento?.()
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      const fileData = await getFileObject(selectedFile)
      setAnexo(fileData)
    }
  }

  const getFileObject = (
    file: File
  ): Promise<{ fileContent: string; fileName: string; fileType: string }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const fileContent = reader.result as string
        resolve({
          fileContent: fileContent.split(',')[1],
          fileName: file.name,
          fileType: file.type,
        })
      }
      reader.onerror = () => reject(reader.error)
      reader.readAsDataURL(file)
    })
  }

  const getAtendimento = () => {
    getAtendimentoById(atendimento?.id)
      .then((data) => setAtendimentoProcedimentos(data))
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getAtendimento()
  }, [atendimento])

  return (
    <>
      {isLoading ? (
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Carregando...</span>
        </Spinner>
      ) : (
        <>
          {atendimentoProcedimentos?.procedimentos &&
            atendimentoProcedimentos?.procedimentos.length > 0 && (
              <>
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-1 text-dark'>Exames Realizados</span>
                  </h3>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-5'>
                      <thead>
                        <tr>
                          <th className='p-0 w-50px'></th>
                          <th className='p-0 min-w-100px'></th>
                          <th className='p-0 min-w-100px'></th>
                          <th className='p-0 min-w-100px'></th>
                          <th className='p-0 min-w-40px'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {atendimentoProcedimentos.procedimentos.map((p) => (
                          <tr key={p.id}>
                            <th>
                              <div className='symbol symbol-50px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/general/document.png')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </th>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {p.nome}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>{p.data}</span>
                            </td>
                            <td>
                              <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                  <span className='text-bold me-2 fs-7 fw-semibold'>Descrição</span>
                                </div>
                                <div className=' w-100'>
                                  <span className='text-muted me-2 fs-7 fw-semibold'>
                                    {p.descricao || '<Não informado>'}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                  <span className='text-bold me-2 fs-7 fw-semibold'>Arquivo</span>
                                </div>
                                <div className=' w-100'>
                                  {atendimentoProcedimentos.anexos
                                    ?.filter((a) => a.id === p.anexo)
                                    .map((j) => (
                                      <button
                                        key={j.id}
                                        className='btn btn-light btn-active-light-primary btn-sm'
                                        onClick={() =>
                                          getArquivoAnexo(atendimentoProcedimentos.id, j.id)
                                        }
                                      >
                                        <span className='indicator-label'>{j.url}</span>
                                        <KTSVG
                                          path='/media/icons/duotune/files/fil021.svg'
                                          className='svg-icon-5 m-0'
                                        />
                                      </button>
                                    ))}
                                </div>
                              </div>
                            </td>
                            <td className='text-end'>
                              <button
                                className='btn btn-sm btn-light btn-color-muted btn-active-light-warning'
                                onClick={() => editProcedimento(p.id)}
                              >
                                <span
                                  className='menu-icon d-flex align-items-center'
                                  data-kt-element='icon'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen055.svg'
                                    className='svg-icon-3'
                                  />
                                </span>
                              </button>
                              <button
                                className='btn btn-sm btn-light btn-color-muted btn-active-light-danger'
                                onClick={() => excluiProcedimento(p.id)}
                              >
                                <span
                                  className='menu-icon d-flex align-items-center'
                                  data-kt-element='icon'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen040.svg'
                                    className='svg-icon-3'
                                  />
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          <form
            id='kt_modal_add_paciente_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='d-flex flex-column  me-n7 pe-7' id='kt_modal_add_paciente_scroll'>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Dados do Exame</label>
              </div>
              <div className='fv-row mb-7'>
                <Input
                  {...formik.getFieldProps('nome')}
                  placeholder='Nome'
                  isInvalid={!!(formik.touched.nome && formik.errors.nome)}
                  isValid={formik.touched.nome && !formik.errors.nome}
                  disabled={formik.isSubmitting || loading}
                  errorMessage={formik.errors.nome}
                />
              </div>
              <div className='fv-row mb-7'>
                <Input
                  {...formik.getFieldProps('descricao')}
                  placeholder='Descrição'
                  isInvalid={!!(formik.touched.descricao && formik.errors.descricao)}
                  isValid={formik.touched.descricao && !formik.errors.descricao}
                  disabled={formik.isSubmitting || loading}
                  errorMessage={formik.errors.descricao}
                />
              </div>
              <div className='fv-row mb-7'>
                <DateInput
                  {...formik.getFieldProps('data')}
                  hasMax={true}
                  errorMessage={formik.errors.data}
                />
              </div>
              {(arquivo || !edit) && (
                <div className='fv-row mb-7'>
                  <input
                    name='file'
                    type='file'
                    onChange={handleFileChange}
                    className='form-control form-control-solid'
                  />
                </div>
              )}
              {edit && !arquivo && (
                <div className='d-flex mb-3'>
                  <Button className='btn-primary' onClick={() => setArquivo(true)}>
                    Alterar arquivo
                  </Button>
                </div>
              )}
            </div>
            <div className='d-flex justify-content-center'>
              <Button className='btn-secondary me-2' onClick={closeModal}>
                Fechar
              </Button>
              <Button
                type={ButtonType.Submit}
                className='btn-primary'
                disabled={loading || formik.isSubmitting}
              >
                {formik.isSubmitting ? <LoadingIndicator /> : <Label>Enviar</Label>}
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  )
}
