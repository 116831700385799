import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { ModalSize } from '../../../_metronic/helpers'

export type FetchState<T = any> = {
  data: T | undefined
  isLoading: boolean
  error: any
  fetcher?: () => Promise<T>
}

type ModalContextType = {
  isOpen: boolean
  showModal: (data: IModal) => void
  closeModal: () => void
  modalContent: ReactNode | null
  modalSize?: ModalSize
  modalTitle: string
  modalOnHide?: () => void
  setModalOnHide?: Dispatch<SetStateAction<(() => void) | undefined>>
  fetchStates: { [key: string]: FetchState }
  refetch: (key: string) => void
}

export type IModal = {
  title: string
  content?: ReactNode
  size?: ModalSize
  fetchers?: { [key: string]: () => Promise<any> }
  onClose?: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)
export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ReactNode | null>(null)
  const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Large)
  const [modalTitle, setModalTitle] = useState<string>('')
  const [modalOnHide, setModalOnHide] = useState<(() => void) | undefined>(undefined)

  const [fetchStates, setFetchStates] = useState<{ [key: string]: FetchState }>({})

  const fetchData = async (key: string, fetcher: () => Promise<any>) => {
    setFetchStates((prev) => ({
      ...prev,
      [key]: { ...prev[key], isLoading: true, data: undefined, error: undefined, fetcher },
    }))

    try {
      const data = await fetcher()
      setFetchStates((prev) => ({
        ...prev,
        [key]: { ...prev[key], isLoading: false, data, error: undefined, fetcher },
      }))
    } catch (error) {
      setFetchStates((prev) => ({
        ...prev,
        [key]: { ...prev[key], isLoading: false, data: undefined, error, fetcher },
      }))
    }
  }

  const refetch = (key: string) => {
    const fetcher = fetchStates[key]?.fetcher
    if (fetcher) {
      fetchData(key, fetcher)
    }
  }

  const showModal = async ({ title, content, size, fetchers, onClose }: IModal) => {
    setModalTitle(title)
    if (size) setModalSize(size)
    setModalOnHide(() => onClose || undefined)
    setModalContent(content || null)

    if (fetchers) {
      const fetchPromises = Object.entries(fetchers).map(([key, fetcher]) =>
        fetchData(key, fetcher)
      )
      await Promise.all(fetchPromises)
    }

    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    modalOnHide?.()

    setTimeout(() => {
      setModalOnHide(undefined)
      setFetchStates({})
      setModalSize(ModalSize.Large)
      setModalContent(null)
    }, 300)
  }

  const contextValue = useMemo(
    () => ({
      isOpen,
      showModal,
      closeModal,
      modalContent,
      modalSize,
      modalTitle,
      fetchStates,
      refetch,
    }),
    [isOpen, modalContent, modalSize, modalTitle, fetchStates]
  )

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
}
export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
