import { Column } from 'react-table'
import { UsuarioList } from '../../../../../auth'
import { LogsUsuarioActionsCell } from './LogsUsuarioActionsCell'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomHeader } from '../../../../../../components'

const LogsUsuarioColumns: ReadonlyArray<Column<UsuarioList>> = [
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Nome' />
    ),
    id: 'pessoa.nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader useQueryRequest={useQueryRequest} tableProps={props} title='Ações' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <LogsUsuarioActionsCell usuarioId={props.row.original.id} />,
  },
]

export { LogsUsuarioColumns }
