import { IconPaths, ModalSize } from '../../../../../../../_metronic/helpers'
import { Button, useModal } from '../../../../../../components'
import { EmpresaClienteEditModalForm } from '../../empresa-cliente-edit-modal/EmpresaClienteEditModalForm'

const EmpresasClienteListToolbar = () => {
  const { showModal } = useModal()

  const openAddEmpresa = () => {
    showModal({
      title: 'Empregador ASO',
      content: <EmpresaClienteEditModalForm />,
      size: ModalSize.Medium,
    })
  }

  return (
    <Button className='btn-primary' icon={IconPaths.Add} onClick={openAddEmpresa}>
      Adicionar
    </Button>
  )
}

export { EmpresasClienteListToolbar }
