import { Column } from 'react-table'
import { RiscoActionsCell } from './RiscoActionsCell'
import { RiscoAsoView } from '../../core/_models'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useListView } from '../../core/ListViewProvider'
import {
  SelectionHeader,
  SelectionCell,
  CustomHeader,
  DataColumn,
} from '../../../../../../components'

const RiscoColumns: ReadonlyArray<Column<RiscoAsoView>> = [
  {
    Header: (props) => <SelectionHeader useListView={useListView} tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell useListView={useListView} id={props.row.original.id} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Risco'
        className='min-w-125px'
      />
    ),
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Setor'
        className='min-w-125px'
      />
    ),
    id: 'setor.nome',
    accessor: (row) => row.setor?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Cargo'
        className='min-w-125px'
      />
    ),
    id: 'cargo.nome',
    accessor: (row) => row.cargo?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data criação'
        isAbleToSort={false}
      />
    ),
    id: 'dataInclusao',
    Cell: ({ ...props }) => <DataColumn data={props.row.original.dataInclusao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data edição'
        isAbleToSort={false}
      />
    ),
    id: 'dataAlteracao',
    Cell: ({ ...props }) => <DataColumn data={props.row.original.dataAlteracao} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <RiscoActionsCell id={props.row.original.id} />,
  },
]

export { RiscoColumns }
