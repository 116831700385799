import { Column } from 'react-table'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { AtendimentoReport } from '../../../../meus-atendimentos/meus-atendimentos-list/core/_models'
import { CustomHeader } from '../../../../../../components'

const relatorioColumns: ReadonlyArray<Column<AtendimentoReport>> = [
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Protocolo'
        className='min-w-125px'
      />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Paciente'
        className='min-w-125px'
      />
    ),
    id: 'paciente.pessoa.nome',
    accessor: (row) => row.paciente?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Tipo'
        className='min-w-125px'
      />
    ),
    id: 'tipo',
    accessor: 'tipoAtendimento',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Especialidade'
        className='min-w-125px'
      />
    ),
    id: 'especialidade',
    accessor: 'especialidade',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Status'
        className='min-w-125px'
      />
    ),
    id: 'estado.nome',
    accessor: 'estadoAtendimento',
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Retorno'
        className='min-w-125px'
        isAbleToSort={false}
      />
    ),
    id: 'retorno',
    accessor: (row) => (row.retorno ? 'Sim' : 'Não'),
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Profissional de Saúde'
        className='min-w-125px'
      />
    ),
    id: 'responsavel.pessoa.nome',
    accessor: (row) => row.responsavel?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Empresa'
        className='min-w-125px'
      />
    ),
    id: 'paciente.empresa.nomeFantasia',
    accessor: (row) => row.empresa?.nomeFantasia,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='CNPJ'
        className='min-w-125px'
      />
    ),
    id: 'paciente.empresa.cnpj',
    accessor: (row) => row.empresa?.cnpj,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Solicitante'
        className='min-w-125px'
      />
    ),
    id: 'criador.nome',
    accessor: (row) => row.criador?.nome,
  },
  {
    Header: (props) => (
      <CustomHeader
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title='Data'
        className='min-w-125px'
      />
    ),
    id: 'solicitacao',
    accessor: 'solicitacaoFormatada',
  },
]

export { relatorioColumns }
