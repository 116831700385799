import { useEffect, useState } from 'react'

import { useQueryRequest } from '../../core/QueryRequestProvider'
import {
  initialQueryState,
  OptionsHelper,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import { CustomSelect, DateInput, IOption, Label } from '../../../../../../components'

const ImportacaoListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [empresa, setEmpresa] = useState<IOption>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [dataInicio, setDataInicio] = useState<string>('')
  const [dataTermino, setDataTermino] = useState<string>('')

  const [filters, setFilters] = useState<Array<String>>(
    empresa ? new Array(String(empresa.value)) : []
  )
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const updateFilter = () => {
    const search = [
      empresa ? `empresa:${empresa.value}` : '',
      dataInicio ? `inicio>${dataInicio}T00:00:00` : '',
      dataTermino ? `termino<'${dataTermino}T23:59:59` : '',
    ]
      .filter(Boolean)
      .join(',')
    setSearchTerm(search)
  }

  useEffect(() => {
    updateFilter()
  }, [empresa, dataInicio, dataTermino])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        filter: filters,
        search: searchTerm,
        sort: 'inicio',
        order: 'desc',
        ...initialQueryState,
      })
    }
  }, [searchTerm, debouncedSearchTerm, filters])

  useEffect(() => {
    if (empresa) {
      setFilters(empresa ? [String(empresa.value)] : [])
    }
  }, [empresa])

  return (
    <div className='p-0 justify-content-center'>
      <div className='row mb-0'>
        <div className='col-lg-4 col-md-6 mb-2'>
          <Label className='pb-2'>Empresa</Label>
          <CustomSelect
            placeholder='Selecione uma empresa'
            onChange={(data) => setEmpresa(data)}
            fetchOptions={OptionsHelper.getOptionsEmpresas}
            value={empresa}
          />
        </div>
        <div className='col-lg-4 col-md-6 mb-2'>
          <Label className='pb-2'>Data início:</Label>
          <DateInput
            placeholder='Digite data inicial'
            value={dataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
          />
        </div>
        <div className='col-lg-4 col-md-6 mb-2'>
          <Label className='pb-2'>Data término:</Label>
          <DateInput
            placeholder='Digite data final'
            value={dataTermino}
            onChange={(e) => setDataTermino(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export { ImportacaoListSearchComponent }
