import * as Yup from 'yup'

import { AvatarDto, EmpresaView, EnderecoList, initialEmpresa } from '../core/_models'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import {
  createEmpresa,
  createEnderecoEmpresa,
  getEnderecoByIdEmpresa,
  getTiposEndereco,
  updateEmpresa,
  updateEnderecoEmpresa,
} from '../core/_requests'

import Select from 'react-select'
import clsx from 'clsx'
import { isNotEmpty, Tipo } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { IMaskInput } from 'react-imask'
import { FetchState, ListLoading, LogoInput, useModal } from '../../../../../components'

type SelectValue = {
  value: string
  label: string
}

function defineTiposInput(tipos: SelectValue[]) {
  var valuesTipoAt: any = []
  tipos.map((t) => {
    valuesTipoAt.push(t.value)
  })
  return valuesTipoAt
}
function possuiASO(tipos: SelectValue[]) {
  let possui = false
  if (tipos.length > 0) {
    tipos.map((t) => {
      if (t.value === 'ASO') {
        possui = true
      }
    })
  }
  return possui
}
function formatCep(cep: string) {
  if (cep !== undefined) {
    cep.replace(/([.-])/g, '')
    cep
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1-$2')
      .replace(/(.\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  } else {
    return ''
  }
  return cep
}
const EmpresaEditModalForm: FC = () => {
  const { fetchStates, closeModal } = useModal()

  const {
    data: empresa,
    isLoading: isEmpresaLoading,
    error: errorEmpresa,
  } = (fetchStates['empresa'] || {}) as FetchState<EmpresaView>

  const {
    data: logo,
    isLoading: isLogoLoading,
    error: errorLogo,
  } = (fetchStates['logo'] || {}) as FetchState<string>

  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [tiposAtendimento, setTiposAtendimento] = useState<SelectValue[]>(
    getTiposAtendimento(empresa)
  )
  const [tiposAtendimentoInput, setTiposAtendimentoInput] = useState<String[]>(
    defineTiposInput(tiposAtendimento)
  )
  const [isAso, setIsAso] = useState<boolean>(possuiASO(tiposAtendimento))

  const convertToAvatarDto = (input: string, action: 'u' | 'd'): AvatarDto => {
    const match = input.match(/^data:(.*?);base64,/)
    const fileType = match ? match[1] : ''

    return {
      action,
      fileContent: input,
      fileType,
    }
  }

  function getTiposAtendimento(empresa: EmpresaView | undefined) {
    var valuesTipoAt: any = []
    if (empresa) {
      empresa.tiposAtendimento?.map((a) => {
        valuesTipoAt.push({ value: a, label: a })
      })
    } else {
      valuesTipoAt.push({ value: 'PA', label: 'PA' })
    }
    return valuesTipoAt
  }

  const editEmpresaSchema = Yup.object().shape({
    cnpj: Yup.string().required('CNPJ é obrigatório'),
    /* logo: Yup.string(), */
    nomeFantasia: Yup.string().required('Nome fantasia é obrigatório'),
    nome: Yup.string().required('Nome é obrigatório'),
    // cpf: Yup.object()
    //   .required('CPF é obrigatório'),
    tipoEndereco: Yup.string(),
    // .when([], {
    //   is: ()=>isAso,
    //     then: Yup.string().required('O campo tipo endereço é obrigatório'),
    //     otherwise: Yup.string().notRequired()
    //   })
    tiposAtendimento: Yup.array()
      .min(1, 'Pelo menos um tipo de atendimento deve ser selecionado')
      .of(Yup.string().required('Tipo de atendimento é obrigatório'))
      .required('Tipos de atendimento é obrigatório'),
    cep: Yup.string()
      .nullable()
      .when([], {
        is: () => isAso,
        then: Yup.string().required('O campo cep é obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    logradouro: Yup.string()
      .nullable()
      .when([], {
        is: () => isAso,
        then: Yup.string().required('O campo logradouro é obrigatório'),
      }),
    numero: Yup.string()
      .nullable()
      .when([], {
        is: () => isAso,
        then: Yup.string().required('O campo número é obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    bairro: Yup.string()
      .nullable()
      .when([], {
        is: () => isAso,
        then: Yup.string().required('O campo bairro é obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    cidade: Yup.string()
      .nullable()
      .when([], {
        is: () => isAso,
        then: Yup.string().required('O campo cidade é obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    uf: Yup.string()
      .nullable()
      .when([], {
        is: () => isAso,
        then: Yup.string().required('O campo UF é obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
  })

  const empresaForEdit = {
    ...empresa,
    nome: empresa?.nome || '',
    nomeFantasia: empresa?.nomeFantasia || '',
    cnpj: empresa?.cnpj || '',
    logo: logo ? convertToAvatarDto(logo, 'u') : undefined,
    tiposAtendimento: empresa?.tiposAtendimento || [Tipo.PA],
    permiteNovoPaciente:
      empresa?.permiteNovoPaciente !== undefined ? empresa.permiteNovoPaciente : true,

    //endereco
    tipoEndereco: empresa?.endereco?.tipoEndereco,
    cep: formatCep(String(empresa?.endereco?.cep)),
    logradouro: empresa?.endereco?.logradouro,
    numero: empresa?.endereco?.numero,
    complemento: empresa?.endereco?.complemento,
    bairro: empresa?.endereco?.bairro,
    cidade: empresa?.endereco?.cidade,
    uf: empresa?.endereco?.uf,
    isASO: isAso,
    idEndereco: empresa?.id !== undefined && isAso ? empresa.endereco?.id : 0,
    idEmpresa: empresa?.id !== undefined ? empresa.id : undefined,
    grupoTelegram: !!empresa?.grupoTelegram ? empresa?.grupoTelegram : '',
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
    closeModal()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: empresaForEdit,
    validationSchema: editEmpresaSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      /* values.logo = values.logo ? undefined : values.logo */

      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          updateEmpresa(
            values.id,
            String(values.nome),
            String(values.nomeFantasia),
            String(values.cnpj),
            tiposAtendimentoInput,
            values.permiteNovoPaciente,
            values.grupoTelegram,
            values.logo
          )
            .then(() => {
              Swal.fire({
                text: 'Dados atualizados com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
              if (isAso) {
                if (values.idEndereco !== undefined) {
                  updateEnderecoEmpresa(
                    Number(values.id),
                    Number(values.idEndereco),
                    Number(1), //tipo endereço
                    String(values.cep),
                    String(values.logradouro),
                    String(values.numero),
                    String(values.complemento),
                    String(values.bairro),
                    String(values.cidade),
                    String(values.uf)
                  )
                    .then(() => {
                      Swal.fire({
                        text: 'Dados atualizados com sucesso!',
                        icon: 'success',
                        buttonsStyling: false,
                        confirmButtonText: 'Ok',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                      }).then(() => cancel(true))
                    })
                    .catch((error) => {
                      const errors = error.response.data.data.errors
                      setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
                      setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                      setFieldError(
                        'nomeFantasia',
                        errors['nomeFantasia'] !== undefined ? errors.nomeFantasia[0] : ''
                      )
                      setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
                      setFieldError(
                        'logradouro',
                        errors['logradouro'] !== undefined ? errors.logradouro[0] : ''
                      )
                      setFieldError(
                        'numero',
                        errors['numero'] !== undefined ? errors.numero[0] : ''
                      )
                      setFieldError(
                        'bairro',
                        errors['bairro'] !== undefined ? errors.bairro[0] : ''
                      )
                      setFieldError(
                        'cidade',
                        errors['cidade'] !== undefined ? errors.cidade[0] : ''
                      )
                      setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
                      setFieldError(
                        'grupoTelegram',
                        errors['grupoTelegram'] !== undefined ? errors.grupoTelegram[0] : ''
                      )
                      Swal.fire({
                        text: error.response?.data.data.message,
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: 'Ok',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                      })
                    })
                } else {
                  createEnderecoEmpresa(
                    Number(values.idEmpresa),
                    1,
                    String(values.cep),
                    String(values.logradouro),
                    String(values.numero),
                    String(values.complemento),
                    String(values.bairro),
                    String(values.cidade),
                    String(values.uf)
                  )
                    .then(() => {
                      Swal.fire({
                        text: 'Empresa cadastrada com sucesso!',
                        icon: 'success',
                        buttonsStyling: false,
                        confirmButtonText: 'Ok',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                      }).then(() => cancel(true))
                    })
                    .catch((error) => {
                      const errors = error.response.data.data.errors
                      setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
                      setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                      setFieldError(
                        'grupoTelegram',
                        errors['grupoTelegram'] !== undefined ? errors.grupoTelegram[0] : ''
                      )
                      setFieldError(
                        'nomeFantasia',
                        errors['nomeFantasia'] !== undefined ? errors.nomeFantasia[0] : ''
                      )
                      setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
                      setFieldError(
                        'logradouro',
                        errors['logradouro'] !== undefined ? errors.logradouro[0] : ''
                      )
                      setFieldError(
                        'numero',
                        errors['numero'] !== undefined ? errors.numero[0] : ''
                      )
                      setFieldError(
                        'bairro',
                        errors['bairro'] !== undefined ? errors.bairro[0] : ''
                      )
                      setFieldError(
                        'cidade',
                        errors['cidade'] !== undefined ? errors.cidade[0] : ''
                      )
                      setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
                      Swal.fire({
                        text: error.response?.data.data.message,
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: 'Ok',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                      })
                    })
                }
              } else {
                Swal.fire({
                  text: 'Empresa atualizada com sucesso!',
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => cancel(true))
              }
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
              setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
              setFieldError(
                'nomeFantasia',
                errors['nomeFantasia'] !== undefined ? errors.nomeFantasia[0] : ''
              )
              setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
              setFieldError(
                'logradouro',
                errors['logradouro'] !== undefined ? errors.logradouro[0] : ''
              )
              setFieldError('numero', errors['numero'] !== undefined ? errors.numero[0] : '')
              setFieldError('bairro', errors['bairro'] !== undefined ? errors.bairro[0] : '')
              setFieldError('cidade', errors['cidade'] !== undefined ? errors.cidade[0] : '')
              setFieldError(
                'grupoTelegram',
                errors['grupoTelegram'] !== undefined ? errors.grupoTelegram[0] : ''
              )
              setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
              Swal.fire({
                text: error.response?.data.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
          //verificar se a empresa tem endereco update ou create
        } else {
          createEmpresa(
            String(values.nome),
            String(values.nomeFantasia),
            String(values.cnpj),
            tiposAtendimentoInput,
            values.permiteNovoPaciente,
            values.grupoTelegram,
            values.logo
          )
            .then((empresa: any) => {
              Swal.fire({
                text: 'Empresa cadastrada com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => cancel(true))
              if (isAso) {
                createEnderecoEmpresa(
                  Number(empresa?.id),
                  1,
                  String(values.cep),
                  String(values.logradouro),
                  String(values.numero),
                  String(values.complemento),
                  String(values.bairro),
                  String(values.cidade),
                  String(values.uf)
                )
                  .then(() => {
                    Swal.fire({
                      text: 'Empresa cadastrada com sucesso!',
                      icon: 'success',
                      buttonsStyling: false,
                      confirmButtonText: 'Ok',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                    }).then(() => cancel(true))
                  })
                  .catch((error) => {
                    const errors = error.response.data.data.errors
                    setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
                    setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                    setFieldError(
                      'nomeFantasia',
                      errors['nomeFantasia'] !== undefined ? errors.nomeFantasia[0] : ''
                    )
                    setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
                    setFieldError(
                      'logradouro',
                      errors['logradouro'] !== undefined ? errors.logradouro[0] : ''
                    )
                    setFieldError('numero', errors['numero'] !== undefined ? errors.numero[0] : '')
                    setFieldError('bairro', errors['bairro'] !== undefined ? errors.bairro[0] : '')
                    setFieldError('cidade', errors['cidade'] !== undefined ? errors.cidade[0] : '')
                    setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
                    setFieldError(
                      'grupoTelegram',
                      errors['grupoTelegram'] !== undefined ? errors.grupoTelegram[0] : ''
                    )

                    Swal.fire({
                      text: error.response?.data.data.message,
                      icon: 'error',
                      buttonsStyling: false,
                      confirmButtonText: 'Ok',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                    })
                  })
              } else {
                Swal.fire({
                  text: 'Empresa cadastrada com sucesso!',
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => cancel(true))
              }
            })
            .catch((error) => {
              const errors = error.response.data.data.errors
              setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
              setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
              setFieldError(
                'nomeFantasia',
                errors['nomeFantasia'] !== undefined ? errors.nomeFantasia[0] : ''
              )
              setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
              setFieldError(
                'logradouro',
                errors['logradouro'] !== undefined ? errors.logradouro[0] : ''
              )
              setFieldError('numero', errors['numero'] !== undefined ? errors.numero[0] : '')
              setFieldError('bairro', errors['bairro'] !== undefined ? errors.bairro[0] : '')
              setFieldError('cidade', errors['cidade'] !== undefined ? errors.cidade[0] : '')
              setFieldError(
                'grupoTelegram',
                errors['grupoTelegram'] !== undefined ? errors.grupoTelegram[0] : ''
              )
              setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
              Swal.fire({
                text: error.response?.data.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
        }
      } catch (error: any) {
        const errors = error.response.data.data.errors
        setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
        setFieldError(
          'nomeFantasia',
          errors['nomeFantasia'] !== undefined ? errors.nomeFantasia[0] : ''
        )
        setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
        setFieldError('logradouro', errors['logradouro'] !== undefined ? errors.logradouro[0] : '')
        setFieldError('numero', errors['numero'] !== undefined ? errors.numero[0] : '')
        setFieldError('bairro', errors['bairro'] !== undefined ? errors.bairro[0] : '')
        setFieldError('cidade', errors['cidade'] !== undefined ? errors.cidade[0] : '')
        setFieldError(
          'grupoTelegram',
          errors['grupoTelegram'] !== undefined ? errors.grupoTelegram[0] : ''
        )
        setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
        console.error(error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  var options: any = []
  function getTipos() {
    getTiposEndereco()
      .then((tipos) => {
        for (let i = 0; i < tipos.length; i++) {
          options.push({ value: tipos[i].id, label: tipos[i].descricao })
        }
      })
      .catch((error: any) => {
        console.error(error)
      })
  }

  const handleChangeTiposAtendimento = (e: any) => {
    let values: any = []
    setIsAso(false)
    e.map((f: any) => {
      if (f.value === 'ASO') {
        setIsAso(true)
      }
      values.push(f.value)
    })
    setTiposAtendimentoInput(values)
  }

  useEffect(() => {
    getTipos()
  }, [empresa])

  const getErrorMessage = (error: any): string => {
    if (typeof error === 'string') {
      return error
    }

    if (Array.isArray(error)) {
      return error.join(', ')
    }

    return ''
  }

  return (
    <>
      <form
        id='kt_modal_add_empresa_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_empresa_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_empresa_header'
          data-kt-scroll-wrappers='#kt_modal_add_empresa_scroll'
          // data-kt-scroll-offset='300px'
        >
          {/* end::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Tipo Atendimento</label>
            {/* end::Label */}

            {/* begin::Input */}
            <Select
              placeholder='Selecione um tipo de atendimento'
              onChange={handleChangeTiposAtendimento}
              isMulti={true}
              name='tiposAtendimento'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
                'is-valid': tiposAtendimentoInput.length > 0,
              })}
              defaultValue={tiposAtendimento}
              options={[
                {
                  label: 'ASO',
                  value: 'ASO',
                },
                {
                  label: 'PA',
                  value: 'PA',
                },
                {
                  label: 'SC',
                  value: 'SC',
                },
                {
                  label: 'CA',
                  value: 'CA',
                },
              ]}
            />
            {formik.touched.tiposAtendimento && formik.errors.tiposAtendimento && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{getErrorMessage(formik.errors.tiposAtendimento)}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
              <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                Permite novos pacientes
              </span>
              <input
                className='form-check-input'
                type='checkbox'
                checked={formik.values.permiteNovoPaciente}
                {...formik.getFieldProps('permiteNovoPaciente')}
              />
            </label>
          </div>
          <br />
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome'
              {...formik.getFieldProps('nome')}
              type='text'
              name='nome'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.nome && formik.errors.nome },
                {
                  'is-valid': formik.touched.nome && !formik.errors.nome,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEmpresaLoading}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{getErrorMessage(formik.errors.nome)}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome fantasia</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome fantasia'
              {...formik.getFieldProps('nomeFantasia')}
              type='text'
              name='nomeFantasia'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.nomeFantasia && formik.errors.nomeFantasia },
                {
                  'is-valid': formik.touched.nomeFantasia && !formik.errors.nomeFantasia,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEmpresaLoading}
            />
            {formik.touched.nomeFantasia && formik.errors.nomeFantasia && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{getErrorMessage(formik.errors.nomeFantasia)}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>CNPJ</label>
            {/* end::Label */}

            {/* begin::Input */}
            <IMaskInput
              placeholder='CNPJ'
              {...formik.getFieldProps('cnpj')}
              mask='00.000.000/0000-00'
              type='text'
              name='cnpj'
              // hidden={hideFind}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.cnpj && formik.errors.cnpj },
                {
                  'is-valid': formik.touched.cnpj && !formik.errors.cnpj,
                }
              )}
              disabled={formik.isSubmitting || isEmpresaLoading}
            />
            {formik.touched.cnpj && formik.errors.cnpj && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{getErrorMessage(formik.errors.cnpj)}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Logo da Empresa</label>
            {/* end::Label */}

            {/* begin::Input */}
            <LogoInput onChange={(value) => formik.setFieldValue('logo', value)} value={logo} />

            {/* end::Input */}
          </div>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Link do Grupo no Telegram</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Link do Grupo no Telegram'
              {...formik.getFieldProps('grupoTelegram')}
              type='text'
              name='grupoTelegram'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.grupoTelegram && formik.errors.grupoTelegram },
                {
                  'is-valid': formik.touched.grupoTelegram && !formik.errors.grupoTelegram,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEmpresaLoading}
            />
            {formik.touched.grupoTelegram && formik.errors.grupoTelegram && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{getErrorMessage(formik.errors.grupoTelegram)}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          {isAso ? (
            <>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Endereco</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Logradouro'
                  {...formik.getFieldProps('logradouro')}
                  type='text'
                  name='logradouro'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.logradouro && formik.errors.logradouro },
                    {
                      'is-valid': formik.touched.logradouro && !formik.errors.logradouro,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isEmpresaLoading}
                />
                {formik.touched.logradouro && formik.errors.logradouro && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{getErrorMessage(formik.errors.logradouro)}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* begin::Input group 
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Tipo Endereço</label>
                  <Select
                    placeholder='Selecione um tipo de Endereço'
                    onChange={handleChangeTipoEndereco}
                    // options={optionsEmpresa}
                    options={options}
                    className={clsx(
                      'basic-single',
                      { 'is-invalid': formik.touched.tipoEndereco && formik.errors.tipoEndereco },
                      {
                        'is-valid': formik.touched.tipoEndereco && !formik.errors.tipoEndereco,
                      }
                    )}
                  />
                  {formik.touched.tipoEndereco && formik.errors.tipoEndereco && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.tipoEndereco}</span>
                      </div>
                    </div>
                  )}
                  {/* end::Input 
                </div>
                {/* end::Input group */}
              {/* end::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Número</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Número'
                  {...formik.getFieldProps('numero')}
                  type='text'
                  name='numero'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.numero && formik.errors.numero },
                    {
                      'is-valid': formik.touched.numero && !formik.errors.numero,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isEmpresaLoading}
                />
                {formik.touched.numero && formik.errors.numero && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{getErrorMessage(formik.errors.numero)}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>CEP</label>
                {/* end::Label */}

                {/* begin::Input */}
                <IMaskInput
                  placeholder='CEP'
                  {...formik.getFieldProps('cep')}
                  mask='00.000-000'
                  type='text'
                  name='cep'
                  // hidden={hideFind}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.cep && formik.errors.cep },
                    {
                      'is-valid': formik.touched.cep && !formik.errors.cep,
                    }
                  )}
                  disabled={formik.isSubmitting || isEmpresaLoading}
                />
                {formik.touched.cep && formik.errors.cep && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{getErrorMessage(formik.errors.cep)}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Complemento</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Complemento'
                  {...formik.getFieldProps('complemento')}
                  type='text'
                  name='complemento'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isEmpresaLoading}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.complemento && formik.errors.complemento },
                    {
                      'is-valid': formik.touched.complemento && !formik.errors.complemento,
                    }
                  )}
                />
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Bairro</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Bairro'
                  {...formik.getFieldProps('bairro')}
                  type='text'
                  name='bairro'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.bairro && formik.errors.bairro },
                    {
                      'is-valid': formik.touched.bairro && !formik.errors.bairro,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isEmpresaLoading}
                />
                {formik.touched.bairro && formik.errors.bairro && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{getErrorMessage(formik.errors.bairro)}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Cidade</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Cidade'
                  {...formik.getFieldProps('cidade')}
                  type='text'
                  name='cidade'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.cidade && formik.errors.cidade },
                    {
                      'is-valid': formik.touched.cidade && !formik.errors.cidade,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isEmpresaLoading}
                />
                {formik.touched.cidade && formik.errors.cidade && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{getErrorMessage(formik.errors.cidade)}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Estado</label>
                {/* end::Label */}

                {/* begin::Input */}
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('uf')}
                >
                  <option value=''>Selecione um estado...</option>
                  <option value='AC'>Acre</option>
                  <option value='AL'>Alagoas</option>
                  <option value='AP'>Amapá</option>
                  <option value='AM'>Amazonas</option>
                  <option value='BA'>Bahia</option>
                  <option value='CE'>Ceará</option>
                  <option value='DF'>Distrito Federal</option>
                  <option value='ES'>Espírito Santo</option>
                  <option value='GO'>Goiás</option>
                  <option value='MA'>Maranhão</option>
                  <option value='MT'>Mato Grosso</option>
                  <option value='MS'>Mato Grosso do Sul</option>
                  <option value='MG'>Minas Gerais</option>
                  <option value='PA'>Pará</option>
                  <option value='PB'>Paraíba</option>
                  <option value='PR'>Paraná</option>
                  <option value='PE'>Pernambuco</option>
                  <option value='PI'>Piauí</option>
                  <option value='RJ'>Rio de Janeiro</option>
                  <option value='RN'>Rio Grande do Norte</option>
                  <option value='RS'>Rio Grande do Sul</option>
                  <option value='RO'>Rondônia</option>
                  <option value='RR'>Roraima</option>
                  <option value='SC'>Santa Catarina</option>
                  <option value='SP'>São Paulo</option>
                  <option value='SE'>Sergipe</option>
                  <option value='TO'>Tocantins</option>
                </select>
                {formik.touched.uf && formik.errors.uf && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{getErrorMessage(formik.errors.uf)}</div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </>
          ) : (
            <></>
          )}
          {/* end::Input group */}
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isEmpresaLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isEmpresaLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || isEmpresaLoading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isEmpresaLoading) && <ListLoading />}
    </>
  )
}

export { EmpresaEditModalForm }
