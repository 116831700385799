import { KTCard } from '../../../../../_metronic/helpers'
import { EmpresasClienteListHeader } from './components/header/EmpresasClienteListHeader'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { EmpresasClienteTable } from './table/EmpresasClienteTable'

const EmpresasClienteList = () => {
  return (
    <>
      <KTCard>
        <EmpresasClienteListHeader />
        <EmpresasClienteTable />
      </KTCard>
    </>
  )
}

const EmpresasClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmpresasClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { EmpresasClienteListWrapper }
