/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { AtendimentoList } from '../../../atendimento/core/_models'
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  stringifyRequestQuery,
  initialQueryState,
} from '../../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { getAtendimentosAso } from '../../aso-list/core/_request'
import { useModal } from '../../../../../components'

const QueryResponseContext = createResponseContext<AtendimentoList>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isOpen } = useModal()
  const { state } = useQueryRequest()
  const { idEmpresa } = useQueryResponse()
  const { idPaciente } = useQueryResponse()
  const [query, setQuery] = useState<string>(decodeURIComponent(stringifyRequestQuery(state)))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [atendimentosFinalizados, setAtendimentosFinalizados] = useState()

  const asoGeradosQuery =
    query === 'page=0&size=10'
      ? 'page=0&size=10&search=situacaoAso:FINALIZADO'
      : decodeURIComponent(stringifyRequestQuery(state))

  useEffect(() => {
    if (asoGeradosQuery !== updatedQuery) {
      getAtendimentosAso(asoGeradosQuery).catch((error) => {
        console.error(error)
      })
      setQuery(decodeURIComponent(updatedQuery))
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-${asoGeradosQuery}-${isOpen}`,
    () => getAtendimentosAso(asoGeradosQuery),
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: !isOpen }
  )

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response,
        query,
        idEmpresa,
        idPaciente,
        atendimentosFinalizados,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useDownloadedResponseData = () => {
  const { atendimentosFinalizados } = useQueryResponse()

  if (!atendimentosFinalizados) {
    return []
  }

  if (!('data' in atendimentosFinalizados)) {
    return []
  }

  return atendimentosFinalizados.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  }

  return responsePaginationState
}

const useQueryDownloadedResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { atendimentosFinalizados } = useQueryResponse()
  if (!atendimentosFinalizados) {
    return defaultPaginationState
  }

  if (
    !(
      'page' in atendimentosFinalizados &&
      'size' in atendimentosFinalizados &&
      'totalElements' in atendimentosFinalizados
    )
  ) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: atendimentosFinalizados.page,
    size: atendimentosFinalizados.size,
    totalElements: atendimentosFinalizados.totalElements,
  }

  return responsePaginationState
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useDownloadedResponseData,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryDownloadedResponsePagination,
}
