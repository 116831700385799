import { KTSVG, ModalSize } from '../../../../../../../_metronic/helpers'
import { useModal } from '../../../../../../components'
import { SetorClienteEditModalForm } from '../../setor-edit-modal/SetorClienteEditModalForm'

const SetorClienteListToolbar = () => {
  const { showModal } = useModal()
  const openAddSetor = () => {
    showModal({
      title: 'Setor',
      content: <SetorClienteEditModalForm />,
      size: ModalSize.Medium,
    })
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={openAddSetor}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar
      </button>
    </div>
  )
}

export { SetorClienteListToolbar }
